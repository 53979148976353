karmads.debug = (() => {
    'use strict';

    karmaVars.debug = false;

    const c = win.console;

    const init = (karmaDebug) => {
        if (karmaDebug !== 'true' && karmaDebug !== 'log') {
            return;
        }

        // TEST: if karmaDebug= 'true' or 'log' is in the URL, karma.vars.debug is true
        if (!karmaVars.debug) {
            toggle();
        }
        // TEST: if karmaDebug= 'true' or 'log' is in the URL, window.karmads is an object containing all the KARMA methods
        exposeGlobal();
        // show the log in the console again
        if (karmaVars.firstAdReturned) {
            showLog();
        }
    };

    // TEST: When karma.debug is called, karma.vars.debug should be true
    const toggle = () => karmaVars.debug = !karmaVars.debug;

    // TEST: after invoking karma.debug, window.karmads is available as a globally scoped object
    const exposeGlobal = () => win.karmads = karmads;

    const showLog = () => {
        // TEST: when invoked, karma.showLog dumps the KARMA log to the console
        const logObject = karmaVars.logObject;
        doLoop(logObject, (m) => {
            doLog(logObject[m].consoleMessage, logObject[m].level);
        });
        return logObject;
    };

    const doLog = (msg, level = 'log') => {
        // log messages out to the console with a prefix and certain colors
        msg = `%c--KARMA ${level.toUpperCase()}${(level === 'warn') ? 'ING' : ''}: ${msg}`;
        switch (level) {
        case 'error':
            c.error(msg, 'font-size:14px;color:red');
            break;
        case 'warn':
            c.warn(msg, 'font-size:14px;color:darkgoldenrod');
            break;
        default:
            c.log(msg, 'font-size:12px;color:green');
        }
    };

    const consoleLog = (msg, level, docsLink = false) => {
        // wrapper around doLog
        if (docsLink) {
            msg += ` http://${(karmaVars.environment !== 'www') && 'dev.'}karma.mdpcdn.com/docs/dev/#${docsLink}`;
        }
        if (c) {
            doLog(msg, level);
        }
    };

    const publicDebug = () => init('true');

    return {
        init,
        showLog,
        consoleLog,
        publicDebug
    };

})();