// This file is adapted from one provided by Adam Day's team. Its purpose is to put a pixel on the page and retrieve a Meredith user id (muid) and Meredith user segments (msg, msg2) to pass along to the ad request
karmads.muid = (function() {

    let cachedCookies;

    // Code to add a pixel to the page
    const appendPixel = (path) => {
        // TEST: a pixel is added to the page with the filename x.gif
        log('Appending MUID pixel to the document');
        return karmads.dom.create('img', doc.body, {src: path, height: '1', width: '1', alt: ''});
    };

    // Function to generate UUID for MUID and request_id
    const uid = () => {
        let d = new Date().getTime();
        if (typeof win.performance === 'undefined') {
            d += win.performance.now();
        }
        // TEST: window.globalTI.session is 36 characters long
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            const r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    };

    const isUuid = (x) => typeof x === 'string' && x.length === 36 && x.split('-').length === 5;

    // Shim to ignore if localStorage unavailable
    const local = (() => {
        let local = {setItem: noop};
        try {
            localStorage.setItem('__test', 1);
            localStorage.removeItem('__test');
            local = win.localStorage;
        } catch (err) {
            log(err, {force: 1});
        }
        return local;
    })();

    // Set a first party cookie through client side code
    const setCookie = (name, value) => {
        local.setItem(name, value);
        const date = new Date();
        date.setTime(Date.now() + (730 * 24 * 60 * 60 * 1000));
        const expires = '; expires=' + date.toUTCString();
        document.cookie = name + '=' + (value || '')  + expires + ';path=/';
    };

    // Get the cookies and cache them in an object to avoid multiple lookups
    const cacheCookies = () => {
        const out = {},
            allCookie = document.cookie.split(';');
        // TODO: convert to for of?
        for (let i = 0; i < allCookie.length; i++) {
            const cval = allCookie[i].trim().split('=');
            if (cval.length > 1 && cval[1] !== '' && cval[1] !== '""')
                out[cval[0].trim()] = cval[1].trim();
        }
        return out;
    };

    // Check cookies or localStorage
    const getCookies = (x) => cachedCookies[x] || local[x];

    // this function is not part of pixel_lite.js and is maintained by the KARMA team
    const exposeSegments = () => {
        // TEST: msg (meredith 360 segments) are exposed as karma.vars.msg if they are in localStorage
        // TEST: msg2 (meredith 360 segments) are exposed as karma.vars.msg2 if they are in localStorage
        const segmentVars = ['msg', 'msg2'];
        doLoop(segmentVars, (varName) => {
            if (typeof local[varName] === 'string' && !karmaVars[varName]) {
                karmaVars[varName] = JSON.parse(local[varName]);
            }
        });
    };

    const init = () => {
        // You can enable console logging by adding `pixelDebug=true` to the URL
        const debug = karmaVars.url.pixelDebug ? convertStringToBoolean(karmaVars.url.pixelDebug) : false,
            // Params about our app
            version = 'l1.0.21',
            pixel = 'https://d9jj3mjthpub.cloudfront.net/x.gif',
            // Get hostname, url, referrer
            host = win.location.hostname.toLowerCase(),
            url = win.location.href,
            ref = (doc.referrer === url) ? '' : doc.referrer;
        cachedCookies = cacheCookies();

        // Set the application state for MUID variables, request_id and MUID
        // TEST: window.globalTI exists and is an object
        win.globalTI = win.globalTI || {};
        // TEST: window.globalTI.request_id exists and is a string
        // Make a request ID if not already defined
        if (!(win.globalTI.request_id)) {
            win.globalTI.request_id = uid();
        }
        // Make a request ID if not already defined

        // loading the muuid asap b/c most likely this logic will execute first
        const muid = getCurrentMUUID();

        // TEST: if the page has one of the following cookies: mdpaccount, monetate_profile, hid, lite_capture has a hid property
        // Get the HID
        let hid;
        if (!hid) {
            tryCatch(() => {
                hid = cachedCookies.mdpaccount.match(/hashId":"([a-z0-9-]{20,})/i)[1];
            }, noop);
        }
        if (!hid) {
            tryCatch(() => {
                hid = decode(cachedCookies.monetate_profile).match(/hashId":"([a-z0-9-]{20,})/i)[1];
            }, noop);
        }
        if (!hid && cachedCookies.hid !== '""') {
            hid = cachedCookies.hid;
        }

        // Get the allrecipes ID
        let userid = doc.cookie.match(/TastePrefs(\d+)/);
        if (userid && userid[1]) {
            userid = userid[1];
        } else {
            userid = null;
        }

        // Get the user agent.
        const userAgent = win.navigator.userAgent ? win.navigator.userAgent.toLowerCase() : 'unknown';

        // Get Segment id
        const getSegmentId = () => {
            if ('ajs_anonymous_id' in cachedCookies) {
                cachedCookies.ajs_anonymous_id = decode(cachedCookies.ajs_anonymous_id || '').replace(/"/g, '');
                return cachedCookies.ajs_anonymous_id;
            }
            return null;
        };

        // TEST: window.globalTI.lite_capture contains these params: pulse, v, type, globalTI_SID, hid, request_id, url, ref, recipes_user_id, host, ua, ajs_anonymous_id, _ga, _gid, muuid_origin
        // Get GA id
        const gaId = cachedCookies._ga,
            gaProperty = cachedCookies._gid,
            // The params we want to output to the pixel
            params = {
                pulse: -1,
                v: version,
                type: 'karma',
                globalTI_SID: muid,
                hid,
                request_id: win.globalTI.request_id,
                url,
                ref,
                recipes_user_id: userid,
                host,
                ua: userAgent,
                ajs_anonymous_id: getSegmentId(),
                _ga: gaId,
                _gid: gaProperty,
                muuid_origin: getCookies('muuid_origin')
            };
        // Capture what was sent for re-use or debugging
        // TEST: window.globalTI.lite_capture exists and is an object
        win.globalTI.lite_capture = params;

        // Turn the params object into a query string
        const queryString = [];
        let x;
        for (x in params) {
            if (!params.hasOwnProperty(x)) {
                continue;
            }
            if (!params[x] || params[x] === '""') {
                continue;
            }
            queryString.push(x + '=' + encodeURIComponent(params[x]));
        }
        appendPixel(pixel + '?' + queryString.join('&'));

        // append the javascript tag that'll put the segment ids in localStorage
        // if is a valid muid and if it has never been requested or it was requested more than 24 hours ago
        // note: msg will not populate for newly created muids (after localStorage is cleared or when in cognito)
        const msgDate = local['msg_date'];

        if (!msgDate || new Date().getTime() - msgDate > 3 * 24 * 60 * 60 * 1000) {
            delete local.msg;
            delete local.msg2;
            delete local['msg_date'];
        }

        if (!local.msg && isUuid(muid)) {
            const path = 'https://d30qdagvt44524.cloudfront.net/production/segments?muid=' + muid;
            fetchResource(path, exposeSegments, noop, 'script', {defer: true});
        } else {
            exposeSegments();
        }

        // Assert statements and debugging
        if (!debug || !win.console || !win.console.assert) {
            return;
        }
        log(`Pixel URL:${pixel}?${queryString.join('&')}`, {force: 1});
        log('Pixel output Object', params, {force: 1});
        // eslint-disable-next-line no-console
        console.assert(muid && muid.length === 36, 'MUID is invalid %s', muid);
        if (hid) {
            // eslint-disable-next-line no-console
            console.assert(hid.length > 10, 'HID is invalid %s', hid);
        }
        if (win.globalTI.request_id) {
            // eslint-disable-next-line no-console
            console.assert(win.globalTI.request_id.length === 36, 'Request ID is invalid %s', win.globalTI.request_id);
        }
    };

    const newMuuid = () => {
        const host = window.location.hostname.toLowerCase(),
            tld = (function() {
                const x = host.match(/[^.^]+\.\w+$/); if (x) return x[0]; else return '';
            }()).toLowerCase();
        setCookie('muuid_origin', tld ? tld : host);
        setCookie('muuid_source', 'CLIENT');
        setCookie('muuid_date', Date.now());
        if (window.globalTI && window.globalTI.request_id) {
            setCookie('first_request_id', window.globalTI.request_id);
        }
        return getMuid();
    };

    const decode = (x) => {
        tryCatch(() => decodeURIComponent(x), noop);
        return x;
    };

    const storeLookup = (name, valFn, defaultValue) => {
        if (!name) {
            return null;
        }
        let validationFn, kv, i, v;

        validationFn = valFn;
        if (!validationFn) {
            validationFn = function(a) {
                return (a !== null);
            };
        }
        // 1. lookup in localStorage
        v = local[name];
        if (validationFn(v)) {
            return v;
        }
        // 2. lookup in the browser's cookies
        kv = doc.cookie.split(';');
        for (i = 0; i < kv.length; i++) {
            if (kv[i].indexOf(`${name}=`) === 0) {
                v = decode(kv[i].substring(name.length + 1));
                if (validationFn(v)) {
                    return v;
                }
            }
        }
        // 3. lookup in query string params
        kv = win.location.search.replace(/^\?/, '').split('&');
        for (i = 0; i < kv.length; i++) {
            if (kv[i].indexOf(`${name}=`) === 0) {
                v = decode(kv[i].substring(name.length + 1));
                if (validationFn(v)) {
                    return v;
                }
            }
        }
        // 4. return default value
        return defaultValue;
    };

    const getCurrentMUUID = () => {
        let v = win.globalTI ? win.globalTI.session : null;
        if (!isUuid(v)) {
            v = storeLookup('globalTI_SID', isUuid);
            if (!v) {
                v = newMuuid();
            }
            if (win.globalTI) {
                win.globalTI.session = v;
            }
            // set the cookie regardless as it might have come from a set of different sources
            // and it might not be part of the browser's cookies for the site
            setCookie('globalTI_SID', v);
        }
        return v;
    };

    // this function does not exist in the pixel-lite.js file. We added it and must therefore maintain it
    const getMuid = () => {
        // TEST: If a value is passed into the URL as testMuid, it is used as the muid
        if (karmads.urlVars.get('testMuid')) {
            return karmads.urlVars.get('testMuid');
        }

        // TEST: if the Time Session ID is available, it should match the muid
        if (win.globalTI && win.globalTI.session) {
            return win.globalTI.session;
        }
        // TEST: if the Time Session ID is NOT available, the muid should match the globalTI_SID cookie
        const tiSessionCookie = getCookies('globalTI_SID');
        if (tiSessionCookie) {
            return tiSessionCookie;
        }
        log('Generating MUID from scratch', {force: 1});
        return uid();
    };

    // this function does not exist in the pixel-lite.js file. We added it and must therefore maintain it
    const getRequestId = () => {
        // TEST: if the Time Request ID is available, it should match the mrid targeting value
        if (win.globalTI && win.globalTI.request_id) {
            return win.globalTI.request_id;
        }
        log('Generating Meredith Request Id from scratch', {force: 1});
        return uid();
    };

    return {
        init,
        generateMuid: uid,
        getMuid,
        getRequestId
    };

}());