karmads.slots = (function() {
    'use strict';
    const initialLoadSlots = [],
        slotCounter = {};

    const isValidSlotForDevice = (slot) => {
        // TEST: on desktop, our list of GPT slots does not contain any mobile slot names
        // TEST: on mobile, our list of GPT slots should only contain mobile slot names + interstitial/wallpaper/sponsorLogo
        return (slot.isMobile && isMobilePage) || (!slot.isMobile && !isMobilePage);
    };

    // TEST: karmads.slots has a method called getSizes
    const getSizes = (slot, type = 'Slot') => {
        const lcType = type.toLowerCase(),
            sizeKey = `${lcType}Sizes`;
        if (slot.responsive) {
            // get responsive sizes for a given slot
            const responsiveSizeKey = `responsive${type}Sizes`,
                responsiveSizes = ((karma.slots.hbProps.indexOf(responsiveSizeKey) !== -1) ? slot.hb[responsiveSizeKey] : slot[responsiveSizeKey]) || {};
            responsiveSizes.base = (karma.slots.hbProps.indexOf(sizeKey) !== -1) ? slot.hb[sizeKey] : slot[sizeKey];
            return responsiveSizes[getBreakpoint(Object.keys(responsiveSizes))];
        }
        return (karma.slots.hbProps.indexOf(sizeKey) !== -1) ? slot.hb[sizeKey] : slot[sizeKey];
    };

    const convertAdSizes = (adSlot) => {
        // TEST: If the slot is responsive, and the page breakpoint falls within a configured breakpoint on the slot, the size for that breakpoint is used
        // TEST: If the slot is responsive, and the page breakpoint does not fall within any configured breakpoints on the slot (slot.slotSizes), the default size is used
        const slotSizes = getSizes(adSlot),
            adSize = [];

        // convert ad sizes to an array of sizes
        doLoop(slotSizes, (adSlotSizeValues) => {
            // TEST: If a slot has a size of 'fluid', the size of the gpt slot is set as 'fluid'
            if (adSlotSizeValues === 'fluid' || Array.isArray(adSlotSizeValues)) {
                adSize.push(adSlotSizeValues);
            } else {
                adSlotSizeValues = adSlotSizeValues.split('x');
                adSize.push([convertStringToNumber(adSlotSizeValues[0]), convertStringToNumber(adSlotSizeValues[1])]);
            }
        });

        return adSize;
    };

    const setResponseData = (slot, event) => {
        // TEST: each requested slot inside karma.slots.catalog has a property called 'creativeId'
        // TEST: each requested slot inside karma.slots.catalog has a property called 'lineItemId'
        // TEST: each requested slot inside karma.slots.catalog has a property called 'advertiserId'
        // TEST: each requested slot inside karma.slots.catalog has a property called 'isEmpty'
        // TEST: each requested slot inside karma.slots.catalog has a property called 'size'
        slot.creativeId = event.sourceAgnosticCreativeId;
        slot.lineItemId = event.sourceAgnosticLineItemId;
        slot.advertiserId = event.advertiserId;
        slot.isEmpty = event.isEmpty;
        slot.size = event.size;
    };

    const injectOutOfPageSlots = () => {
        log('Creating KARMA interstitial slot.');
        karmads.dom.create('div', doc.body, {'id': 'div-gpt-interstitial', 'data-tier': 1});

        // TEST: if karmaConfig.isMobile is false, the wallpaper slot should be present
        log('Creating KARMA wallpaper slot.');
        karmads.dom.create('div', doc.body, {'id': 'div-gpt-wallpaper', 'data-tier': 1});
    };

    const init = () => {
        const mainSlotsObj = karmads.config.slots.build(karmads.dom.scrapeSlots(true), karmaConfig.site),
            slots = karma.slots.catalog = karma.slots.catalog.concat(mainSlotsObj.adSlots),
            finalSlots = [];

        karma.slots.lazy = mainSlotsObj.lazySlots;

        doLoop(slots, (slot) => {
            if (isValidSlotForDevice(slot) || slot.slotType === 'interstitial')  {
                finalSlots.push(slot);
            }
        });
        // TEST: karma returned valid slots from the config service in a property called slots.catalog, which is a non-empty array
        karma.slots.catalog = finalSlots;
    };

    const define = () => {
        const adSlotsArray = karma.slots.catalog;
        let slotAdUnit,
            gptSlot;

        /* defineSlot calls for each adSlot in the array*/ // karmads.gpt.function define(slot, slotAdUnit, slotName)
        // TEST: the KARMA list of ad slots matches the Google list of ad slots
        doLoop(adSlotsArray, (adSlot) => {
            // TEST: each ad slot without a tierSlugOverride has a valid tiered ad unit
            slotAdUnit = karmads.targeting.adunit.build(adSlot);
            if (adSlot.hasOwnProperty('slotType')) {
                gptSlot = karmads.gpt.define(adSlot, slotAdUnit, adSlot.slotContainer);
                initialLoadSlots.push(gptSlot);
                karmads.targeting.slotSetup(adSlot);
            }
        });

        return initialLoadSlots;

    };

    const getSlot = (slot) => {
        if (!slot) {
            return false;
        }
        slot = isObject(slot) ? slot : getById(slot);
        if (!isObject(slot)) {
            log(`invalid slot id passed into karma.getSlot: ${slot}`, {force: 1, level: 'error', doc: 'karmagetslot'});
            return false;
        }
        return slot;
    };

    // TEST: karmads.slots.getSlotCount returns the length of karmads.slots.catalog
    const getSlotCount = () => karma.slots.catalog.length;

    // TEST: karmads.slots.getById returns a slot object when passed a valid slots id
    const getById = (id) => karma.slots.catalog.filter((slot) => id === slot.slotContainer)[0] || false;

    // TEST: karmads.slots.isLazy returns a boolean when passed a slot object
    const isLazy = (slot) => !!(typeof slot === 'object' && slot.hasOwnProperty('slotContainer') && slot.slotContainer.indexOf('-lazy-') > 0);

    const makeHandle = (slot) => {
        // TEST: karmads.slots has a method called makeHandle
        const tier = slot.tier,
            type = slot.slotType,
            propName = `${type}:tier${tier}`,
            sizeDesignatorMatch = slot.slotContainer.match(/(fixed|flex)/),
            sizeDesignator = sizeDesignatorMatch ? `${sizeDesignatorMatch[0]}-` : '',
            lazyDesignator = isLazy(slot) ? 'lazy-' : '';
        let iterator = slotCounter[propName] || 0;

        iterator++;
        // TEST: each slot has an object property called 'handle' that has the following keys: full, type, tier, instance
        slot.handle = {
            full: `${propName}:${iterator}`,
            type,
            tier: parseInt(tier, 10),
            instance: iterator
        };
        if (moduleExists('hb') && karmaConfig.hb.enabled) {
            // TEST: each slot.hb object has a string property of 'slotName' with the slot name to be used for header bidding
            slot.hb = slot.hb || {};
            slot.hb.slotName = `${lazyDesignator}${type}-${sizeDesignator}tier${tier}`;
            slot.hb.gpid = karmads.hb.generateGpid(slot);
        }
    };

    const matchHandle = (slot, handle) => {
        // TEST: karmads.slots.matchHandle returns true when passed a slot object and a full handle that matches the slot
        // TEST: karmads.slots.matchHandle returns true when passed a slot object and a type handle that matches the slot
        // TEST: karmads.slots.matchHandle returns true when passed a slot object and a type+tier handle that matches the slot
        // TEST: karmads.slots.matchHandle returns false when passed a slot object and a handle that does not matches the slot

        if (typeof slot === undefined) {
            log('Tried to match handle, but slot is undefined', {force: 1});
            return false;
        }

        if (!slot.handle) {
            log(`No handle is available for slot: ${ slot.slotContainer}`, {force: 1});
            return false;
        }

        if (slot.handle.full === handle) {
            return true;
        } // If we have an exact match, no need to parse everything out.

        const slotHandle = slot.handle,
            matchPattern = /^([a-zA-Z-]*)(?::tier)?(\d)?:?(\d+)?$/,
            handleMatch = handle.match(matchPattern) || [],
            matchType = handleMatch[1],
            matchTier = handleMatch[2],
            matchInstance = handleMatch[3];
        let result = true;

        if (matchType !== slotHandle.type || (matchTier !== String(slotHandle.tier) && matchTier !== undefined) || (matchInstance !== String(slotHandle.instance) && matchInstance !== undefined)) {
            result = false;
        }

        return result;
    };

    // TEST: karmads.slots.getSlotIdTierCombos returns an array of strings (slot container + tier) when passed an array of slot objects
    // TEST: the array returned from karmads.slots.getSlotIdTierCombos is the same length as the argument array
    const getSlotIdTierCombos = (slotArray = []) => slotArray.map((slot) => `${slot.slotContainer}${slot.tierString ? `:${slot.tierString}` : ''}`);

    const matchHandleArray = (slot, handleArray = [], slotMustBeDisplayed = false) => {
        // TEST: karmads.slots.matchHandleArray returns true when passed a slot object and an array of handles where at least one matches
        // TEST: karmads.slots.matchHandleArray returns false when passed a slot object and an array of handles where none match
        // TEST: karmads.slots.matchHandleArray returns false when passed a slot object whose dom el is not displayed and slotMustBeDisplayed=true

        let result = false;

        if (slotMustBeDisplayed && karmads.viewability.isElementHidden(karmads.dom.get(slot))) {
            return false;
        }

        doLoop(handleArray, (handle) => {
            if (matchHandle(slot, handle)) {
                result = true;
            }
        });

        return result;
    };

    const destroy = (slotId) => {
        const slot = getById(slotId);
        if (slot) {
            // TEST: when invoked, karmads.slots.destroy removes a slot from the karma.vars.unfilledLazyLoadSlotsd
            karmads.lazyLoad.removeUnfilledSlot(slotId);

            // TEST: when invoked, karmads.slots.destroy removes the slot from GPT
            karmads.gpt.destroy(slot.gptSlot());

            // TEST: when invoked, karmads.slots.destroy removes the slot from the list of user-refreshable slots
            karmads.refresh.removeSlot(slotId, true);

            // TEST: when invoked, karmads.slots.destroy removes the slot from the list of timed-refreshable slots
            karmads.refresh.timed.removeSlot(slot);

            // TEST: when invoked, karmads.slots.destroy removes the slot from karma.slots.catalog
            karma.slots.catalog = karma.slots.catalog.filter((v) => v.slotContainer !== slotId);

            // TEST: when invoked, karmads.slots.destroy removes the slot from karma.slots.active
            karmads.requestQueue.removeFromActiveSlots(slotId);

            karmads.translate.slots();
        }
        return false;
    };

    return {
        init,
        define,
        convertAdSizes,
        makeHandle,
        matchHandle,
        matchHandleArray,
        injectOutOfPageSlots,
        isValidSlotForDevice,
        getSlot,
        getSlotIdTierCombos,
        getSlotCount,
        getById,
        getSizes,
        setResponseData,
        isLazy,
        destroy
    };

}());