karmads.docking = karmads.docking || {};
karmads.docking.leaderboard = (function() {
    /* ---- [ variables ] ---- */
    let additionalLogic,
        backgroundOpacity,
        backgroundRGB,
        bannerSelector,
        bannerZindex,
        customStyles,
        delay,
        dockedClass,
        dockingOffset,
        dockingSlotId,
        dockingSlotEl,
        dockingSlot,
        els,
        enabled,
        offset,
        position,
        cachedBannerHeight,
        rollUp,
        rollUpMs,
        rollUpTimerId,
        settings,
        timer,
        timerStarted,
        timerExpired,
        topMargin,
        transitionMs,
        useCSS,
        useDefaultHeight,
        // events
        dockEvent,
        undockingEvent,
        undockedEvent,
        // selectors
        body,
        banner,
        belowBanner,
        siteContainer,
        placeholder,
        shouldDock,
        shouldRelease;

    const getBannerSelectorHeight = () => {
        // get total banner height
        if (enabled) {
            const elmHeight = banner.clientHeight < 90 ? 90 : banner.clientHeight,
                elmMargin = parseInt(doc.defaultView.getComputedStyle(banner, '').getPropertyValue('margin-top'), 10) + parseInt(doc.defaultView.getComputedStyle(banner, '').getPropertyValue('margin-bottom'), 10);
            return elmHeight + elmMargin;
        }
        return 0;
    };

    const isEnabled = () => {
        karmaConfig.docking = karmaConfig.docking || {};
        // TEST: if karmaConfig.docking or karmaConfig.docking.leaderboard is not set, KARMA still initializes
        settings = karmaConfig.docking.leaderboard || {};
        if (typeof enabled !== 'boolean') {
            enabled = convertStringToBoolean(settings.enabled);
        }
        // TEST: Leaderboard docking is always disabled on mobile
        if (karmaConfig.isMobile) {
            enabled = false;
        }
        if (enabled && settings.disableSmallScreen && win.innerWidth <= 1024) {
            enabled = false;
        }
        return enabled;
    };

    const dockingProcedure = () => {

        if (!isEnabled()) {
            return;
        }

        // eslint-disable-next-line func-style
        function startRollUpTimer() {

            // eslint-disable-next-line func-style
            function RollUpTimer(callback, rollUpDelay) {
                let start, remaining = rollUpDelay;

                this.pause = () => {
                    win.clearTimeout(rollUpTimerId);
                    remaining -= new Date() - start;
                    log(`Rollup timer paused! ${remaining} remaining`);
                };

                this.resume = () => {
                    start = new Date();
                    win.clearTimeout(rollUpTimerId);
                    rollUpTimerId = win.setTimeout(callback, remaining);
                    log('Rollup timer resumed!');
                };

                this.resume();
            }

            // TEST: if docking.leaderboard.rollUp is true, the docked leaderboard rolls up after a given number of seconds
            timerStarted = true;
            timer = new RollUpTimer(() => {
                timerExpired = true;
                if (position !== 'docked') {
                    disable();
                    return;
                }
                releaseBanner(true);
            }, delay);

            // TEST: if the user mouses over a docked leaderboard, the rollup timer pauses
            banner.addEventListener('mouseover', timer.pause);
            // TEST: if the user mouses off a docked leaderboard, the rollup timer resumes
            banner.addEventListener('mouseout', timer.resume);
        }

        const dock = () => {
            karmads.docking.rail.leaderboardEventHandler('leaderboardDocked');
            doLoop(els, (el) => el.classList.add(dockedClass));
            body.dispatchEvent(dockEvent);
        };

        shouldDock = (!timerExpired && position !== 'docked' && offset - topMargin - dockingOffset < win.pageYOffset && !dockingSlot.collapse);
        shouldRelease = (position === 'docked' && offset - topMargin - dockingOffset >= win.pageYOffset);

        if (shouldDock) {
            dock();
            position = 'docked';

            if (rollUp && !timerStarted) {
                startRollUpTimer();
            }
        } else if (shouldRelease) {
            releaseBanner();
        }
    };

    const init = () => {
        const initSelectors = () => {
            // TEST: the selectors array contains the banner, placeholder, and siteContainer elements
            const elArr = [banner, placeholder];
            if (siteContainer !== null) {
                elArr.push(siteContainer);
            }
            return elArr;
        };

        const addEventListeners = () => {
            doLoop(['touchstart', 'touchmove', 'touchend', 'scroll'], (action) => doc.addEventListener(action, dockingProcedure));
        };

        const getAndCacheCreativeHeight = (e, slotId) => {
            // TEST: if the returned leaderboard is greater than 90 pixels high, don't dock the leaderboard
            const slot = karmads.slots.getById(slotId);
            let gptDiv;

            if (Array.isArray(e.size)) {
                cachedBannerHeight = e.size[1];
            } else {
                gptDiv = karmads.dom.lookup('div[id^="google_ads_iframe"]', false, false, karmads.dom.get(slot));
                cachedBannerHeight = (gptDiv !== null) ? gptDiv.offsetHeight : 0;
            }
            if (e.isEmpty) {
                return 0;
            }
            return cachedBannerHeight;
        };

        // settings and corresponding defaults are processed
        karmaConfig.docking = karmaConfig.docking || {};
        settings = settings || (karmaConfig.docking.leaderboard || {});

        // internal vars
        karmaVars.leaderboardInitialState = settings.enabled || false;
        rollUpTimerId = null;
        delay = 0;
        offset = 0;
        els = [];
        timerStarted = false;
        timerExpired = false;
        position = 'undocked';

        // configurable settings
        enabled = isEnabled();

        if (!enabled) {
            return;
        }

        additionalLogic = settings.additionalLogic || [];
        // TEST: if docking.leaderboard.backgroundOpacity is not set, the background opacity on bannerSelector is .7
        backgroundOpacity = settings.backgroundOpacity || '.7';
        // TEST: if docking.leaderboard.backgroundRGB is not set, the background color on bannerSelector is 255,255,255
        backgroundRGB = settings.backgroundRGB || '255,255,255';
        bannerSelector = settings.dockingElementSelector || '';
        // TEST: if docking.leaderboard.zIndex is not set, the z-index on bannerSelector is 595
        bannerZindex = (settings.zIndex) || 595;
        customStyles = settings.customStyles || '';
        // TEST: if docking.leaderboard.dockedClass is not set, the class on the banner placeholder and the bannerSelector is 'docked'
        dockedClass = settings.dockedClass || 'docked';
        dockingOffset = convertStringToNumber(settings.offset) || 0;
        // TEST: if docking.leaderboard.rollUp is not set, the docked leaderboard never undocks
        rollUp = convertStringToBoolean(settings.rollUp) || false;
        // TEST: if docking.leaderboard.rollUpMs is not set and docking.leaderboard.rollUp is true, the docked leaderboard undocks after 10 seconds
        rollUpMs = convertStringToNumber(settings.rollUpMs) || 10000;
        // TEST: if docking.leaderboard.topMargin is not set, the docked banner has a top position of 0
        topMargin = convertStringToNumber(settings.topMargin) || 0;
        transitionMs = convertStringToNumber(settings.transitionMs) || 1000;
        useCSS = convertStringToBoolean(settings.useCSS) || true;
        useDefaultHeight = convertStringToBoolean(settings.useDefaultHeight) || false;

        // selectors
        body = doc.body;
        banner = settings.dockingElementSelector ? karmads.dom.lookup(settings.dockingElementSelector) : null;
        belowBanner = settings.belowBannerSelector ? karmads.dom.lookup(settings.belowBannerSelector) : null;
        siteContainer = settings.siteContainerSelector ? karmads.dom.lookup(settings.siteContainerSelector) : null;
        placeholder = null;

        dockEvent = createEvent('leaderboard_docked');
        undockingEvent = createEvent('leaderboard_undocking');
        undockedEvent = createEvent('leaderboard_undocked');

        if (rollUp && !isNaN(convertStringToNumber(rollUpMs, 10))) {
            delay = rollUpMs;
        }

        // TEST: if the required DOM elements are missing, leaderboard docking is disabled
        if (!banner || !belowBanner || !siteContainer) {
            log('Missing element for leaderboard docking. Disabling.', {force: 1, level: 'warn', doc: 'karma-docking'});
            enabled = false;
        } else {
            // TEST: if additional docking logic is defined and it evals to false, leaderboard docking is disabled
            enabled = enabled && karmads.docking.processAdditionalLogic(additionalLogic);
        }

        // TEST: if karmaConfig.docking.leaderboard is defined, the enabled status is altered to match module logic
        if (karmaConfig.docking.leaderboard) {
            karmaConfig.docking.leaderboard.enabled = enabled;
        }

        if (!isEnabled()) {
            // Some checks failed, early return;
            return;
        }

        log('initializing docking banner...');

        // TEST: if leaderboard docking is enabled, an element with an id of docking-banner-placeholder exists and is a sibling of the belowBanner selector
        const bannerHeight = banner.offsetHeight;
        placeholder = karmads.dom.create(
            'div',
            belowBanner.parentElement,
            {id: 'docking-banner-placeholder'},
            belowBanner
        );

        // selectors are initialized
        els = initSelectors();

        // TEST: if docking.leaderboard.useCSS is true, a stylesheet is appended to the document with docking styles
        if (useCSS) {
            const css = `
            ${bannerSelector}{
                position: relative;
                top: 0;
                z-index: ${bannerZindex}
            }
            #${placeholder.id}{
                height: 0;
                width: 100%;
                background-color: rgb(${backgroundRGB})
            }
            #${placeholder.id}.${dockedClass}{
                height: ${ bannerHeight}px
            }
            ${useDefaultHeight && `
            .${dockedClass} #div-gpt-leaderboard-flex-1{
                min-height: 100px;
                padding: 5px 0;
                box-sizing: border-box
            }` || ''}
            #div-gpt-leaderboard-flex-1{
                text-align: center;
                overflow: hidden; 
                box-sizing: border-box;
                position: relative;
                left: 0;
                width: 100%; 
                top: 0
            }
            ${bannerSelector}.${dockedClass}{
                position: fixed;
                background: rgba(${backgroundRGB},${backgroundOpacity});
                top: ${topMargin}px;
                left: 0;
                right: 0;
                width: 100%;
                ${useDefaultHeight && 'height: 100px' || ''}
            }
            ${bannerSelector}.undocking{
                top: -${bannerHeight + 20}px;
                transition: top 1s;
                z-index: ${bannerZindex}
            }
            ${customStyles}`;
            karmads.docking.appendStyleSheetCss(css);
        }

        // the working value of offset matches the banner selector's top position
        offset = banner.offsetTop;
        dockingSlotEl = karmads.dom.lookup('div[id^="div-gpt-leaderboard"]', false, false, banner) || {};
        dockingSlotId = dockingSlotEl.id;
        dockingSlot = karmads.slots.getById(dockingSlotId);
        // TEST: if the banner element is not null, a callback is added to the GPT queue that checks the size of the returned banner, initiates event selectors, and starts the docking procedure
        karmads.gpt.addCallback((e) => {
            const slotId = e.slot.getSlotId().getDomId();

            if (slotId === dockingSlotId) {
                const creativeHeight = getAndCacheCreativeHeight(e, slotId);

                if (isCreativeHeightDockable(creativeHeight)) {
                    if (!isEnabled() && karmaVars.leaderboardInitialState) {
                        restart();
                        addEventListeners();
                    } else if (isEnabled()) {
                        addEventListeners();
                        dockingProcedure();
                    }
                } else {
                    // TEST: If the creative does not dock because it is too large, docking passes 'sizeCheck' into forceReleaseBanner which is ready by the bookmarklet
                    forceReleaseBanner('sizeCheck');
                }
            }
        });
    };

    const releaseBanner = (useTransition) => {
        const undock = () => {
            // TEST: when the banner undocks, the dockedClass is removed from the site container and the banner
            banner.classList.remove(dockedClass);
            doLoop(els, (el) => {
                if (el !== banner) {
                    el.classList.remove(dockedClass);
                }
            });

            if (rollUp && timerExpired) {
                disable();
            }

            position = 'undocked';
            // TEST: when the banner undocks, the leaderboard_undocked event is dispatched to the body (RVDEV-1689, RVDEV-1731)
            body.dispatchEvent(undockedEvent);
            banner.classList.remove('undocking');
        };

        // TEST: when the user scrolls to the top of the page, the banner undocks
        // TEST: when the banner undocks, a class of undocking is added and subsequently removed after a timeout of transitionMS (default 1s)
        body.dispatchEvent(undockingEvent);
        if (useTransition) {
            position = 'undocking';
            banner.classList.add(position);
            karmads.docking.rail.leaderboardEventHandler('leaderboardUndocked', true);
            win.setTimeout(undock, transitionMs);
        } else {
            karmads.docking.rail.leaderboardEventHandler('leaderboardUndocked');
            undock();
        }
    };

    const forceReleaseBanner = (method) => {
        // TEST: when forceReleaseBanner is called, docking should be disabled.
        if (isEnabled()) {
            enabled = false;
            karmaConfig.docking.leaderboard.enabled = false;
            karmaVars.leaderboardReleasedBy = method;
            if (position !== 'docked') {
                disable();
                return;
            }
            releaseBanner();
        }
    };

    const disable = () => {
        // TEST: when the banner undocks, the touch/scroll event listeners are removed from the document
        doLoop(['touchstart', 'touchmove', 'touchend', 'scroll'], (action) => {
            doc.removeEventListener(action, dockingProcedure);
        });
        // TEST: when the banner undocks, the undock timer is cleared
        win.clearTimeout(rollUpTimerId);
        // TEST: when the banner undocks, the hover pause event is removed from the banner
        if (timerStarted) {
            banner.removeEventListener('mouseover', timer.pause);
            banner.removeEventListener('mouseout', timer.resume);
        }
        timer = null;
    };

    // TEST: karmads.docking.leaderboard has a method called getPosition
    // TEST: getPosition returns the current position of the docking-enabled leaderboard
    const getPosition = () => position;

    const restart = () => {
        // TEST: karma.initDocking is a function
        // TEST: karma.initDocking restarts leaderboard docking if it was previously enabled.
        if (karmaVars.leaderboardInitialState && !isEnabled() && isCreativeHeightDockable(cachedBannerHeight)) {
            enabled = true;
            karmaConfig.docking.leaderboard.enabled = true;
            init();
            dockingProcedure();
        } else {
            log('Attempted to start leaderboard docking, but could not due to initial enabled state, or current leaderboard size.');
        }
    };

    const isCreativeHeightDockable = (bannerHeight) => bannerHeight !== 0 && bannerHeight <= 90;

    /* ---- [ Public methods ] ---- */
    return {
        init,
        isEnabled,
        forceReleaseBanner,
        restart,
        getPosition,
        getBannerSelectorHeight
    };

}());
