// TODO (audit): I would still like to separate out report/log logic at some point
karmads.log = (function() {
    'use strict';
    let count = 0;
    const logObject = {},
        partnerEventLoggedOnce = {},
        timingSupported = !!win.performance && typeof win.performance.now === 'function',                     /* Check for performance timing support */
        pageRenderStart = timingSupported ? performance.timing.responseEnd - performance.timing.fetchStart : 0;   /* Set start of page render as event 0 */

    const log = (message, options) => {

        if (typeof message === 'object') {
            options = message;
            message = options.label || '';
        } else {
            options = options || {};
        }

        const getRange = (n) => {
            const floor = Math.floor(n / 100),
                low = (floor === n / 100) ? floor * 100 - 99 : floor * 100 + 1,
                high = low + 99;
            return `${low}-${high}`;
        };

        let report = options.report || false;

        const referencePoint = options.referencePoint || false,
            level = (options.level) ? options.level : (options.color === 'red') ? 'error' : 'log',
            now = timingSupported ? Math.floor(performance.now()) : 0,
            timestamp = now - pageRenderStart,
            eventDelta = (referencePoint && logObject[referencePoint]) ? timestamp - logObject[referencePoint].timestamp : false,
            eventDeltaMessage = eventDelta ? ` : ${referencePoint} +${eventDelta}` : '',
            range = (report === 'range' && eventDelta) ? getRange(eventDelta) : false,
            showLabel = !(!options.label || message === options.label),
            forceMessage = options.force || false,
            docsLink = options.doc || false,
            reportEventType = options.reportEventType,
            reportLabel = options.reportLabel || false,
            payload = options.payload,
            reportValueOverride = options.reportValueOverride || null;

        count++;

        let label = options.label || `entry${count}`;

        if (logObject[label]) {
            label = `entry${count}`;
            // TEST: log items are only reported the first time they are logged
            report = false;
        }

        // TEST: karma.vars has an object property called logObject
        // TEST: each log object has these properties: consoleMessage,eventDelta,eventDeltaMessage,referencePoint,report,reportLabel,reported,timestamp
        logObject[label] = {
            reportValueOverride,
            timestamp,
            referencePoint,
            eventDelta,
            eventDeltaMessage,
            consoleMessage: `${timestamp}${showLabel && ` : ${label}` || ''} : ${message}${eventDeltaMessage}${range && ` (${range})` || ''}`,
            report,
            reportEventType,
            reportLabel,
            payload,
            reportValue: reportValueOverride || eventDelta || timestamp,
            reported: false,
            level
        };

        // TEST: if karma.vars.debug is set to true or the forceMessage param is passed, the log event is added to the browser's console
        if ((karmaVars.debug || forceMessage)) {
            karmads.debug.consoleLog(message, level, docsLink);
        }
    };

    const logOnceAndReport = (message, options) => {
        if (typeof message === 'object') {
            options = message;
            message = options.label || '';
        } else {
            options = options || {};
        }

        if (!partnerEventLoggedOnce[options.label]) {
            partnerEventLoggedOnce[options.label] = true;
            options = options || {};
            options.report = true;
            log(message, options);
        }
    };

    const getTiming = (url) => {
        if (!timingSupported) {
            return false;
        }

        const entries = performance.getEntriesByType('resource'),
            targetEntry = entries.filter((entry) => entry.name === url);

        return targetEntry.length ? Math.floor(targetEntry[0].duration) : false;
    };

    karmaVars.logObject = logObject;

    return {
        getTiming,
        log,
        logOnceAndReport
    };

}());

log = karmads.log.log;
