karmads.docking = (function() {
    'use strict';
    let styleSheetCss = '';

    const init = () => {
        karmads.docking.leaderboard.init();
        karmads.docking.rail.init();
    };

    const processAdditionalLogic = (params) => {
        // TEST: if additional logic is defined, it's considered before enabling docking
        if (!params || !Array.isArray(params)) {
            return false;
        }
        let dockBanner = true;
        doLoop(params, (param) => {
            if (!eval(param)) { // eslint-disable-line no-eval
                dockBanner = false;
            }
        });
        return dockBanner;
    };

    const appendStyleSheetCss = (css) => {
        // TEST: a stylesheet is appended to the document with docking styles for the leaderboard and right rail
        styleSheetCss += css.replace(/[\s]{2,}/g, '');
        const existingStyleSheet = karmads.dom.lookup('#dockingStyles', false, true);

        if (styleSheetCss.length > 0) {
            if (existingStyleSheet === null) {
                karmads.dom.create(
                    'style',
                    karmaVars.els.head, {
                        id: 'dockingStyles',
                        innerHTML: styleSheetCss
                    }
                );
            } else {
                existingStyleSheet.innerHTML = styleSheetCss;
            }
        }
    };

    const targeting = () => {
        // TEST: When the leaderboard is docked, docking.targeting() sets a page targeting value of 'dockedleaderboard' to 'true'
        // TEST: When the leaderboard is undocked or undocking, docking.targeting() sets a page targeting value of 'dockedleaderboard' to 'false'
        // TEST: On pages where the right rail is docked, a page targeting value of "dockedrail" should be present and set to "true"
        if (karmaConfig.docking) {
            const types = ['leaderboard', 'rail'];
            let isDocked;
            doLoop(types, (type) => {
                if (karmaConfig.docking[type]) {
                    isDocked = (type === 'leaderboard') ? karmads.docking.leaderboard.getPosition() === 'docked' : karmads.docking[type].isEnabled();
                    karmads.targeting.set(`docked${type}`, isDocked);
                }
            });
        }
    };

    const disableDocking = (type = 'leaderboard') => {
        // TEST: karma.releaseDocking() disables docking, on both, just leaderboard, or just rail
        // pass 'both', 'leaderboard', 'rail' or nothing (to get default) to specify which to disable (default is 'leaderboard' for pre-existing use cases)
        log(`${(type === 'both' ? 'leaderboard and rail docking have' : `${type} docking has`)} been disabled by karma.releaseDocking()`);
        switch (type) {
        case 'both':
            karmads.docking.rail.disableAllRails();
            karmads.docking.leaderboard.forceReleaseBanner('method');
            break;
        case 'rail':
            karmads.docking.rail.disableAllRails();
            break;
        case 'leaderboard':
        default:
            karmads.docking.leaderboard.forceReleaseBanner('method');
        }

    };

    return {
        processAdditionalLogic,
        appendStyleSheetCss,
        disableDocking,
        targeting,
        init
    };
}());