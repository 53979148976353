karmads.dom = (function() {
    'use strict';

    const scrapeSlots = (cleanSlots) => {
        // grab divs that begin with div-gpt
        const gptDivs = lookup('div[id^="div-gpt"]', true),
            adSlots = [];

        let slotId,
            slotTier,
            gptDivData;

        doLoop(gptDivs, (gptDiv) => {
            // clear out unnessecary whitespace if needed
            if (cleanSlots && gptDiv.childNodes.length) {
                gptDiv.textContent = '';
            }
            gptDivData = gptDiv.dataset;
            slotTier = (gptDivData && karmads.tiers.isValidTier(gptDivData.tier)) ? gptDivData.tier : 0;
            slotId = `${gptDiv.id}:tier${slotTier}`;

            // if the slot tier is zero, log a warning
            if (slotTier === 0) {
                log(`Slot ${slotId} does not have a valid data-tier attribute. <div id="${slotId}"${gptDivData.tier && ` data-tier="${gptDivData.tier}"`}>. It will be requested as tier0.`, {force: 1, level: 'warn'});
            }
            // don't push duplicates in
            if (adSlots.indexOf(slotId) === -1) {
                adSlots.push(slotId);
            }
        });

        // if there are no slots on the page, push in a dummy slot name
        if (adSlots.length === 0) {
            adSlots.push('none');
        }

        return adSlots;
    };

    const get = (slot) => {
        // if you passed in a string, grab the slot object
        slot = (typeof slot === 'string') ? karmads.slots.getById(slot) : slot;
        if (slot) {
            // if we don't already have a .dom property, add it
            if (!slot.dom) {
                slot.dom = doc.getElementById(slot.slotContainer);
            }
            return slot.dom;
        }
        return null;
    };

    // TEST: karmads.dom has a function called ready
    // TEST: All ad divs (div-gpt-whatever) are accounted for in karma.slots.catalog
    const ready = (callback) => {
        // TEST: If the API version is 3, karmaConfig.go is a function
        let callbackTriggered = false;

        const siteHasCalledGo = () => !!(Array.isArray(karma.cmd) && karma.cmd.filter((item) => item === 'go').length > 0);

        const oneTimeCallbackTrigger = (callback, logMessage) => {
            // TEST: If the KARMA DOM ready callback is called multiple times, it only fires once.
            if (!callbackTriggered) {
                callbackTriggered = true;
                if (logMessage) {
                    log(`${logMessage}, slot setup continuing`);
                }
                callback();
            }
        };

        // TEST: If the API version is 2, do not wait for karmaConfig.go or DOMContentLoaded
        if (apiVersion === 2) {
            oneTimeCallbackTrigger(callback);
            return;
        }

        if (karmaConfig.bypassDOMReady || doc.readyState === 'loading') {
            if (siteHasCalledGo()) {
                oneTimeCallbackTrigger(callback, 'Site already called go');
            } else {
                karmaConfig.go = () => oneTimeCallbackTrigger(callback, 'Site is calling go');
                if (!karmaConfig.bypassDOMReady) {
                    doc.addEventListener('DOMContentLoaded', () => oneTimeCallbackTrigger(callback, 'DOMContentLoaded firing'));
                }
            }
        } else {
            oneTimeCallbackTrigger(callback, 'DOMContentLoaded already triggered');
        }
    };

    const isDisplayed = (slot) => {
        const dom = get(slot),
            isDisplayed = dom ? !!(dom.offsetWidth || dom.offsetHeight || dom.getClientRects().length) : false;
        // TEST: if a slot is hidden and bypass is false, karmads.dom.getVisibleSlots sets a property of isDisplayed to false on the slot
        // TEST: if a slot is not hidden, karmads.dom.getVisibleSlots sets a property of isDisplayed to true on the slot
        slot.isDisplayed = isDisplayed;
        return isDisplayed;
    };

    const bypassVisibilityCheck = (slot) => {
        // TEST: If the data-karma-bypass-visibility-check is set on the slot, the attribute is set to 'processed' and the value is applied to the slot object
        const dom = get(slot),
            dataAttributes = dom.dataset,
            visibilityCheckAttribute = dataAttributes['karmaBypassVisibilityCheck'],
            visibilityCheckAttributeBool = convertStringToBoolean(visibilityCheckAttribute);

        if (visibilityCheckAttribute !== undefined && visibilityCheckAttribute !== 'processed') {
            slot.bypassVisibilityCheck = visibilityCheckAttributeBool;
            dataAttributes['karmaBypassVisibilityCheck'] = 'processed';
        }

        return slot.bypassVisibilityCheck || false;
    };

    // TEST: karmads.dom has a method called getVisibleSlots
    // TEST: karmads.dom.getVisibleSlots returns all the slots that are not hidden or hidden and bypassed
    const getVisibleSlots = (slots) => {
        slots = slots || [];
        const visibleSlots = [];

        // loop through slots
        doLoop(slots, (slot) => {
            // if it's displayed or out of page, or the bypass visibility check is on, it's visible
            // TEST: Hidden slots are returned if slot.bypassVisibilityCheck is true
            if (isDisplayed(slot) || slot.outOfPage || bypassVisibilityCheck(slot)) {
                visibleSlots.push(slot);
            } else {
                // TEST: if a slot is hidden and bypass is false, a message is logged saying the slot won't be requested
                log(`Did not request ${slot.slotContainer} because the container is not displayed.`, {force: 1, level: 'warn'});
            }
        });
        return visibleSlots;
    };

    const lookup = (selectorString, getAll = false, bustCache = false, parentElement) => {
        let uniqueSelectorString = selectorString;
        if (parentElement && parentElement.id) {
            // TEST: If a parent with an id is passed in, the cache property name contains the id of the parent
            uniqueSelectorString = `#${parentElement.id} ${selectorString}`;
        } else {
            parentElement = doc;
        }
        // check in the element cache if bustCache is not set
        // TEST: If bustCache is true, the cached value is returned regardless of the getAll value
        // TEST: If bustCache is false, a fresh lookup occurs and the result is stored in cache
        if (!bustCache && karmaVars.els.hasOwnProperty(uniqueSelectorString)) {
            return karmaVars.els[uniqueSelectorString];
        }
        // all looked up elements are cached in karma.vars.els
        // if you pass in getAll, it looks up multiples; the default is to just grab one element
        const isASimpleIdString = selectorString.charAt(0) === '#' && selectorString.indexOf(' ') === -1;
        karmaVars.els[uniqueSelectorString] =
            isASimpleIdString
                // TEST: If an only id is passed in as the selectorString, the getElementById result is returned
                ? parentElement.getElementById(selectorString.substring(1))
                : getAll
                    // TEST: If getAll:true is passed in and the selector is a class selector, an array of elements is returned
                    ? parentElement.querySelectorAll(selectorString)
                    // TEST: If getAll: false is passed in and the selector is class selector, only the first instance is returned
                    : parentElement.querySelector(selectorString);
        return karmaVars.els[uniqueSelectorString];
    };

    const create = (tagName, appendTo = false, props, beforeEl = false) => {
        // TEST: should create an element inside the specified dom element
        // TEST: (beforeEl specified) create a new dom element and place inside a node, and before a specific node
        // TEST:  Newly created elements have the attributes as passed in via 'props'
        // create the element
        const el = doc.createElement(tagName);
        // add attributes to the element
        if (typeof props === 'object') {
            doLoop(props, (prop) => {
                if (prop.indexOf('data-') !== -1) {
                    el.setAttribute(prop, props[prop]);
                } else {
                    el[prop] = props[prop];
                }
            });
        }
        // add it to the dom
        if (appendTo) {
            // specify dom element that the new element should go before
            if (beforeEl) {
                appendTo.insertBefore(el, beforeEl);
            // otherwise, append to the element
            } else {
                appendTo.appendChild(el);
            }
        }
        return el;
    };

    const remove = (node) => node.parentNode.removeChild(node);

    return {
        isDisplayed,
        scrapeSlots,
        get,
        create,
        lookup,
        ready,
        remove,
        getVisibleSlots
    };

}());