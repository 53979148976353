karmads.refresh = karmads.refresh || {};
karmads.refresh.timed = (function() {
    'use strict';
    /* Module locals (defined in init) */
    const activeMap = {},
        listeners = ['scroll', 'focus', 'blur', 'click'];
    let adSlots,
        map = [],
        slotCount,
        debounceCheck,
        refreshSettings,
        timedSettings,
        intervals,
        listenersActive = false,
        refHub;

    const init = () => {
        // TEST: karmads.refresh.timed has a method called init
        if (isEnabled()) {
            adSlots = karma.slots.catalog;
            refreshSettings = karmaConfig.refresh || {};
            timedSettings = refreshSettings.timed || {};
            refHub = timedSettings.refHubOverrides || {};
            intervals = getDaypartedIntervals() || refHubShouldOverride() || timedSettings.intervals || {};
            map = timedSettings.map || [];
            slotCount = 0;
            debounceCheck = debounce(check, 250);

            /* Add X-domain listener */
            win.addEventListener('message', (e) => {
                if (typeof e.data === 'string' && e.data.indexOf('karmaStopRefresh') !== -1) {
                    try {
                        const data = JSON.parse(e.data);
                        // Note: this used to be set to permanent: true, but we removed it in RVDEV-3727 so that we could reenable timed refresh on sponsorship content that has been auto-advanced to non-sponsorship content
                        karmads.refresh.timed.disableTimedRefreshOnSlot(`div-gpt-${data.slot}`);
                    } catch (err) {
                        log(err.toString(), {level: 'error', force: 1});
                    }
                }
            }, false);

            check();
        }
    };

    const isEnabled = () => {
        // TEST: karmads.refresh.timed has a method called isEnabled and it returns a boolean

        if (karmaConfig.refresh
                && karmaConfig.refresh.timed
                && karmaConfig.refresh.timed.enabled
                && karmaConfig.refresh.timed.map
                && typeof karmaConfig.refresh.timed.map === 'object'
                && Object.keys(karmaConfig.refresh.timed.map).length > 0) {
            return true;
        }

        log('Timed refresh disabled for this page by config.');
        return false;
    };

    const check = () => {
        // TEST: karmads.refresh.timed has a method called check
        if (!isEnabled()) {
            return;
        }

        const slotsAreValid = loopSlots();
        let isRefreshable,
            slot,
            duration;

        if (slotCount > 0) {
            if (!slotsAreValid) {
                log('An ad slot is missing a handle. Aborting slot swap.', {force: 1, level: 'warn'});
                return;
            }

            doLoop(activeMap, (slotId) => {
                slot = karmads.slots.getById(slotId);
                isRefreshable = isTimedRefreshable(slot);
                duration = getDuration(slot);

                if (slot.requested && !slot.timedRefresh.timerRunning && isRefreshable) {
                    startSlotTimer(slot, duration[0], duration[1]);
                } else if (!isRefreshable && slot.timedRefresh.timerRunning) {
                    pauseSlotTimer(slot);
                }
            });
        }
    };

    const loopSlots = () => {
        let prop,
            slotMap,
            slotMapCopy;

        adSlots = karma.slots.catalog;

        doLoop(adSlots, (slot) => {

            if (typeof slot.timedRefresh !== 'object') {
                slot.timedRefresh = {};
            }

            if (!slot.handle || !slot.handle.full) {
                return false; // Tisk tisk, there is a missing handle somewhere, so we'll give up
            }

            if (!slot.requested) {
                return false; // TEST: Slots that have not been requested yet are not watched for timed refresh
            }

            // TEST: If timed refresh is enabled and activeMap is an object, the activeMap align with the members of karmaConfig.timed.map
            if (!activeMap.hasOwnProperty(slot.slotContainer) && slot.timedRefresh.enabled !== false) {
                for (prop in map) { // Using a for loop here to utilize 'break' in order to avoid unneeded iterations
                    slotMap = map[prop];

                    if (karmads.slots.matchHandle(slot, prop) && slotMap.mode) {
                        slotMapCopy = clone(slotMap);
                        activeMap[slot.slotContainer] = slotMapCopy;
                        slotCount++;
                        slot.timedRefresh = slotMapCopy;
                        break;
                    }
                }
                slot.timedRefresh.enabled = false;
            }
        });
        if (slotCount > 0 && !listenersActive) {
            // Add listeners
            listenersActive = true;
            doLoop(listeners, (listener) => {
                win.addEventListener(listener, debounceCheck, {passive: true, capture: true}); // useCapture: true. This way clicks are registered even if the target is removed (e.g. a close button on a modal)
            });
        } else if (slotCount <= 0 && listenersActive) {
            // Remove listeners
            listenersActive = false;
            doLoop(listeners, (listener) => win.removeEventListener(listener, debounceCheck));
        }
        return true;
    };

    // returns the map of active timed refresh slots
    const getActiveMap = () => activeMap;

    const startSlotTimer = (slot, duration, bidTimeout) => {
        // TEST: karmads.refresh.timed has a method called startSlotTimer
        const slotSettings = slot.timedRefresh || {};
        slotSettings.timerRunning = true;
        slotSettings.duration = duration = duration / 1000;
        slotSettings.bidTimeout = bidTimeout / 1000;
        // TEST: If the timer has started, the slot.timedRefresh.timer and slot.timedRefresh.duration are numbers
        if (typeof slotSettings.timer !== 'number') {
            slotSettings.timer = duration;
        }

        log(`Starting timer for ${slot.slotContainer}: ${slotSettings.timer}`);

        slotSettings.interval = setInterval((function(slot) {
            return () => {
                slot.timedRefresh.timer--;
                if (slot.timedRefresh.timer > 0 || !karmads.slots.getById(slot.slotContainer)) {
                    /* Do nothing */
                } else {
                    pauseSlotTimer(slot);
                    if (isSlotInView(slot)) {
                        slot.timedRefresh.timer = null;
                        if (slot.timedRefresh.mode === 'swap') {
                            swap(slot);
                        } else if (slot.timedRefresh.mode === 'refresh') {
                            slot.shouldIncrementRefresh = true;
                            slot.refreshType = 'timed';
                            karmads.requestQueue.process([slot]);
                        }

                    }
                }
            };
        }(slot)), 1000);
    };

    const pauseSlotTimer = (slot) => {
        // TEST: karmads.refresh.timed has a method called pauseSlotTimer
        // TEST: If the timer is running when pauseSlotTimer is called, slot.timedRefresh.timerRunning is set to false
        const slotSettings = slot.timedRefresh || {};
        log(`Pausing timer for ${slot.slotContainer}: ${slotSettings.timer}`);
        slotSettings.timerRunning = false;
        clearInterval(slotSettings.interval);
        slotSettings.interval = false;
    };

    const resetSlotTimer = (slot) => {
        // TEST: karmads.refresh.timed has a method called resetSlotTimer
        // TEST: When karmads.refresh.timed.resetSlotTimer is called, the timer resets to the initial duration that was passed into startSlotTimer
        const slotSettings = slot.timedRefresh || {};
        slotSettings.timer = slotSettings.duration;
        log(`Resetting timer for ${slot.slotContainer}: ${slotSettings.timer}`);
    };

    // swaps out a slot for another slot
    const swap = (slot) => {
        const dom = karmads.dom.get(slot),
            slotSettings = slot.timedRefresh,
            slotContainer = slot.slotContainer,
            typeMatch = slotContainer.match(/(.*)-\d/),
            type = typeMatch ? typeMatch[1] : slotContainer,
            swapTo = `${type}-tier${slotSettings.swapToTier}`;
        let result = false;

        if (!slotSettings.filled && slot.requested && dom !== null) {
            // change the dom id of the current slot to a container for the new slot
            dom.id = swapTo.replace('div-gpt', 'karma-reload-container');
            // set the height and width to avoid jumpiness during swap
            dom.style.height = `${dom.clientHeight}px`;
            dom.style.width = `${dom.clientWidth}px`;
            // destroy the original slot
            karmads.slots.destroy(slotContainer);
            // lazy-load the new slot
            const slotId = karmads.lazyLoad.create(swapTo, dom, false, () => {
                    dom.style.height = '';
                    dom.style.width = '';
                }, {refreshType: 'swap'}).id,
                newSlot = karmads.slots.getById(slotId);
            log(`Swapping ${slot.handle.full} to ${newSlot.handle.full}`);
            /* Keep refresh count from initial slot */
            newSlot.refreshCount = slot.refreshCount || 0;
            newSlot.shouldIncrementRefresh = true;
            // fill the swapped slot
            karmads.lazyLoad.fill();
            result = true;
        } else {
            log(`Tried to swap ${dom.id}, but failed. Disabling swap on this slot.`);
            removeSlot(slot);
        }
        return result;
    };

    const isSlotInView = (slot) => karmads.viewability.isInView(slot);

    const isTimedRefreshable = (slot) => {
        if (!slot.requested || slot.inFlight) {
            return false; // TEST: If a slot has not been requested yet, or is still in flight, it's not valid for timed refresh
        }
        let timedRefreshable = false;
        if (isSlotInView(slot) && !timedRefreshOverrideIsSet(slot)) {
            timedRefreshable = true;
            slot.timedRefreshOverride = false; // Reset the override to false. This will need to be reset by DFP if the slot is refreshable.
        }

        return timedRefreshable;
    };

    const timedRefreshOverrideIsSet = (slot) => slot.timedRefreshOverride || false;

    const removeSlot = (slot) => {
        // TEST: karmads.refresh.timed has a method called removeSlot
        // TEST: If a slot is removed, it no longer appears in the activeMap, and timedRefresh.enabled is set to false on the slot
        if (activeMap.hasOwnProperty(slot.slotContainer)) {
            delete activeMap[slot.slotContainer];
            slot.timedRefresh = slot.timedRefresh || {};
            slot.timedRefresh.enabled = false;
            slotCount--;
        }
    };

    const isValidSlot = (slot) => slot && activeMap.hasOwnProperty(slot.slotContainer) && !slot.timedRefreshOverride;

    const disableTimedRefreshOnSlot = (slotId, permanent) => {
        // TEST: karmads.refresh.timed has a method calld reEneableTimedRefreshOnSlot
        // TEST: karma has a method called disableTimedRefreshOnSlot
        // TEST: When disableTimedRefreshOnSlot is called with permanent set to true, the slot is removed via removeSlot (it no longer appears in the activeMap)
        // TEST: When disableTimedRefreshOnSlot is called with the "permanent" argument not passed, slot.timedRefreshOverride is set to true
        const slot = karmads.slots.getById(slotId);
        permanent = permanent || false;
        if (isValidSlot(slot)) {
            slot.timedRefreshOverride = true;
            if (permanent) {
                pauseSlotTimer(slot);
                removeSlot(slot);
            } else {
                check();
            }
            log(`disableTimedRefreshOnSlot(): ${permanent ? 'Permanently' : 'Temporarily'} disabling timed refresh on ${slotId}`);
        }
    };

    const reEnableTimedRefreshOnSlot = (slotId) => {
        // TEST: karmads.refresh.timed has a method calld reEneableTimedRefreshOnSlot
        // TEST: karma has a method called reEneableTimedRefreshOnSlot
        // TEST: When reEnableTimedRefreshOnSlot is called, slot.timedRefreshOverride is set to false
        const slot = karmads.slots.getById(slotId);
        if (slot && activeMap.hasOwnProperty(slotId) && slot.timedRefreshOverride) {
            slot.timedRefreshOverride = false;
            check();
            log(`Re-enabling timed refresh on ${slotId}`);
        }
    };

    const getDuration = (slot) => {
        const slotLevelRefreshIntervalOverrides = slot.refreshIntervalOverrides,
            mode = slot.timedRefresh.mode || 'refresh';
        let duration = 20000,
            bidTimeout = 0;

        // TEST: Timeout value on the slot object is used if present, otherwise the page-level value is used.
        if (slotLevelRefreshIntervalOverrides && slotLevelRefreshIntervalOverrides[mode]) {
            duration = slotLevelRefreshIntervalOverrides[mode];
        } else if (intervals[mode] && intervals[mode][slot.tierString]) {
            duration = intervals[mode][slot.tierString];
        }

        // TEST: If header bidding is enabled, subtract the bid timeout from the refresh interval, rounded up to the nearest second
        if (moduleExists('hb') && karmads.hb.isAnyPartnerEnabledOnSlot(slot)) {
            bidTimeout = Math.ceil(karmaConfig.hb.bidTimeouts.timed / 1000) * 1000;
            duration = duration - bidTimeout;
        }

        return [duration, bidTimeout];
    };

    const refHubShouldOverride = () => {
        // TEST: if url has param ref_hub=arbit*, override the timed refresh setting with whats set in refHubOverride in config.
        if (refHub && karmaVars.url['ref_hub'] && karmaVars.url['ref_hub'].indexOf('arbit') > -1) {
            return extend(timedSettings.intervals, refHub);
        } else {
            return false;
        }
    };

    const getDaypartedIntervals = () => {
        /* Config Format
        dayPartedIntervals: [
            {
                start: 1, // Hour in 24 hr format. Eastern time
                end: 6,
                intervals: {
                    refresh: {
                        tier1: 10000,
                        tier2: 10000,
                        tier3: 10000,
                        tier4: 10000
                    }
                }
            }
        ]
        */
        let intervals = {};

        const overrides = karma.config.refresh.timed.dayPartedIntervals || [],
            today = new Date(),
            todayOffset = today.getTimezoneOffset() / 60,
            // Get timezone offset for January to determine if current offset includes DST
            winterDay = new Date();

        winterDay.setMonth(0);

        const winterDayOffset = winterDay.getTimezoneOffset() / 60,
            // If DST, subract one hour from timezone offset
            currentEasternOffset = 5 - (winterDayOffset - todayOffset);

        today.setHours(today.getHours() + (todayOffset - currentEasternOffset));

        const currentEasternHour = today.getHours();

        overrides.forEach((override) => {
            if (currentEasternHour >= override.start && currentEasternHour < override.end) {
                intervals = override.intervals;
            }
        });

        if (Object.keys(intervals).length === 0) {
            return false;
        }
        return intervals;
    };

    return {
        check,
        disableTimedRefreshOnSlot,
        getActiveMap,
        init,
        isEnabled,
        pauseSlotTimer,
        reEnableTimedRefreshOnSlot,
        removeSlot,
        resetSlotTimer,
        startSlotTimer
    };
}());