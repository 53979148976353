karmads.requestQueue = (function() {
    'use strict';

    const activeSlots = [];

    let slotsQueue = [],
        requestSlotCount = 0,
        requestEvent,
        maxRequestSize;

    /* Add slots to the refresh queue. */
    /* If a refresh is in progress, the queue will be stored, and processed once current refresh finishes*/
    /* We know a refresh is finished due to the call to karmads.requestQueue.process that is made inside the slotRenderEnded event */
    /* See karmads.requestQueue.init */
    // ARGS: slotConfigs - if false is passed in, no new slots will be added to the karmads.requestQueue.slots array.
    // TEST: karma.vars.requestInFlight is a boolean
    // TEST: karmads.requestQueue has a method called process
    const process = (slotConfigs = []) => {

        /* Add new slots (slotConfigs) to the existing slot queue (slots)*/
        if (Array.isArray(slotConfigs) && slotConfigs.length > 0) {
            slotsQueue = slotsQueue.concat(slotConfigs);
        }

        // TEST: If a slot does not have a valid dom object, it is destroyed and removed from the request queue
        // TEST: If there are more than one instances of an individual slot, only one is kept
        slotsQueue = cleanSlots(slotsQueue);

        // TEST: If slots are only to be requested once scrolled into view, they are excluded from the ad request
        slotsQueue = karmads.scrollLoad.removeOffScreenSlots(slotsQueue);

        // TEST: If an otherwise valid slot is hidden on the page, it is excluded from the ad request
        slotsQueue = karmads.dom.getVisibleSlots(slotsQueue);

        // TEST: If no valid slots are requested, refresh is aborted
        if (slotsQueue.length === 0) {
            return;
        }

        if (karmaVars.requestInFlight) {     /* If a request is in progress, skip the GPT call. The slots are now waiting in queue for the current request to finish */
            log(`Refresh called, but Karma is still processing the previous ad request. Adding ${slotConfigs.length} slots to the refresh queue.`);
        } else {
            karmaVars.requestInFlight = true;
            /* Move slots to the local slotConfigs per the maxRequestSize, leaving extras in the slots array */
            slotConfigs = slotsQueue.splice(0, maxRequestSize);

            doLoop(slotConfigs, (slot) => {
                // TEST: Each slot that is in the process of being requested has a property of inFlight: true
                slot.inFlight = true;
                slot.bypassViewabilityRequirement = false;
            });

            const gptSlots = karmads.gpt.getGptObjects(slotConfigs);
            log(`Refresh running on ${slotConfigs.length} slots.`);

            karmads.refresh.targeting(slotConfigs);
            log({label: 'initDemandFetch'});
            moduleExists('hb') && karmads.hb.initDemandFetch(slotConfigs, gptSlots);
        }
        return slotConfigs;
    };

    const removeFromActiveSlots = (slotId) => {
        if (slotId) {
            activeSlots.splice(activeSlots.indexOf(slotId), 1);
        }
        return activeSlots;
    };

    const cleanSlots = (slotConfigs) => {
        // Remove slots from KARMA that have been removed from the DOM without a corresponding karma.destroySlot() call
        let cleanSlots = [];
        doLoop(slotConfigs, (slot) => {
            const dom = karmads.dom.get(slot);
            if (dom === null || dom.parentElement === null) {
                return karmads.slots.destroy(slot.slotContainer);
            }
            return cleanSlots.push(slot);
        });

        /* Remove any duplicate slots - GPT ignores duplicates which throws off the slot count */
        cleanSlots = cleanSlots.filter((slot, index, self) => index === self.findIndex((s) => s.slotContainer === slot.slotContainer));

        return cleanSlots;
    };

    const init = () => { /* Add the callback needed to process the refresh queue after each ad slot is filled*/
        requestEvent = createEvent('karmaRequest');
        maxRequestSize = karma.config.maxRequestSize || 30;

        // TEST: karmads.requestQueue has a method called init
        karmads.gpt.addCallback((event) => postRequest(event));
    };

    // Finish request called once header bidding is complete
    const finishRequest = (slotConfigs) => {
        // Re-clean the slots in case any were deleted outside of the KARMA API during header bidding
        let filteredSlotConfigs = cleanSlots(slotConfigs);

        // Validate slots in case any were deleted via KARMA API during header bidding request
        const validSlotContainers = karma.slots.catalog.map((slot) => slot.slotContainer);
        filteredSlotConfigs = filteredSlotConfigs.filter((slot) => validSlotContainers.indexOf(slot.slotContainer) > -1);

        // Create reguestEvent objects for logging
        const requestEventSlots = filteredSlotConfigs.map((slot) => {
            return {
                slotContainer: slot.slotContainer, refreshType: slot.refreshType
            };
        });

        // TEST: When a KARMA request is made, a 'karmaRequest' event is emitted on the window
        // TEST: The karmaRequest event detail property contains a JSON of the slots and refresh types
        requestEvent.detail = JSON.stringify(requestEventSlots);
        win.dispatchEvent(requestEvent);

        // Update queueLength to reflect missing slots
        requestSlotCount = filteredSlotConfigs.length;

        // If we need to do something right before the GAM request, do it here vvv
        // privacy settings are applied
        karmads.privacy.check();
        // bidz floors are set
        moduleExists('hb.bidz') && karmads.hb.bidz.setFloors(filteredSlotConfigs);
        // do prerequest targeting
        karmads.targeting.prerequest();
        // TEST: docking-related targeting is set
        moduleExists('docking') && karmads.docking.targeting();
        // TEST: the karmaTargetingReady event is fired after all page-level targeting has been set
        karmads.targeting.ready();
        // increment the refresh counter
        karmads.refresh.incrementRefreshCounter(filteredSlotConfigs);
        // if hard refresh, log refresh call
        if (filteredSlotConfigs[0].refreshType === 'hard') {
            log({report: 1, label: 'adRequestSent'});
        }
        // call GPT refresh
        karmads.gpt.refresh(karmads.gpt.getGptObjects(filteredSlotConfigs));

    };

    const postRequest = (event) => {
        const id = event.slot.getSlotId().getDomId(),
            slot = karmads.slots.getById(id);

        if (slot) {
            // TEST: each requested slot inside karma.slots.catalog has a property called 'requested'
            slot.requested = true;
            // TEST: each requested slot inside karma.slots.catalog has a property called 'inFlight'
            slot.inFlight = false;
            karmads.slots.setResponseData(slot, event);
            karmads.viewability.handleSpecialViewabilityRules(slot);
        }

        requestSlotCount--;

        if (requestSlotCount === 0) {
            karmaVars.requestInFlight = false;
            moduleExists('docking.rail') && karmads.docking.rail.reassessDisabledRails();
            if (slotsQueue.length > 0)  {
                process(false); // Process any slots that were queued up for refresh while the previous refresh was finishing
            } else {
                if (karmaVars.firstAdReturned) {
                    karmads.refresh.timed.check();
                }
            }
        }
    };

    return {
        init,
        process,
        finishRequest,
        removeFromActiveSlots
    };
}());