/* eslint-disable object-shorthand */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable func-style */
karmads.docking = karmads.docking || {};
karmads.docking.rail = (function() {
    'use strict';

    const matchPattern = /karma-sticky-(top|stuck|bottom)/,
        classPrefix = 'karma-sticky-',
        disablingMsg = 'Disabling rail docking: ',
        msg = 'Rail Docking: ',
        leaderboardEventSaveState = {};

    let enabled,
        rails = [],
        availableHeight = win.innerHeight,
        settings;

    const isGrid = () => karmads.dom.lookup(`.${classPrefix}grid`, 1, 1).length > 0;

    const getRails = () => rails;

    function createRail(settings, railNum) {
        // creates a new rail object, uses railNum to differentiate each rail on page
        try {
            // variables
            this.railNum = railNum || 0;
            this.pos;
            this.identifier = `railDockSection-${this.railNum}`;
            this.classIdentifier = `.${this.identifier}`;

            const missingElArr = [];

            // settings
            this.enabled = isEnabled();
            this.dockingElementSelector = settings.dockingElementSelector || false;
            if (!isGrid()) {
                this.contentContainerSelector = settings.contentContainerSelector || false;
                this.mainColumnSelector = settings.mainColumnSelector || false;
            }
            this.railSelector = settings.railSelector || false;
            this.customStyles = settings.customStyles || false;
            this.additionalLogic = settings.additionalLogic || [];
            this.offset = settings.offset || 0;
            this.screenSizeThreshold = settings.screenSizeThreshold || 625;
            this.dockIfTooTall = settings.dockIfTooTall || false;

            // elements
            this.body = doc.body;
            if (!isGrid()) {
                this.contentContainer = karmads.dom.lookup(this.contentContainerSelector, 1, 1)[this.railNum];
                this.contentContainer.classList.add(this.identifier);
                this.contentContainerSelector = this.classIdentifier + this.contentContainerSelector;
                this.mainColumnSelector = `${this.classIdentifier} ${this.mainColumnSelector}`;
                this.dockingElementSelector = `${this.classIdentifier} ${this.railSelector} ${this.dockingElementSelector}`;
                this.railSelector = `${this.classIdentifier} ${this.railSelector}`;
                this.rail = karmads.dom.lookup(this.railSelector, 0, 1);
                this.mainColumn = karmads.dom.lookup(this.mainColumnSelector, 0, 1);
                this.dockingElement = karmads.dom.lookup(this.dockingElementSelector, 0, 1);
                this.contentContainer = karmads.dom.lookup(this.contentContainerSelector, 0, 1);
            } else {
                this.rail = karmads.dom.lookup(this.railSelector, 1, 1)[this.railNum];
                this.rail.classList.add(this.identifier);
                this.dockingElementSelector = `${this.classIdentifier} ${this.dockingElementSelector}`;
                this.dockingElement = karmads.dom.lookup(this.dockingElementSelector, 0, 1);
            }

            // functions - creates a set of functions to control the rail docking behavior
            this.getOffset = function(el) {
                let offset = 0,
                    failsafe = 100;

                while (el !== this.body && el !== null && failsafe !== 0) {
                    offset += el.offsetTop;
                    el = el.offsetParent;
                    failsafe--;
                }

                if (failsafe === 0 || el === null) {
                    log(`${disablingMsg} Failed to find offset parent for ${this.dockingElementSelector}[${this.railNum}]`);
                    this.releaseAtTop();
                    this.disable();
                }

                return offset;
            };

            this.disable = function() {
                this.enabled = false;
            };

            this.releaseAtTop = function() {
                this.removeInlineStyle();
                this.pos = this.update('top');
            };

            this.releaseAtBottom = function() {
                this.pos = this.update('bottom');
                const topDistance = isGrid() ? (this.rail.offsetHeight - this.dockingElement.offsetHeight) : (this.mainColumn.offsetHeight - this.dockingElement.offsetHeight);
                this.dockingElement.style.top = `${topDistance}px`;
                if (rails.indexOf(this) > 0) {
                    this.dockingElement.style.position = 'relative';
                }
            };

            this.removeInlineStyle = function() {
                if (this.pos === 'bottom') {
                    this.dockingElement.style.top = '';
                    this.dockingElement.style.position = '';
                }
            };

            this.park = function() {
                this.removeInlineStyle();
                this.pos = this.update('stuck');
            };

            this.update = function(pos) {
                if (!enabled) {
                    pos = 'top';
                }
                if (this.dockingElement.className.indexOf(classPrefix) === -1) {
                    this.dockingElement.className += ` ${classPrefix}${pos}`;
                } else {
                    this.dockingElement.className = this.dockingElement.className.replace(matchPattern, classPrefix + pos);
                }
                return pos;
            };

            this.railIsTooTall = function() {
                if (!this.rail || !this.mainColumn || !this.dockingElement) {
                    return false;
                }
                const contentHeight = isGrid() ? this.rail.offsetHeight : this.mainColumn.offsetHeight;
                return this.dockingElement.offsetHeight >= contentHeight;
            };

            this.check = function() {

                const smallScreen = isGrid() ?
                        availableHeight < this.screenSizeThreshold || (!this.dockIfTooTall && this.dockingElement.offsetHeight + this.offset > availableHeight) :
                        availableHeight < this.screenSizeThreshold || this.mainColumn.offsetHeight < this.rail.offsetHeight || (!this.dockIfTooTall && this.dockingElement.offsetHeight + this.offset > availableHeight),
                    railInViewNScrolledDown = this.pos !== 'stuck' && this.pos !== 'bottom' && win.pageYOffset > this.getOffset(this.dockingPlaceholder) - this.offset,
                    notTopOrBottom = this.pos !== 'top' && this.pos !== 'bottom',
                    topOfRailOffset = win.pageYOffset <= this.getOffset(this.dockingPlaceholder) - this.offset,
                    footerInView = isGrid() ?
                        win.pageYOffset + this.offset + this.dockingElement.offsetHeight > this.getOffset(this.rail) + this.rail.offsetHeight :
                        win.pageYOffset + this.offset + this.dockingElement.offsetHeight > this.getOffset(this.mainColumn) + this.mainColumn.offsetHeight,
                    notTopOrStuckDock = isGrid() ?
                        this.pos !== 'top' && this.pos !== 'stuck' && win.pageYOffset + this.offset + this.dockingElement.offsetHeight <= this.getOffset(this.rail) + this.rail.offsetHeight :
                        this.pos !== 'top' && this.pos !== 'stuck' && win.pageYOffset + this.offset + this.dockingElement.offsetHeight <= this.getOffset(this.mainColumn) + this.mainColumn.offsetHeight;

                if ((isGrid() && this.dockingElement.offsetHeight >= this.rail.offsetHeight) || this.railIsTooTall()) {
                    this.releaseAtTop();
                    this.disable();
                } else {
                    if (smallScreen) {
                        // TEST: on small screens, the rail is not docked
                        this.releaseAtTop();
                    } else if (railInViewNScrolledDown) {
                        // TEST: if the right rail is in view and we're scrolled down, it's docked
                        this.park();
                    } else if (notTopOrBottom) {
                        // TEST: if we're at the top of the page, it's not docked
                        if (topOfRailOffset) {
                            this.releaseAtTop();
                            // TEST: if the footer is in view, it's not docked
                        } else if (footerInView) {
                            this.releaseAtBottom();
                        }
                    } else if (notTopOrStuckDock) {
                        // not top or stuck, should we dock
                        this.park();
                    }
                }
            };

            // TEST: if dockingElementSelector, mainColumnSelector, or railSelector aren't specified, rail docking is disabled
            if (!enabled || !this.dockingElementSelector || (!isGrid() && !this.mainColumnSelector) || !this.railSelector) {
                if (enabled) {
                    log(`Rail #${this.railNum + 1} Disabled: Missing selectors: dockingElementSelector: ${this.dockingElementSelector}, ${isGrid() ? `mainColumnSelector: ${this.mainColumnSelector}` : ''}, railSelector: ${this.railSelector}`, {
                        level: 'warn',
                        doc: 'karma-docking'
                    });
                    this.disable();
                }
            }

            // TEST: if additionalLogic is present and returns false, rail docking is disabled
            if (!karmads.docking.processAdditionalLogic(this.additionalLogic)) {
                if (enabled) {
                    log(`${disablingMsg} Disabled via additionalLogic`, {doc: 'karma-docking'});
                    this.disable();
                }
            }

            if (!isGrid() && !this.rail) {
                missingElArr.push(`rail: "${this.railSelector}"`);
            }

            // TEST: if the contentContainer isn't in the DOM, the rail is disabled
            if (enabled && !isGrid() && !this.contentContainer) {
                log(`${disablingMsg} Cannot find DOM Element contentContainer for rail #${this.railNum + 1}, selector: ${this.contentContainerSelector}`, {
                    level: 'warn',
                    doc: 'karma-docking'
                });
                this.disable();
            }

            // TEST: GRID: if the rail isn't in the DOM, the rail is disabled
            if (enabled && isGrid() && !this.rail) {
                log(`${disablingMsg} Cannot find the rail DOM Element for rail #${this.railNum + 1}, selector: ${this.railSelector}`, {
                    level: 'warn',
                    doc: 'karma-docking'
                });
                this.disable();
            }

            // TEST: if dockingElement, mainColumn, body, or rail DOM elements aren't present in the DOM, rail docking is disabled
            if (!this.body) {
                this.disable();
            }

            if (!this.dockingElement) {
                missingElArr.push(`dockingElement: "${this.dockingElementSelector}"`);
            }

            if (!isGrid() && !this.mainColumn) {
                missingElArr.push(`mainColumn: "${this.mainColumnSelector}`);
            }

            if (missingElArr.length > 0) {
                log(`Rail #${this.railNum + 1} Disabled: missing elements ${missingElArr.join(', ')}`, {
                    force: 1,
                    level: 'warn',
                    doc: 'karma-docking'
                });
                this.disable();
            }

            if (karmaConfig.docking.rail) {
                karmaConfig.docking.rail.enabled = enabled;
            }

            enabled = isEnabled();

            /* don't do anything if we're not enabled */
            if (!enabled || !this.enabled) {
                return;
            }

            this.dockingParent = this.dockingElement.parentElement;

            // TEST: Each docked rail has a dockingPlaceholder element as a previousSibling
            const dockingPlaceholder = karmads.dom.create(
                'div',
                this.dockingParent,
                {className: 'karma-docking-rail-placeholder', styles: 'margin:0;height:0;'},
                this.dockingElement
            );

            this.dockingPlaceholder = dockingPlaceholder;

            this.dockingElementSelector += `.${classPrefix}`;
            this.leaderboardHeight = karmads.docking.leaderboard.getBannerSelectorHeight();

            // TEST: railSelector should have position: relative
            // TEST: dockingElementSelector+'stuck' should have position: fixed and top defined in pixels
            // TEST: dockingElementSelector+'bottom' should have position: absolute
            this.css = `
                ${this.railSelector}, ${this.dockingElementSelector}top{
                    position: relative
                }
                ${this.dockingElementSelector}stuck{
                    position: fixed;
                    top: ${this.offset}px
                }
                ${this.dockingElementSelector}stuck.dockedLeaderboard{
                    top: ${this.offset + this.leaderboardHeight}px
                }
                ${this.dockingElementSelector}stuck.dockedLeaderboard.undockingLeaderboard{
                    top: ${this.offset}px;
                    transition: top 1s;
                    z-index: 500
                }
                ${this.dockingElementSelector}bottom{
                    position: absolute
                }
                ${this.customStyles}`;
            // TEST: a style block should be appended to the document with rail docking styles in it
            karmads.docking.appendStyleSheetCss(this.css);

            if (this.enabled) {
                this.check();
            }
        } catch (err) {
            log(msg + err.toString(), {
                level: 'error',
                force: 1
            });
        }

    } // end createRail()

    const checkAll = () => {
        // TEST: on document touchstart, touchmove, touchend, and scroll, the check function runs
        doLoop(rails, function(rail) {
            if (rail.enabled) {
                rail.check();
            }
        });
    };

    const isEnabled = () => {
        // sorta obvious what happens here - check that it's enabled in the config
        karmaConfig.docking = karmaConfig.docking || {};
        settings = karmaConfig.docking.rail || {};
        // TEST: if karmaConfig.docking or karmaConfig.docking.rail is not set, KARMA still initializes
        if (typeof enabled !== 'boolean') {
            enabled = convertStringToBoolean(settings.enabled);
        }
        return enabled;
    };

    const init = () => {

        if (!isEnabled()) {
            return;
        }

        // init loops through all possible contentContainers on page to see if they qualify to have a rail docked
        settings = karmaConfig.docking.rail || {};

        /* Update available height on orientation change */
        win.addEventListener('orientationchange', function() {
            availableHeight = win.innerHeight;
        });

        doLoop(['touchstart', 'touchmove', 'touchend', 'scroll'], function(prop) {
            doc.addEventListener(prop, karmads.docking.rail.checkAll, {passive: 1});
        });

        let els = isGrid() ?
                karmads.dom.lookup(settings.railSelector, 1, 1) || [] :
                karmads.dom.lookup(settings.contentContainerSelector, 1, 1) || [],
            railNum = 0,
            newRail;

        if (!isGrid() && !settings.multi) {
            els = els[0] ? els[0] : [];
        }

        if (els.length === 0) {
            log(`${disablingMsg}Missing DOM Element contentContainer, selector: ${settings.contentContainerSelector}`, {
                level: 'error',
                doc: 'karma-docking'
            });
        } else {
            doLoop(els, function() {
                newRail = new createRail(settings, railNum);
                if (newRail.enabled || newRail.railIsTooTall()) {
                    rails.push(newRail);
                }
                railNum++;
            });
        }

        if (rails.length === 0) {
            enabled = false;
        }
        karmaConfig.docking.rail.enabled = enabled;
    };

    const rebuildRails = () => {
        enabled = undefined;
        karmaConfig.docking.rail.enabled = true;
        if (isEnabled()) {
            rails = [];
            const placeholders = karmads.dom.lookup('.karma-docking-rail-placeholder', 1, 1);
            doLoop(placeholders, function(placeholder) {
                placeholder.parentNode.removeChild(placeholder);
            });
            init();
            if (rails.length > 0) {
                leaderboardEventHandler(leaderboardEventSaveState.event, leaderboardEventSaveState.useTransition);
            }
            checkAll();
        }
    };

    const leaderboardEventHandler = (event, useTransition) => {
        leaderboardEventSaveState.event = event;
        leaderboardEventSaveState.useTransition = useTransition;
        if (isEnabled()) {
            doLoop(rails, function(rail) {
                switch (event) {
                case 'leaderboardDocked':
                    rail.offset += rail.leaderboardHeight;
                    rail.dockingElement.classList.add('dockedLeaderboard');
                    break;
                case 'leaderboardUndocked':
                    rail.offset -= rail.leaderboardHeight;
                    if (rail.useTransition) {
                        rail.dockingElement.classList.add('undockingLeaderboard');
                        setTimeout(function() {
                            rail.dockingElement.classList.remove('undockingLeaderboard');
                            rail.dockingElement.classList.remove('dockedLeaderboard');
                        }, 1000);
                    } else {
                        rail.dockingElement.classList.remove('dockedLeaderboard');
                    }
                    break;
                }
            });
        }
        checkAll();
    };

    const disableAllRails = () => {
        // TEST: when disableAllRails() is called, rail docking is disabled.
        doLoop(rails, function(rail) {
            if (rail.enabled) {
                rail.releaseAtTop();
                rail.disable();
            }
            enabled = false;
            karmaConfig.docking.rail.enabled = false;
        });
    };

    const reassessDisabledRails = () => {
        const rails = getRails(),
            disabledRails = rails.filter(function(rail) {
                return rail.enabled === false;
            });

        if (disabledRails.length) {
            rebuildRails();
        }
    };

    return {
        init,
        checkAll,
        disableAllRails,
        isGrid,
        getRails,
        isEnabled,
        leaderboardEventHandler,
        reassessDisabledRails,
        rebuildRails
    };
}());