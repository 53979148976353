/* eslint-disable no-unused-vars */
// TEST: window.karma exists and is an object
win.karma = win.karma || {};
// TEST: window.karma.vars is a globally-scoped object
win.karma.vars = win.karma.vars || {};
// TEST: window.karma.config is a globally-scoped object
win.karma.config = win.karma.config || {};

// eslint-disable-next-line prefer-const
let karma = {
        vars: {
        // TEST: karma.vars.version matches the comment at the top of the service file
            version: '3.22.5',
            // TEST: karma.vars has an object property called els, which contains at least a property named 'head'
            els: {
                head: doc.getElementsByTagName('head')[0]
            },
            firstAdReturned: false,
            // TEST: karma.vars has an object property called videoTargeting
            videoTargeting: {}
        },
        // TEST: window.karma.slots is a globally-scoped object
        // TEST: window.karma.slots.catalog is a globally-scoped array
        // TEST: window.karma.slots.lazy is a globally-scoped array
        // TEST: window.karma.slots.scrollLoad is a globally-scoped array
        // TEST: window.karma.slots.refresh is a globally-scoped object
        // TEST: karma.slots has an array property called 'hbProps'
        // TEST: window.karma.slots.hbProps is an array of header-bidding related slot properties
        slots: {
            catalog: [],
            lazy: [],
            scrollLoad: [],
            refresh: [],
            hbProps: ['a9', 'a9Sizes', 'prebid', 'prebidSizes', 'responsiveA9Sizes', 'responsivePrebidSizes']
        },
        config: {
        // TEST: karma.config.networkCode is a string with a default value of 3865
            networkCode: '3865',
            // TEST: karma.config.version is a string that matches the version in the loaded config file
            version: '3.31',
            // TEST: karma.config.scriptPath is a string
            scriptPath: '',
            // TEST: karma.config.environment is a string
            environment: '',
            // TEST: karma.config.site is a string
            site: '',
            // TEST: karma.config.embeddedSite exists and is a string or a boolean
            embeddedSite: '',
            // TEST: karma.config.targeting is an object
            targeting: {},
            // TEST: karma.config has an object property called storedTargeting
            storedTargeting: {},
            // TEST: karma.config has a property 'static'
            static: false
        }
    },
    isMobilePage,
    apiVersion,
    // scope utility functions so that they can be called directly
    log;

// local variable for karma.vars and karma.config to make minification happy
const karmaVars = karma.vars,
    karmaConfig = karma.config;

// TEST: window.googletag exists and is an object
win.googletag = win.googletag || {};
// TEST: window.googletag.cmd has a method called push
googletag.cmd = googletag.cmd || [];

// TEST: window.ga exists and is a function
win['ga'] = win['ga'] || function() {
    (win['ga'].q = win['ga'].q || []).push(arguments);
};

// TEST: karma.config.apiVersion is a number with a default value of 2 (it can be overwritten with an on-page value)
if (win.karma.config.apiVersion) {
    apiVersion = karma.config.apiVersion = win.karma.config.apiVersion;
} else {
    apiVersion = karma.config.apiVersion = 2;
    window.adServiceQ = window.adServiceQ || [];
}

// TEST: karma.vars.initialized is a boolean
karma.vars.initialized = false;