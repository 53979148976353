karmads.translate = (function() {
    'use strict';

    // TEST: if karma.config.apiVersion is 2, the translate module is enabled to convert the old API to the new
    const isEnabled = () => apiVersion === 2;

    const config = () => {
        win.adService = win.adService || {};
        win.adService.mobileAds = karma.config.isMobile;

        win.adService.unitValues = {};
        win.adService.unitValues.adDomain = karma.config.site;
        win.adService.unitValues.channel = karma.config.targeting.channel;
        win.adService.unitValues.parent = karma.config.targeting.parent;
        win.adService.unitValues.child = karma.config.targeting.child;

        win.adService.pageTargetingValues = karma.config.targeting;

        win.adService.siteSettings = karma.config['static'];
        win.adService.docking = karma.config.docking;
        win.adService.refresh = karma.config.refresh;

        win.adService.callback = karma.config.callback;

        win.adService.apiVersion = apiVersion;

    };

    const resetAdDomain = () => {
        if (isEnabled()) {
            karma.config.site = '';
        }
    };

    const onPageConfig = () => {
        if (isEnabled()) {
            win.adService = win.adService || {};

            const uvs = win.adService.unitValues || {},
                pvs = win.adService.pageTargetingValues || {};

            karma.config.onPageSettings.config = (win.adService !== {}) ? win.adService : {};
            karma.config.isMobile = win.adService.mobileAds || false;

            doLoop(uvs, (key) => {
                if (key === 'adDomain') {
                    karma.config.site = uvs[key];
                } else {
                    karma.config.targeting[key] = uvs[key];
                }
            });

            doLoop(pvs, (key) => {
                karma.config.targeting[key] = pvs[key];
            });

            karma.config.hb = karma.config.hb || {};
            karma.config.hb.a9 = karma.config.hb.a9 || {};
            karma.config.hb.a9.enabled = win.adService.a9Enabled || false;
            karma.config.refreshSlotTypes = win.adService.refreshSlotTypes || [];

            if (win.adService.hasOwnProperty('callback')) {
                if (karma.config.hasOwnProperty('callback')) {
                    if (typeof karma.config.callback === 'object') {
                        karma.config.callback.push(win.adService.callback);
                    }
                } else {
                    karma.config.callback = win.adService.callback;
                }
            }
        }
    };

    const vars = () => {
        win.adService.adUnit = {
            top: karmads.targeting.adunit.buildTop(),
            sub: karmads.targeting.adunit.buildSub()
        };

        win.adService.mdpPageCount = karmaVars.pageCount;
    };

    const targetingReady = () => {
        if (isEnabled()) {
            win.adService.pageTargetingReady = karmaVars.targetingReady;
        }
    };

    const methodLog = (oldName, newName, fn, args) => {
        log(`You called ${oldName}, which has been deprecated. ${(newName && newName !== '') ? `You should call karma.${newName} instead. ` : ''}`, {force: 1, level: 'warn', doc: 'api-method-changes-in-karma-3'});
        if (typeof fn === 'function') {
            fn.apply(this, args);
        }
    };

    const methods = () => {

        const functions = [
            // TEST: a function is available on adService.renderAds called 'createLazyLoadSlot'
            // TEST: a function named fillLazyLoadSlots exists on adService.renderAds
            // TEST: a function is available on adService.renderAds called 'updatePageTargeting'
            // TEST: a function is available on adService.renderAds called 'updateSlotTargeting'
            // TEST: An addToCallbackList function is available on adService.renderAds
            {func: 'createLazyLoadSlot', newName: 'createSlot', fn: karmads.lazyLoad.create},
            {func: 'fillLazyLoadSlots', newName: 'fillSlots', fn: karmads.lazyLoad.fill},
            {func: 'updatePageTargeting', newName: 'updateTargeting', fn: karmads.targeting.updateTargeting},
            {func: 'updateSlotTargeting', newName: 'updateTargeting', fn: (slot, targetingObj) => karmads.targeting.updateTargeting(targetingObj, slot)},
            {func: 'buildDefaultRefreshSlots', newName: '', fn: karmads.refresh.buildDefaultSlots},
            {func: 'addToRefreshSlots', newName: 'createSlot(slotName, container, autofill, callback, {refreshable: true})', fn: karmads.refresh.addSlot},
            {func: 'purgeRefreshSlots', newName: 'createSlot(slotName, container, autofill, callback, {purgeRefreshSlots: true})', fn: karmads.refresh.addSlot},
            {func: 'removeFromRefreshSlots', newName: '', fn: karmads.refresh.removeSlot},
            {func: 'destroySlot', newName: 'destroySlot', fn: karmads.slots.destroy},
            {func: 'addToCallbackList', newName: 'config.callback.push', fn: karmads.callbacks.add},
            {func: 'getSlotCount', newName: 'getSlotCount', fn: karmads.slots.getSlotCount},
            {func: 'isInView', newName: 'isInView', fn: karmads.viewability.isInView},
            {func: 'disableTimedRefreshOnSlot', newName: 'disableTimedRefreshOnSlot', fn: karmads.refresh.timed.disableTimedRefreshOnSlot},
            {func: 'forceReleaseBanner', newName: 'releaseDocking', fn: moduleExists('docking.leaderboard') ? karmads.docking.leaderboard.forceReleaseBanner : noop}
        ];
        win.adService.renderAds = {};

        doLoop(functions, (f) => {
            win.adService.renderAds[f.func] = function() {
                methodLog(`adService.renderAds.${f.func}`, f.newName, f.fn, arguments);
            };
        });

        win.adService.debug = function() {
            methodLog('adService.debug', 'debug', karmads.debug.publicDebug, arguments);
        };
        win.adService.showLog = function() {
            methodLog('adService.showLog', 'showLog', karmads.debug.showLog, arguments);
        };
    };

    const refresh = () => {
        win.refreshAdFrame = () => methodLog('refreshAdFrame', 'refresh', karmads.refresh.startRefresh, arguments);
    };

    const slots = () => {
        if (isEnabled()) {
            win.adService.adSlotsById = {};
            karma.slots.catalog.forEach((slot) => {
                win.adService.adSlotsById[slot.slotContainer] = slot;
            });
        }
    };

    const queue = () => {
        win.adServiceQ = win.adServiceQ || [];
        win.karma.cmd = (win.karma.cmd !== undefined && typeof win.karma.cmd === 'object') ? win.adServiceQ.concat(win.karma.cmd) : win.adServiceQ.slice();
    };

    const init = () => {
        if (isEnabled()) {
            config();
            vars();
            methods();
            refresh();
            slots();
        }
    };

    const karmaReady = () => {
        if (isEnabled()) {
            win.adService.queueExecuted = karmaVars.firstAdReturned;
        }
        win.adServiceQ = win.karma.cmd;
    };

    return {
        convertConfig: onPageConfig,
        init,
        isEnabled,
        queue,
        ready: karmaReady,
        resetAdDomain,
        slots,
        targetingReady
    };

}());
