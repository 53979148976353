karmads.urlVars = (function() {
    'use strict';

    const queryString = decodeURIComponent(win.location.search);

    // TEST: karma.vars.url is an object that contains all key/value pairs passed in the URL query string
    const init = () => {
        // TEST: karma.vars has an object property called 'url'
        karmaVars.url = convertQueryStringToObject(queryString);
        return karmaVars.url;
    };

    // TEST: karmads.urlvars has a method called 'get'
    // TEST: karmads.urlvars.get returns the value of a URL param, given a key
    const get = (key) => karmaVars.url[key.toLowerCase()] || null;

    // TEST: karmads.urlvars has a method called 'isTrue'
    // TEST: karmads.urlvars.isTrue tests a URL param to see if it's true, given a key
    const isTrue = (key) => get(key.toLowerCase()) === 'true';

    const getTestValues = (type) => {
        // gets URL params with the key 'adTestKeyValues' or 'adTestServiceKeyValues' and splits them on dashes (for multiple key/value pairs) and commas (to designate key,value)

        type = type ? type.charAt(0).toUpperCase() + type.substr(1).toLowerCase() : '';

        const testValues = {};
        queryString.replace(new RegExp(`[?&]adtest${type}keyvalues=([^&]*)`, 'i'), (m, valueArray) => {
            doLoop(valueArray.split('-'), (kvPair) => {
                const kvBits = kvPair.split(',');
                testValues[kvBits[0]] = kvBits[1];
            });
        });

        return testValues;
    };

    const setTestValues = () => {
        // TEST: On any URL that has adTestServiceKeyValues=key,value-key2,value2 in the query string, karmaConfig['key'] is set to 'value', and karmaConfig['key2'] is set to 'value2' (overriding config-set values)
        // TEST: On any URL that has adTestServiceKeyValues=key.nestedkey,value in the query string, karmaConfig['key']['nestedkey'] is set to 'value' (overriding config-set values)
        const testServiceValues = getTestValues('service');
        let params, parentObj, l;
        doLoop(testServiceValues, (param) => {
            params = param.split('.');
            parentObj = karma.config;
            l = params.length;
            doLoop(params, (np, i) => {
                if (i === l - 1) {
                    parentObj[np] = coerceType(testServiceValues[param]);
                } else {
                    parentObj[np] = parentObj.hasOwnProperty(np) ? parentObj[np] : {};
                    parentObj = parentObj[np];
                }
            });
        });
    };

    const urlContainsEmail = () => {
        // detects whether or not the URL contains an email
        const decodedHref = decodeURIComponent(win.location.href);
        return decodedHref.match(/[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?/ig);
    };

    init();

    return {
        setTestValues,
        getTestValues,
        get,
        init,
        isTrue,
        urlContainsEmail
    };

}());