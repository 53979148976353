karmads.partner = karmads.partner || {};
karmads.partner.adLightning = (function() {

    const isEnabled = () => !!karmaConfig.adLightningEnabled;

    const load = () => {
        if (isEnabled()) {
            // TEST: if adLightningEnabled is set to true, the adLightning wrapper is loaded
            fetchResource('//tagan.adlightning.com/meredith/op.js', () => log('adLightning loaded.'), () => {
                // TEST: if adLightningEnabled is enabled and the wrapper fails to load, we disable adLightning
                log('Failed to fetch adLightning.', {level: 'error', force: 1});
                karmaConfig.adLightningEnabled = false;
            });
        }
    };

    return {
        load,
        isEnabled
    };

}());