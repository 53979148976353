karmads.hb = karmads.hb || {};
karmads.hb.a9 = (function() {
    'use strict';

    // TEST: karma.vars has an object property called 'a9'
    karmaVars.a9 = {};

    let a9TargetingKeys = [],
        initialPrerollRequestMade = false;

    // TEST: karmads.hb.a9 has a method called isEnabled
    const isEnabled = () => karmads.hb.isEnabled('a9');

    const shouldRequestPreroll = (isSoftPrerollRequest) => moduleExists('hb.video') ? karmads.hb.video.isEnabled() && (isSoftPrerollRequest || !initialPrerollRequestMade) : false;

    const init = () => {
        if (isEnabled() && win.apstag && win.apstag.init) {
            apstag.init({
                pubID: karmaConfig.hb.a9.pubId,
                adServer: 'googletag',
                videoAdServer: 'DFP',
                deals: true,
                params: {
                    'aps_privacy': karmads.privacy.getCcpaConsentString(),
                    'si_pagegroup': karmaConfig.targeting.channel
                },
                schain: {
                    complete: 1,
                    ver: '1.0',
                    nodes: []
                }
            });
        }
    };

    // load apstag JavaScript and initalize library with pubID
    const load = () => {
        if (!isEnabled()) {
            return;
        }

        // TEST: karmads.hb.a9 has a method called load
        // TEST: When a9 is enabled, window.apstag exists and is an object
        // TEST: When a9 is not enabled, apstag.js is not added to the page
        // TEST: if A9 is enabled in the config and we have A9-compatible slots, a9 is loaded
        // TEST: karmaConfig.hb.a9.pubId should exist
        !function(a9, a) {
            if (a[a9]) {
                return;
            }
            const q = (c, r) => {
                a[a9]._Q.push([c, r]);
            };
            a[a9] = {
                // eslint-disable-next-line object-shorthand
                init: function() {
                    q('i', arguments);
                },
                // eslint-disable-next-line object-shorthand
                fetchBids: function() {
                    q('f', arguments);
                },
                _Q: []
            };
        }('apstag', win);

        const a9Path = 'https://c.amazon-adsystem.com/aax2/apstag.js';
        log({label: 'a9JsRequested'});
        fetchResource(a9Path, () => {
            log({
                report: true,
                label: 'a9JsLoaded',
                referencePoint: 'a9JsRequested',
                reportValueOverride: karmads.log.getTiming(a9Path)
            });
        });

        init();
    };

    // returns an array of slots like:
    // [{slotID: div-id-123, sizes: [[300, 250], [300,600]]}, { ... }]
    const buildSlots = (slots) => {
        // TEST: The properties of karma.vars.a9.slots are objects
        const isPrerollOnly = slots.length === 1 && slots[0].isPreroll,
            result = [];

        if (!isPrerollOnly) {
            // turn object keyed by slot to an array of slots
            doLoop(slots, (slot) => {
                slot.hb = slot.hb || {};
                // if responsive sizes are configured, and the slot is responsive, use those
                slot.hb.a9Sizes = karmads.slots.getSizes(slot, 'A9') || [];
                // map slots to format to include in fetchBids request and return
                result.push({
                    slotID: slot.slotContainer,
                    sizes: slot.hb.a9Sizes,
                    slotName: slot.hb.gpid
                });
            });
        }

        if (shouldRequestPreroll(isPrerollOnly)) {
            initialPrerollRequestMade = true;
            result.push({
                slotID: 'preroll',
                mediaType: 'video'
            });
        }
        log(`Slots built for a9: ${result.map((slot) => slot.slotID)}`);
        return result;
    };

    const fetchBids = (slots, refreshType, callback) => {
        // TEST: karmads.hb.a9 has a method called fetchBids

        const timeoutLengths = karmads.hb.getTimeoutLengths(refreshType);
        let timeoutExpired = false,
            bidsLoaded = false;

        // associate the bid object returned from a9 with the corresponding slot object
        const storeTargeting = (bids) => {
            // TEST: If in debug mode, amazon targeting (amznbid) is added to an amazon slot
            // TEST: If in debug mode, amazon targeting (amzniid) is added to an amazon slot
            // TEST: If in debug mode, amazon targeting (slotId) is added to an amazon slot
            // TEST: If in debug mode, amazon targeting (size) is added to an amazon slot
            // TEST: If in debug mode, slotID matches the corresponding slotContainer
            let slot;
            doLoop(bids, (bid) => {
                slot = karmads.slots.getById(bid.slotID);
                if (slot) {
                    slot.hb.a9Targeting = bid['targeting'];
                    slot.hb.a9Targeting['size'] = bid['size'];
                    slot.hb.a9Targeting['slotID'] = bid['slotID'];
                }
            });
        };

        // set the a9 targeting keys on each slot
        const setTargeting = (bid, slot) => {
            switch (typeof slot) {
            case 'undefined':
                slot = karmads.slots.getById(bid.slotID);
                break;
            case 'string':
                slot = karmads.slots.getById(slot);
                break;
            }
            // TEST: apstag has a method targetingKeys that returns an array
            tryCatch(() => {
                a9TargetingKeys = apstag.targetingKeys() || [];
                if (!Array.isArray(a9TargetingKeys)) {
                    a9TargetingKeys = [];
                    log(`Unexpected format for a9TargetingKeys: ${typeof apstag.targetingKeys()}`);
                }
            });
            // TEST: If in debug mode, amazon targeting (amznbid) is added to a GPT slot
            // TEST: If in debug mode, amazon targeting (amzniid) is added to a GPT slot
            if (slot) {
                doLoop(bid.helpers.targetingKeys, (key) => karmads.targeting.setSlot(key, bid.targeting[key], slot));
            }
        };

        const validateBids = (bids) => {
            if (!Array.isArray(bids)) {
                log('Unexpected data structure from a9. Ignoring response.', {force: 1, level: 'warn'});
                return [];
            }
            return bids;
        };

        const processResponse = (bids, callback, storeTargeting, setTargeting, validateBids) => {
            bids = validateBids(bids);
            if (!timeoutExpired) {
                doLoop(bids, (bid) => {
                    if (bid.hasOwnProperty('slotID')) {
                        if (bid.slotID === 'preroll') {
                            moduleExists('hb.video') && karmads.hb.video.setTargeting(bid.targeting);
                        } else {
                            setTargeting(bid, karmads.slots.getById(bid.slotID));
                        }
                    }
                });
                bidsLoaded = true;
                storeTargeting(bids);
                if (typeof callback === 'function') {
                    callback();
                }
            }
        };

        const batchContainsRefreshedSlots = (karmaSlots = []) => karmaSlots.filter(slot => slot.refreshType !== 'hard').length > 0 ? 1 : 0;

        // filter to only a9.enabled slots
        slots = karmads.hb.filterSlots(slots, 'a9') || [];

        // clear the previous targeting from GAM to prevent sending more than once
        doLoop(slots, (slot) => {
            if (!slot.isPreroll) {
                if (slot.hb) {
                    delete slot.hb.a9Targeting;
                }
                doLoop(a9TargetingKeys, (key) => karmads.targeting.clear(key, slot));
            }
        });

        // request new bids for the slots
        log(`starting a9 timeout: ${timeoutLengths.karmaEnforced}`);
        log(`passing timeout to a9: ${timeoutLengths.partnerEnforced}`);
        tryCatch(() => {
            log({label: 'a9BidsRequested'});
            const bidConfig = {
                slots: buildSlots(slots),
                // TEST: if any a9 slots have been refreshed more than once, the bid config we send to a9 contains params.adRefresh = 1
                params: {
                    adRefresh: batchContainsRefreshedSlots(slots)
                },
                timeout: timeoutLengths.partnerEnforced
            };
            log(`bid config sent to a9: ${JSON.stringify(bidConfig)}`);
            apstag.fetchBids(bidConfig, (bids) => {
                karmads.log.logOnceAndReport({
                    label: 'a9BidsRecieved',
                    referencePoint: 'initDemandFetch'
                });
                karmads.log.logOnceAndReport(`a9FailsafeHit: ${timeoutExpired}`, {
                    label: 'a9FailsafeHit',
                    reportValueOverride: `${timeoutExpired}`
                });
                tryCatch(() => {
                    processResponse(bids, callback, storeTargeting, setTargeting, validateBids);
                });
            });
        });
        setTimeout(() => {
            timeoutExpired = true;
            if (!bidsLoaded) {
                log(`a9 did not load before karma-enforced timeout period ended. ${ timeoutLengths.karmaEnforced}ms`, {force: 1, level: 'warn'});
                callback();
            }
        }, timeoutLengths.karmaEnforced);
    };

    return {
        load,
        init,
        isEnabled,
        fetchBids
    };
}());