karmads.callbacks = (function() {
    'use strict';

    const callbackArray = [];
    let callback;

    const init = () => {
        // TEST: if any callbacks are present, they are added to the slot render callback stack
        callback = karmaConfig.callback;
        if (callback) {
            // convert karma.config to an array if a function is passed in
            callback = (typeof callback !== 'function') ? callback : [callback];
            if (Array.isArray(callback)) {
                // loop through and add to the callback stack
                doLoop(callback, addToCallbackStack);
            } else {
                log('karma.config.callback is defined but is not an array of functions. Ignoring callback.', {force: 1, level: 'error'});
            }
        }
        convertCallbackToObject();
    };

    const convertCallbackToObject = () => {
        // TEST: An window.karma.config.callback.push function is available on karma
        // karma.config.callback.push adds a function to the callback stack
        karmaConfig.callback = {
            push: add
        };
    };

    const addToCallbackStack = (fn) => {
        // callbacks are added to the array and pushed into GPT as slotRenderEnded callbacks
        if (typeof fn === 'function') {
            karmads.gpt.addCallback(fn);
            callbackArray.push(fn);
        } else {
            log('karma.config.callback.push(fn): Not a valid function.', {force: 1, level: 'error'});
        }
    };

    const add = (fn) => {
        // TEST: when invoked, window.karma.config.callback.push adds the function to the callback stack
        callback = callback || [];
        // sometimes people overwrite karma.config.callback with a function; they are bad people; scold them
        if (typeof callback === 'function') {
            log('karma.config.callback has been overwritten as a function. It should be an array. Please stop it', {force: 1, level: 'error'});
            convertCallbackToObject();
            add(callback);
        }
        if (Array.isArray(callback)) {
            addToCallbackStack(fn);
        }
    };

    const getCallbackArray = () => callbackArray;

    return {
        init,
        getCallbackArray,
        add
    };
}());