karmads.reload = (function() {
    'use strict';

    const checkParams = (file = 'footer') => {
        // TEST karmads.reload has a method called checkParams
        const isReloaded = !!karmads.dom.lookup('script[src*="karma.mdpcdn.com"][data-reloaded="true"]'),
            matches = getKarmaScript(isReloaded) || [],
            initialEnv = (matches[1] === '' || matches[1] === undefined) ? 'www' : matches[1],
            initialMin =  matches[2] ? (matches[2] === 'js-min') : true,
            envParam = karmads.urlVars.get('adTestEnv'),
            minParam = karmads.urlVars.get('adTestMin'),
            initialFileName = matches[3] ? matches[3] : (apiVersion === 3) ? 'karma.js' : `karma.${file}.js`,
            desiredFileName = initialFileName,
            // TEST: If adTestEnv is present in the query string, karma.vars.environment matches the environment requested in adTestEnv
            desiredEnv = karmaVars.environment = envParam || initialEnv,
            // TEST: karma.vars.minified matches the minification level requested in adTestMin
            // TEST: If the desired environment is local, and no minification is specified, karma.vars.minified is false
            // TEST: If no adTestMin params are present, karma.vars.minified matches that of the initial karma.header.js file
            desiredMin = karmaVars.minified = JSON.parse(minParam || (desiredEnv === 'local' && desiredEnv !== initialEnv) ? 'false' : initialMin),
            subDomain = (desiredEnv === 'www') ? '' : `${desiredEnv}.`;

        let reloadPath;

        karmaVars.scriptPath = matches[0] || false;

        // TEST: If reload is triggered via URL params, each KARMA file is reloaded to the page no more than once
        if ((initialEnv !== desiredEnv || initialMin !== desiredMin || initialFileName !== desiredFileName) && !isReloaded) {

            // TEST: If the requested environment is local, :9999 is added to the end of the new hostname
            // TEST: KARMA files are reloaded over https
            reloadPath = `https://${subDomain}karma.mdpcdn.com${desiredEnv === 'local' && ':9999' || ''}/service/js${desiredMin === true && '-min' || ''}/${desiredFileName}`;

            // TEST: karma.vars.scriptPath contains the minification and environment specified in adTestEnv and adTestMin
            karmaVars.scriptPath = reloadPath;

            // TEST: If a second version of karma.header.js is requested, karma.vars.reloaded is true
            karmaVars.reloaded = true;
            karmaVars.ready = false;

            // TEST: The reloaded version of karma.header.js is added to the page, and the location matches karma.vars.scriptPath
            karmads.dom.create('script', karmaVars.els.head, {
                'src': reloadPath,
                'data-reloaded': 'true'
            });

            log(`Reloading KARMA ${file} due to testing URL param. Env: ${desiredEnv}, Min: ${desiredMin}`, {force: 1});
            return true;
        } else {
            karmaVars.reloaded = isReloaded;
        }
        return false;

    };

    return {
        checkParams
    };

}());