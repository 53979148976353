karmads.targeting = (function() {
    'use strict';
    const alreadySet = {}, // alreadySet lets us keep track of which k/vs we've already set
        // TODO (RVDEV-3929): remove legacy Meredith types
        standardTypes = ['taxonomy', 'taxonomysc', 'structuredcontent', 'listsc', 'recipesc', 'other'],
        legacyStandardTypes = ['app', 'article', 'audio', 'audio_hub', 'category', 'collection', 'freestuff', 'homepage', 'landing_page', 'longform', 'none', 'page', 'photos', 'poll', 'post', 'print', 'product', 'profile', 'project', 'recipe', 'recipe_hub', 'reviews', 'search', 'slideshow', 'sponsored_recipe', 'tag', 'video', 'video_article', 'video_hub', 'video_playlist', 'video_recipe'],
        defaultType = 'other';
    // pvs is an alias for karma.config.targeting
    let pvs,
        targetingEvent,
        targetingEventActive = false;

    // TEST: karmads.targeting has a method called init
    const init = () => {
        if (isLegacyAdUnitFormat()) {
            standardTypes.push(...legacyStandardTypes);
        }
        // TEST: page targeting values are present as an object on karma
        karmaConfig.targeting = karmaConfig.targeting || {};
        targetingEvent = createEvent('karmaSetTargeting');
        // assign pvs/karma.config.targeting reference
        pvs = karmaConfig.targeting;
        // do page-level targeting setup
        pageSetup();
    };

    const fireTargetingEvent = () => {
        // TEST: A 'karmaSetTargeting' event is emitted each time targeting is set or updated
        // NOTE: This event is fired 100ms after targeting is set in order to avoid firing the event
        //       multiple times if a group of kvs are set. This will not be testable until our test framework
        //       has a better way to work with callbacks
        if (!targetingEventActive) {
            targetingEventActive = true;
            setTimeout(() => {
                // TODO (audit): utility for dispatching events?
                win.dispatchEvent(targetingEvent);
                targetingEventActive = false;
            }, 100);
        }
    };

    // TEST: the page type should be lowercase and should not contain any non-alphanumeric characters
    const cleanType = (type) => type.toLowerCase().replace(/[^a-z0-9]/g, '');

    const mapPageType = (type) => {
        // TEST: karma.vars has a string property called 'unmappedPageType'
        karmaVars.unmappedPageType = type;

        // mapPageType standardizes nonstandard page types to standard ones
        type = type ? cleanType(type) : '';

        const legacyToNewTypeMapping = {
            article: 'structuredcontent',
            videoarticle: 'structuredcontent',
            video: 'structuredcontent',
            homepage: 'taxonomy',
            category: 'taxonomysc',
            slideshow: 'listsc',
            recipe: 'recipesc',
            videorecipe: 'recipesc',
            aggregate: 'taxonomysc',
            tag: 'taxonomysc'
        };
        let typeMapping = {
            categorypage: 'taxonomysc',
            index: 'taxonomysc',
            package: 'taxonomysc',
            section: 'taxonomysc',
            sectionfront: 'taxonomysc',
            landingpage: 'taxonomysc',
            recipehub: 'taxonomysc',
            videohub: 'taxonomysc',
            listicle: 'listsc',
            gallery: 'listsc'
        };

        // if we're in ddm mode, map old page types to new ones
        if (!isLegacyAdUnitFormat()) {
            typeMapping = {
                ...typeMapping,
                ...legacyToNewTypeMapping
            };
        }

        // map standard types into typeMapping
        doLoop(standardTypes, (type) => {
            typeMapping[cleanType(type)] = type;
        });

        // if the mapped type is not part of the standard set, it's set to 'other'
        if (type === undefined || type === '') {
            type = defaultType;
        }
        if (typeMapping.hasOwnProperty(type)) {
            type = typeMapping[type];
        }
        return standardTypes.indexOf(type) !== -1 ? type : defaultType;
    };

    // TEST: karmads.targeting has a method named get
    // TEST: karmads.targeting.get returns a page-level targeting value, given a key
    const get = (key) => karmaConfig.targeting[key];

    // TEST: karmads.targeting has a method named set
    // TEST: targeting.set Sets targeting passed in as individual K/V pairs
    const set = (key, val) => {
        // if key is an object, then loop through the keys and run this function on each key/val pair
        if (isObject(key)) {
            doLoop(key, (k) => {
                set(k, key[k]);
            });
            return true;
        }
        // TEST: if targeting values are passed that include invalid characters, KARMA strips the invalid characters before passing to DFP
        val = cleanCharacters(val);
        // TEST: if an empty value or null is passed in to karma.updateTargeting, clear the targeting key
        if (val === '' || val === null) {
            return clear(key);
        }
        // if it's page targeting
        setPageTargeting(key, val);
    };

    // if you pass an object as the first argument, all the other arguments will be shifted up by one. So these are identical in functionality:
    //   set({foo: 'bar'}, 'div-gpt-leaderboard-flex-1', true);
    //   set('foo', 'bar', 'div-gpt-leaderboard-flex-1', true);
    // TEST: If an object is passed unto updateTargeting, but no slot, page-level targeting is set
    // TEST: If an individual key and value is passed unto updateTargeting, but no slot, page-level targeting is set
    // TEST: If an object is passed unto updateTargeting along with a slot, slot level targeting is set
    // TEST: If an individual key and value is passed unto updateTargeting with a slot, slot level targeting is set
    const updateTargeting = (key, val, slot, isPersistent = false) => {
        // if key is an object, then loop through the keys and run this function on each key/val pair
        if (isObject(key)) {
            isPersistent = slot;
            slot = val;
            doLoop(key, (k) => {
                updateTargeting(k, key[k], slot, isPersistent);
            });
            return true;
        }
        // pass through to setSlot if it's slot only
        if (slot) {
            return setSlot(key, val, slot, isPersistent);
        }

        set(key, val);

        // TEST: if a new value for 'id' is passed into karma.updateTargeting on pages where karma.config.targeting.abTest is mdextest, the leaderboard
        if ((key === 'id' || isObject(key) && Object.keys(key).indexOf('id') !== -1) && get('abTest') === 'mdextest') {
            // find the first tier 1 leaderboard
            const leaderboardSlot = karma.slots.refresh.find((slot) => slot.handle.full === 'leaderboard:tier1:1');
            if (leaderboardSlot) {
                // force manual refresh
                karmads.refresh.startRefresh([leaderboardSlot.slotContainer]);
                // reenable timed refresh
                karmads.refresh.timed.reEnableTimedRefreshOnSlot(leaderboardSlot.slotContainer);
                // restart leaderboard docking
                karmads.docking.leaderboard.restart();
            }
        }
    };

    const setPageTargeting = (key, val) => {
        const videoTargetingKeys = karmaConfig.videoTargetingKeys || [];

        // if key is an object, then loop through the keys and run this function on each key/val pair
        if (isObject(key)) {
            doLoop(key, (k) => {
                setPageTargeting(k, key[k]);
            });
            return;
        }

        // TEST: if targeting values are passed that include invalid characters, KARMA strips the invalid characters before passing to DFP
        val = cleanCharacters(val);
        // TEST: if an invalid page type is passed to KARMA, we convert it to a valid type or 'none'
        val = (key === 'type') ? mapPageType(val) : val;
        if (val !== '') {
            // don't set it if it's already been set with this value
            if (alreadySet.hasOwnProperty(key) && alreadySet[key] === JSON.stringify(val)) {
                return;
            }
            // set in karma.config.targeting, alreadySet, and through GPT
            pvs[key] = val;
            alreadySet[key] = JSON.stringify(val);
            karmads.gpt.setPageTargeting(key, val);
            // TEST: If a page targeting key is in karma.config.videoTargetingKeys, it gets set on karma.vars.videoTargeting
            if (videoTargetingKeys.indexOf(key) > -1) {
                const targetingObj = {};
                targetingObj[key] = val;
                moduleExists('hb.video') && karmads.hb.video.setTargeting(targetingObj);
            }
            // fire the targeting event
            fireTargetingEvent();
        }
    };

    const setSlot = (key, val, slot, isPersistent) => {
        // TEST: A key and value passed into setSlot is set on the slot
        // check the slot
        slot = karmads.slots.getSlot(slot);
        val = cleanCharacters(val);
        // if the slot is invalid, bail out early
        if (!slot) {
            return false;
        }
        // Default behavior is targeting is not persistent and will be cleared off when a new ad request is initiated
        // Non-persistent targeting should only be used after a refresh has been kicked off (e.g. for header bidder)
        // TEST: If isPersistent is true, the targeting is added to slot.persitentTargeting
        // TEST: If isPersistent is set to false or undefined, the targeting is not added to slot.persistentTargeting
        if (isPersistent) {
            slot.persistentTargeting[key] = val;
        }
        // set the value on the slot
        karmads.gpt.setSlotTargeting(slot, key, val);
        return true;
    };

    // Clear targeting values from page or slot
    // To clear all values on a slot, pass in undefined as the key (as done in karmads.refresh.targeting)
    // Note that this does not clear values off of slot.persistentTargeting, so if slotSetup is called, those
    // values will be re-set on the slot
    // TEST: When clear() is called on a key, that page targeting key is cleared from GPT
    // TEST: When clear() is called on a key, that page targeting key is cleared from karma.config.targeting
    // TEST: When clear() is called on a key and slot, that key is cleared from GPT on that slot
    // TEST: When clear() is called on a key of undefined on a slot, all targeting is cleared from GPT on that slot
    const clear = (key, slot) => {
        // delete from karma.config.targeting
        if (pvs.hasOwnProperty(key)) {
            delete pvs[key];
        }
        // delete from alreadySet
        if (alreadySet.hasOwnProperty(key)) {
            delete alreadySet[key];
        }
        // clear GPT targeting
        if (slot) {
            karmads.gpt.clearSlotTargeting(slot, key);
        } else {
            karmads.gpt.clearPageTargetingKey(key);
        }
        // fire the targeting event
        return fireTargetingEvent();
    };

    // TEST: A page level targeting value 'path' is set to the value of the URL path split on '/'
    const setPath = () => set('path', win.location.pathname.split('/').filter((p) => p));

    // page-level targeting values setup
    const pageSetup = () => {
        const refhub = karmaVars.url['ref_hub'],
            searchReferrer = doc.referrer.match(/https:\/\/(www\.(google|bing)|search\.(yahoo))\.com/);

        // TEST: If ref_hub=foo is set as a URL parameter, a page-level targeting value of 'ref_hub' is set to 'foo'
        if (refhub && refhub !== '') {
            pvs['ref_hub'] = refhub;
        }

        // TEST: if ref_hub is set on the URL or in local storage, karmaConfig.targeting has a non-empty page targeting value \'ref_hub\' that is a string
        setAndCheckLocalStorageVars();
        doLoop(karmaConfig.storedTargeting, (key) => {
            if (karmaConfig.storedTargeting[key]) {
                pvs[key] = karmaConfig.storedTargeting[key];
            }
        });

        // TEST: if we're on the homepage, the page targeting value 'type' should be set as 'homepage'
        // TEST: if isHomepage() returns true, but a page targeting value 'search' is set, the 'type' is not set to 'homepage'
        if (isHomepage() && !pvs.search) {
            pvs.type = isLegacyAdUnitFormat() ? 'homepage' : 'taxonomy';
        }

        // set values that came in via the URL
        // TEST: On any URL that has adTestKeyValues=key,value-key2,value2 in the query string, the page targeting parameter 'key' is set to 'value', and the page targeting parameter 'key2' is set to 'value2'
        const testValues = karmads.urlVars.getTestValues();
        doLoop(testValues, (key) => {
            pvs[key] = testValues[key];
        });

        // TEST: if the type is undefined or empty, it's set to 'other'
        // TODO (audit): this is redundant to logic in mapPageType; remove?
        if (pvs.type === undefined || pvs.type === '') {
            pvs.type = defaultType;
        }

        // TEST: A page level targeting value 'path' is set
        setPath();

        // TEST: A targeting key of 'pft' is set to a value of 1 on approx 1 in every 1000 page views (0.1%)
        if (randomPercentage(0.1)) {
            pvs.pft = 1;
        }

        // TEST: If the referrer is Google, a page targeting value of referrer=google will be set
        // TEST: If the referrer is Yahoo, a page targeting value of referrer=yahoo will be set
        // TEST: If the referrer is Bing, a page targeting value of referrer=bing will be set
        if (searchReferrer && searchReferrer.length > 1) {
            // filter out undefineds from the match array
            pvs.referrer = searchReferrer.filter((match) => match)[2];
        }

        // set all the targeting values
        set(pvs);
    };

    // Instantiates default slot targeting values (including stored on persistentTargeting)
    const slotSetup = (slot) => {
        // TEST: karmads.targeting has a method called slotSetup
        // TEST: each slot has an object property called 'persistentTargeting'
        slot.persistentTargeting = slot.persistentTargeting || {};
        // TEST: slotSetup sets slot= by default, where the value is the slot id minus 'div-gpt-'
        slot.persistentTargeting['slot'] = slot.slotContainer.replace('div-gpt-', '');
        // TEST: slotSetup sets all values stored on slot.persistentTargeting
        const persistentTargeting = slot.persistentTargeting;
        doLoop(persistentTargeting, (key) => {
            setSlot(key, persistentTargeting[key], slot, true);
        });
    };

    const debugLog = () => {
        // debugging console logs if you dun fscked up
        const requiredKvs = ['id', 'type', 'channel'];
        doLoop(requiredKvs, (key) => {
            if (!pvs[key] || pvs[key] === '') {
                log(`karma.config.targeting. ${key} is missing or empty.`, {force: 1, level: 'error', doc: `karmaconfigtargeting${key}---required`});
            }
        });

        if (standardTypes.indexOf(karmaVars.unmappedPageType) === -1) {
            log(`You provided an invalid value for karma.config.targeting.type: ${karmaVars.unmappedPageType}.`, {force: 1, level: 'warn', doc: 'karmaconfigtargetingtype---required'});
        }
    };

    // fires when targeting is all ready
    const ready = () => {
        // dispatch the targetingReady event
        // TODO (audit): do we need a utility for dispatching events?
        if (win.dispatchEvent) {
            log('Dispatching event: karmaTargetingReady');
            win.dispatchEvent(createEvent('karmaTargetingReady'));
        }
        // TEST: karma.vars has property called targetingReady that is set to true
        karmaVars.targetingReady = true;

        karmads.translate.targetingReady();

        debugLog();
    };

    const setRequestId = () => {
        if (alreadySet.hasOwnProperty('mrid')) {
            return alreadySet.mrid;
        }

        // TEST: if the Meredith Request id is available, the targeting value 'mrid' should exist and not be empty
        const mrid = karmads.muid.getRequestId();
        log(`Setting Meredith request id: ${mrid}`);
        set('mrid', mrid);

        return mrid;
    };

    const setMdpUserId = () => {
        if (alreadySet.hasOwnProperty('muid')) {
            return alreadySet.muid;
        }

        // TEST: if the Meredith User ID is available, the page targeting value 'muid' should exist and not be empty
        const muid = karmads.muid.getMuid();
        log(`Setting muid: ${muid}`);
        set('muid', muid);
        karmads.gpt.setPublisherProvidedId(muid);

        return muid;
    };

    /* These are the targeting operations that run in requestQueue just before the request is sent to GAM (after header bidding) */
    const prerequest = () => {
        // TEST: karmads.targeting has a method called prerequest
        setMdpUserId();
        setRequestId();
    };

    return {
        init,
        clear,
        get,
        prerequest,
        ready,
        set,
        setSlot,
        slotSetup,
        updateTargeting,
        vars: {
            standardTypes
        }
    };
}());
