karmads.scrollLoad = (function() {
    'use strict';
    // TEST: Slots with a property of scrollLoad: 'true' that are outside of the viewport have not been requested
    // TEST: Slots with a property of scrollLoad: 'loaded' have been requested
    // TEST: All slots in karma.slots.scrollLoad have the property of scrollLoad, and the value is not 'loaded'

    let debouncedScrollCheck,
        initialized = false,
        threshold;

    const init = (slots) => {
        // TEST: All slots with a data attribute of scroll-load also have a property of scrollLoad
        // TEST: The value of slot.scrollLoad is always a string
        const scrollLoadSlots = [];

        initialized = true;
        threshold = convertStringToNumber(karmaConfig.scrollLoadThreshold) || 100;

        doLoop(slots, (slot) => {
            const dom = karmads.dom.get(slot),
                /* Leaving lazyLoad check in until Element is fully converted */
                scrollLoad = slot.scrollLoad || dom.dataset['scrollLoad'] || dom.dataset['lazyScroll'];
            if (scrollLoad === 'true') {
                scrollLoadSlots.push(slot);
                slot.scrollLoad = scrollLoad;
            }
        });

        karma.slots.scrollLoad = scrollLoadSlots;

        if (karma.slots.scrollLoad.length > 0) {
            debouncedScrollCheck = debounce(checkAndRequest, 25);
            startListeners();
            karmads.callbacks.add(scrollLoadCallback);
        }

        return slots;
    };

    const removeOffScreenSlots = (slots) => {
        // TEST: karmads.scrollLoad has a method called removeOffScreenSlots
        // TEST: removeOffScreenSlots returns an array of slots minus slots that are marked for scrollLoad and are not within the viewport threshold
        const validForRequestSlots = [];

        if (!initialized) {
            slots = init(slots);
        }

        doLoop(slots, (slot) => {
            if (slot.scrollLoad === 'true') {
                // If a slot is scrollLoad, but is valid to be requested for other reasons, it is ok to reqeust
                if (shouldLoad(slot, threshold)) {
                    slot.scrollLoad = 'processing';
                    validForRequestSlots.push(slot);
                }
            } else {
                validForRequestSlots.push(slot);
            }
        });

        return validForRequestSlots;
    };

    const scrollLoadCallback = (evt) => {
        // after the lazy scroll slots loads, this callback fires and updates the scrollLoad slot status/listeners, and removes it from the list of lazy scrolls
        let scrollLoadSlots = karma.slots.scrollLoad,
            slotId;

        if (scrollLoadSlots.length === 0) {
            return;
        }

        try {
            slotId = evt.slot.getSlotElementId();
        } catch (e) {
            log(e.message, {force: 1, level: 'error'});
        }

        const slot = karmads.slots.getById(slotId);

        if (!slot.scrollLoad) {
            return;
        }

        slot.scrollLoad = 'loaded';

        scrollLoadSlots = scrollLoadSlots.filter((slot) => slot.slotContainer !== slotId);

        karma.slots.scrollLoad = scrollLoadSlots;

        if (scrollLoadSlots.length === 0) {
            removeListeners();
        }
    };

    // TEST: karmads.scrollLoad has a method called check
    const check = (slots, threshold) => {
        // TEST: check returns slots in batch sizes equal to config.scrollLoadBatchSize as long as the first slot is within the threshold
        // TEST: a slot passed into check() does not pass if it is not displayed

        // checks if there are lazyScoll slots that are ready to be filled
        const batchSize = karmaConfig.scrollLoadBatchSize || 1,
            slotsToRefresh = [];
        let batchCounter = 0;

        if (!karmaVars.firstAdReturned) {
            return slotsToRefresh;
        }

        doLoop(slots, (slot) => {
            if (shouldLoad(slot, threshold, batchCounter)) {
                if (batchCounter === 0) {
                    batchCounter = batchSize;
                }
                batchCounter--;
                slotsToRefresh.push(slot);
                slot.scrollLoad = 'processing';
                slot.refreshType = 'scroll';
            }
        });
        return slotsToRefresh;
    };

    const checkAndRequest = () => {
        const slots = check(karma.slots.scrollLoad, threshold);
        if (slots.length > 0) {
            karmads.requestQueue.process(slots);
        }
    };

    const shouldLoad = (slot, threshold, batchCounter) => {
        const offset = Math.abs(karmads.viewability.getSlotOffsetFromViewport(slot)),
            isDisplayed = karmads.dom.isDisplayed(slot);

        return slot.scrollLoad === 'true' && isDisplayed && ((offset < threshold) || batchCounter > 0);
    };

    const startListeners = () => {
        doc.addEventListener('scroll', debouncedScrollCheck, {passive: true});
        doc.addEventListener('click', checkAndRequest);
        win.addEventListener('load', checkAndRequest);
    };

    const removeListeners = () => {
        doc.removeEventListener('scroll', debouncedScrollCheck);
        doc.removeEventListener('click', checkAndRequest);
    };

    return {
        check, // Exposed primarily for testing purposes
        checkAndRequest,
        removeOffScreenSlots
    };

}());