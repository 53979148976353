
karmads.tiers = (function() {
    'use strict';

    const getFromType = (slotName) => {
        const tierPos = slotName.indexOf('tier');
        return (tierPos !== -1) ? slotName.charAt(tierPos + 4) : false;
    };

    const isValidTier = (tier) => {
        return (tier !== undefined && !isNaN(parseInt(tier, 10)) && tier >= 0 && tier <= 4);
    };

    const setTierSettings = (tier, slot) => {
        if (isValidTier(tier)) {
            const slotType = slot.slotContainer.replace(/-\d+$/i, '').replace('-lazy', ''),
                // TEST: if a slot has a tierSlugOverride, that value is used for the tier string in place of 'tier'
                localTierString = `${slot.tierSlugOverride || 'tier'}${tier}`;

            slot.tier = parseInt(tier, 10);
            slot.hb = slot.hb || {};
            // TEST: Slots have a tierString of 'tierx' that matches the data-tier attribute
            slot.tierString = localTierString;
            if (karmaConfig.tiers && karmaConfig.tiers[slotType] && karmaConfig.tiers[slotType][localTierString]) {
                // TEST: tier-level configurations override slot-level configs
                doLoop(karmaConfig.tiers[slotType][localTierString], (key) => {
                    if (isObject(slot[key])) {
                        slot[key] = extend(slot[key], karmaConfig.tiers[slotType][localTierString][key]);
                    } else {
                        slot[key] = karmaConfig.tiers[slotType][localTierString][key];
                    }
                });
            }
        }
        return slot;
    };

    return {
        getFromType,
        isValidTier,
        setTierSettings
    };
}());