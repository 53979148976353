karmads.lazyLoad = (function() {
    'use strict';

    let unfilledSlots = [];

    const create = (type, container, autofill, callback, opts = {}) => {
        // TEST: a function is available on window.karma called 'createSlot'
        // TEST: when invoked, 'createSlot' creates a lazy loaded ad slot, which is added to karma.slots.catalog
        const abortMsg = '. Aborting karma.createSlot.';

        // TEST: if the type value (first argument) is a non-string or empty string, createSlot will exit gracefully with a log message
        if (typeof type !== 'string' || type === '') {
            log(`The lazy load slot type you passed to karma.createSlot is not set or is not a string${abortMsg}`, {force: 1, level: 'error', doc: 'karmacreateslot'});
            return false;
        }

        // TEST: if the container value (second argument) is string, but does not exist in the DOM, createSlot will exit gracefully with a log message
        if (typeof container === 'string' && !karmads.dom.lookup(`#${container}`)) {
            log(`You passed "${container}" as the lazy slot container id to karma.createSlot, but that element does not exist in the DOM${abortMsg}`, {force: 1, level: 'error', doc: 'karmacreateslot'});
            return false;
        }

        // TEST: if the autofill value (third argument) is not a boolean, autofill defaults to false with a log message
        if (typeof autofill !== 'boolean') {
            log('the autofill argument for karma.createSlot is not set or is not a boolean, so it defaults to false. You need to call karma.fillSlots() in order to fill the ad.', {force: 1, level: 'warn', doc: 'karmafillslots'});
            autofill = false;
        }

        // TEST: if the fourth value is an object, assume that it is options, not callback. Set callback to undefined
        if (typeof callback === 'object' && Object.keys(callback).length) {
            opts = callback;
            callback = undefined;
        }

        container = (typeof container === 'string') ? karmads.dom.lookup(`#${  container}`) : container;

        // TEST: if the container value is a DOM element but is not attached to the document body, createSlot fails gracefully
        if (!doc.body.contains(container) && doc.body !== container) {
            log(`The container you passed to karma.createSlot for ${type} is not attached to the document body${abortMsg}`, {force: 1, level: 'error', doc: 'karmacreateslot'});
            return false;
        }

        let tier = opts.tier;

        // TEST: Tier can be passed into the options object as a number when creating a lazy load slot
        // TEST: Tier can be passed into the options object as a string when creating a lazy load slot
        // TEST: If a tier is passed into the options object and as part of the type, the later is used
        const tierFromType = karmads.tiers.getFromType(type);
        if (tierFromType) {
            type = type.replace(`-tier${tierFromType}`, '');
            tier = tierFromType;
        }

        // TEST: karma.slots has a property called lazy that contains all slots configs for slots that could potentially be lazy loaded
        // TEST: A slot can be created using a non-lazy name
        let slot = karma.slots.lazy.filter((slot) => slot.type === type || slot.lazyType === type)[0];

        // TEST: if the passed in type does not match a slot configured for this site, createSlot fails gracefully
        if (!slot) {
            log(`Lazy slot "${type}" is not configured for this site${abortMsg}`, {force: 1, level: 'error', doc: 'karmacreateslot'});
            return false;
        }

        // TEST: if the type value (first argument) does not include tier info, and tier was not passed, the slot is created as tier 0
        if (!karmads.tiers.isValidTier(tier)) {
            log(`You did not pass a valid tier (1-4) to karma.createSlot. The slot will be loaded as tier0. You should use "${type}'-tierx" instead, where "x" is the desired tier`, {force: 1, level: 'warn', doc: 'karmacreateslot'});
            tier = 0;
        }

        // increment the lazy slotContainer id based on how many of that lazy slot type there are
        slot.lazyCount = (!slot.lazyCount) ? 0 : slot.lazyCount;
        slot.lazyCount += 1;
        // TEST: if the type is valid, the KARMA placeholder DIV is added to the container DOM element with the correct tier
        const slotContainer = `${slot.lazyType}-${slot.lazyCount}`,
            el = karmads.dom.create(
                'div',
                container,
                {id: slotContainer, 'data-tier': tier}
            );

        // Make a copy of the slot so we break reference to the karma.slots.lazy array
        slot = clone(slot);

        // TEST: Slots created by createSlot have a tier property that is a valid tier
        slot.tier = tier;

        slot.slotContainer = slotContainer;

        // TEST: Slots created by createSlot have a 'tierString' property that is a string of 'tier' plus the tier number
        slot.tierString = (slot.tierSlugOverride || 'tier') + tier;
        slot.persistentTargeting = slot.persistentTargeting || {};

        // TEST: if targeting values were passed in opts.targeting, those values are appended as slot targeting key/value pairs
        if (opts.hasOwnProperty('targeting') && typeof opts.targeting === 'object') {
            extend(slot.persistentTargeting, opts.targeting);
        }

        // TEST: Tier-level slot overrides are merged into slot objects
        slot = karmads.tiers.setTierSettings(tier, slot);

        // TEST: the slot refreshType property is set to lazy by default
        // TEST: If a value is passed into opts.refreshType, that value is used as the slot refreshType
        slot.refreshType = opts.refreshType || 'lazy';

        // TEST: If options.byPassVisibliltyCheck is passed into the lazyLoad slot, that setting is applied to the slot
        if (opts.bypassVisibilityCheck) {
            slot.bypassVisibilityCheck = true;
        }

        // TEST: the new slot is defined and registered with GPT
        karmads.gpt.define(slot, karmads.targeting.adunit.build(slot), slotContainer);
        // TEST: the new slot is added to and karma.slots.slotCatalog
        karma.slots.catalog.push(slot);

        karmads.targeting.slotSetup(slot);
        karmads.gpt.display(slotContainer);
        // TEST: the new slot information is sent to GA
        karmads.reporting.lazyLoadSlot(`${type}-${slot.tierString}`);

        // TEST: the new slot is added to the ad slot fill queue
        unfilledSlots.push(slot);

        log(`Finished adding GPT slot ${slotContainer}`);

        // TODO: This section is depracated. We can remove once
        // AR recipe detial pages transition completely to Element
        if (opts.refreshable) {
            if (opts.purgeRefreshList) {
                karmads.refresh.purgeSlots();
            }
            karmads.refresh.addSlot(slotContainer);
        }

        // TEST: if autofill is set to true, go ahead and fill the slot
        if (autofill) {
            fill();
        }

        // TEST: if the callback value (third argument) is a function, add it to the GPT callback stack for this slot only
        if (typeof callback === 'function') {
            karmads.gpt.addCallback((event) => {
                if (event.slot.getSlotId().getDomId() === slotContainer) {
                    callback(event);
                }
            });
        // TEST: if the callback value (third argument) is not a function or an object, do not add it to the GPT callback stack and log a warning
        } else if (typeof callback !== 'undefined') {
            log(`Callback passed to karma.createSlot for ${slotContainer} is ${typeof callback}, not a function. ignoring callback`, {force: 1, level: 'warn', doc: 'karmacreateslot'});
        }

        karmads.translate.slots();

        return el;
    };

    const fill = () => {
        // TEST: a function named fillLazyLoadSlots exists on window.karma
        // TEST: when called, fillLazyLoadSlots fills any lazy loaded slots that have been put in the fill queue
        if (unfilledSlots.length > 0) {
            karmads.requestQueue.process(unfilledSlots);
            unfilledSlots = [];
        } else {
            log('Attempted to fill newly created slots, but no unfilled slots exist.', {force: 1});
        }
    };

    const removeUnfilledSlot = (slotId) => {
        // TEST: karmads.lazyLoad has a method called removeUnfilledSlot
        // TEST: removeUnfilledSlot removes the passed in slot of from the fillQueue
        // TEST: if an invalid argument is passed into removeUnfilledSlot, nothing happens
        // TEST: karmads.lazyLoad.removeUnfilledSlot returns the remaining unfilledSlots
        unfilledSlots = unfilledSlots.filter((slot) => slot.slotContainer !== slotId);
        return unfilledSlots;
    };

    return {
        create,
        fill,
        removeUnfilledSlot
    };
}());
