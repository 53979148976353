karmads.gpt = (function() {
    'use strict';

    let gptJsCalled = googletag.mdpScriptCalled || false,
        gptServicesEnabled = false;

    const init = () => {
        // TEST: the GPT library is loaded
        if (!googletag.mdpScriptCalled && !gptJsCalled) {
            gptJsCalled = true;
            fetchResource('https://securepubads.g.doubleclick.net/tag/js/gpt.js', noop, karmads.kismet.report);
            // TEST: googletag.mdpScriptCalled is set to true
            googletag.mdpScriptCalled = true;
            log({report: true, label: 'gptReady'});
        }
    };

    const setNonPersonalizedAds = (value = false) => {
        // TEST: karmads.gpt has a method called setNonPersonalizedAds
        if (value) {
            log('Enabling non-personalized ads in GPT', {force: 1});
        }
        googletag.pubads().setRequestNonPersonalizedAds(value ? 1 : 0);
    };

    const restrictDataProcessing = (value = false) => {
        // TEST: karmads.gpt has a method called restrictDataProcessing
        if (value) {
            log('Restricting data processing in GPT', {force: 1});
        }
        googletag.pubads().setPrivacySettings({'restrictDataProcessing': value});
    };

    const setPublisherProvidedId = (muid = karmads.muid.getMuid()) => {
        // TEST: karmads.gpt has a method called setPublisherProvidedId
        // TEST: When MUID is set, we also set google's PPID
        if (typeof muid === 'string' && muid.length > 0) {
            googletag.pubads().setPublisherProvidedId(muid);
        }
    };

    const enable = () => {
        if (!gptServicesEnabled) {
            gptServicesEnabled = true;
            // TEST: single request mode is enabled in GPT
            googletag.pubads().enableSingleRequest();

            // privacy settings are applied
            karmads.privacy.check();

            // set the publisher provided id, if provided
            setPublisherProvidedId();

            // TEST: disableInitialLoad is called to put us in GPT batch request mode (requied for lazy load)
            googletag.pubads().disableInitialLoad();
            googletag.enableServices();
        }
    };

    const display = (slotContainer) => queuePush(() => googletag.display(slotContainer));

    const displayAllSlots = () => {
        // TEST: when displayAllSlots is invoked, all ad slots with a slotType property defined are displayed
        doLoop(karma.slots.catalog, (adSlot) => {
            if (adSlot.slotType) {
                display(adSlot.slotContainer);
            }
        });
    };

    const queuePush = (fn) => {
        // TEST: when invoked, queuePush pushes a function into the GPT command queue (so that it executes after GPT is initialized)
        if (typeof fn === 'function') {
            googletag.cmd.push(fn);
        }
    };

    const define = (slot, slotAdUnit, slotName) => {

        // eslint-disable-next-line func-style
        function getGptSlotFromKarmaSlotObject() {
            // get the GPT Slot from inside a KARMA slot
            const id = this.slotContainer,
                gptSlots = googletag.pubads().getSlots().filter((slot) => !!(slot.getSlotElementId() === id));
            return gptSlots.length ? gptSlots[0] : false;
        }

        // build the slot handle
        karmads.slots.makeHandle(slot);

        // TEST: out of page slots are defined as out of page slots in GPT
        let gptSlot;
        if (slot.outOfPage) {
            gptSlot = googletag.defineOutOfPageSlot(slotAdUnit, slotName).addService(googletag.pubads());
        } else {
            gptSlot = googletag.defineSlot(slotAdUnit, karmads.slots.convertAdSizes(slot), slotName).addService(googletag.pubads());
        }

        slot.gptSlot = getGptSlotFromKarmaSlotObject;

        return gptSlot;
    };

    const destroy = (slots) => {
        /* if slots is not an array, convert it to one */
        slots = !Array.isArray(slots) ? [slots] : slots;
        // TEST: when invoked, karma.destroySlot removes the slot from GPT
        queuePush(() => googletag.destroySlots(slots));
    };

    const refresh = (slots) => {
        // TEST: karmads.gpt has a method called refresh
        // TEST: All instant ads on the page have been requested
        queuePush(() => {
            googletag.pubads().clear(slots);
            googletag.pubads().refresh(slots);
        });
    };

    // returns GPT slot targeting values
    const getSlotTargeting = (slot) => slot.gptSlot().getTargetingMap();

    const setPageTargeting = (key, val) => {
        // TEST: when invoked, setPageTargeting sets a GPT page-level targeting key/value pair
        queuePush(() => {
            googletag.pubads().setTargeting(cleanCharacters(key), cleanCharacters(val));
        });
    };

    const setSlotTargeting = (slot, key, val) => {
        // TEST: when invoked, setSlotTargeting sets a GPT targeting key/value pair on the given slot
        if (slot !== undefined && slot !== false && typeof key === 'string' && key !== '') {
            if (val === '') {
                return clearSlotTargeting(slot, key);
            }
            queuePush(() => {
                slot.gptSlot().setTargeting(cleanCharacters(key), cleanCharacters(val));
            });
        }
    };

    const setSlotTargetingMap = (slot, targetingMap) => {
        // TEST: when invoked, setSlotTargetingMap updates a slot with targeting k/vs set in an object passed to the function
        if (slot !== undefined && slot !== false && isObject(targetingMap)) {
            queuePush(() => {
                slot.gptSlot().updateTargetingFromMap(targetingMap);
            });
        }
    };

    const clearPageTargetingKey = (key) => {
        // TEST: when invoked, clearPageTargeting clears a specific GPT page-level key/value pair
        if (typeof key === 'string' && key !== '') {
            key = cleanCharacters(key);
            queuePush(() => {
                // only clear if the page targeting key exists
                if (googletag.pubads().getTargeting(key).length) {
                    googletag.pubads().clearTargeting(key);
                }
            });
        }
    };

    const clearSlotTargeting = (slot, key) => {
        // TEST: when invoked, clearSlotTargeting clears a GPT targeting key/value pair on the given slot
        if (slot) {
            key = cleanCharacters(key);
            if (typeof key === 'string' && key !== '') {
                queuePush(() => {
                    if (slot.gptSlot().getTargetingKeys().indexOf(key) !== -1) {
                        slot.gptSlot().clearTargeting(key);
                    }
                });
            } else {
                queuePush(() => {
                    slot.gptSlot().clearTargeting();
                });
            }
        }
    };

    const addCallback = (fn, eventType = 'slotRenderEnded') => {
        // TEST: when invoked, addCallback pushes a function into the GPT slotRenderEnded callback stack (so that it executes after each ad is rendered)
        if (typeof fn === 'function') {
            queuePush(() => {
                googletag.pubads().addEventListener(eventType, fn);
            });
        }
    };

    // TEST: getGptObjects returns the GPT slot objects for the given KARMA slot objects
    const getGptObjects = (slotConfigs) => slotConfigs.map((config) => config.gptSlot());

    // TEST: karmads.gpt has a method called requestWebInterstitial
    // TEST: if karma.config,.useWebInterstitial is set to true, the web interstitial is added to the page  (targeting: slot=webInterstitial)
    const requestWebInterstitial = () => {
        if (!karmaConfig.useWebInterstitial) {
            return;
        }
        queuePush(() => {
            // TEST: The web interstitial has a tier1 ad unit (should match oop slots)
            const adUnit = karmads.targeting.adunit.build({tierString: 'tier1'}),
                webInterstitial = googletag.defineOutOfPageSlot(adUnit, googletag.enums.OutOfPageFormat.INTERSTITIAL);
            webInterstitial.addService(googletag.pubads());
            webInterstitial.setTargeting('slot', 'webInterstitial');
            googletag.display(webInterstitial.getSlotElementId());
            googletag.pubads().refresh([webInterstitial]);
            // TEST: The webInterstitial slot is stored at karma.slots.webInterstitial
            karma.slots.webInterstitial = webInterstitial;
        });
    };

    return {
        addCallback,
        clearPageTargetingKey,
        clearSlotTargeting,
        define,
        destroy,
        display,
        displayAllSlots,
        enable,
        getGptObjects,
        getSlotTargeting,
        init,
        queuePush,
        refresh,
        requestWebInterstitial,
        restrictDataProcessing,
        setNonPersonalizedAds,
        setPageTargeting,
        setPublisherProvidedId,
        setSlotTargeting,
        setSlotTargetingMap
    };

}());
