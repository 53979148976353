karmads.methods = (function() {

    const init = () => {
        const methods = {
            debug: karmads.debug.publicDebug,
            showLog: karmads.debug.showLog,
            log: karmads.log.log,
            init: karmads.timeline.init,
            clearTargeting: karmads.targeting.clear,
            createSlot: karmads.lazyLoad.create,
            fillSlots: karmads.lazyLoad.fill,
            destroySlot: karmads.slots.destroy,
            // TEST: karma.updateTargeting is mapped to karmads.targeting.set
            updateTargeting: karmads.targeting.updateTargeting,
            getSlotById: karmads.slots.getById,
            getSlotCount: karmads.slots.getSlotCount,
            disableTimedRefreshOnSlot: karmads.refresh.timed.disableTimedRefreshOnSlot,
            reEnableTimedRefreshOnSlot: karmads.refresh.timed.reEnableTimedRefreshOnSlot,
            releaseDocking: moduleExists('docking') ? karmads.docking.disableDocking : noop,
            initDocking: moduleExists('docking.leaderboard') ? karmads.docking.leaderboard.restart : noop,
            report: karmads.reporting.segmentReport,
            isInView: karmads.viewability.isInView,
            // TEST: A globally scoped function called window.karma.refresh is available
            refresh: karmads.refresh.startRefresh,
            // TEST: karma has a method called setBypassViewabilityRequirement that is an alias to karmads.viewability.setBypassViewabilityRequirement
            setBypassViewabilityRequirement: karmads.viewability.setBypassViewabilityRequirement,
            // TEST: karma has a method called purgeRefreshSlots which is an alias of karmads.refresh.purgeSlots
            purgeRefreshSlots: karmads.refresh.purgeSlots,
            rebuildRails: moduleExists('docking.rail') ? karmads.docking.rail.rebuildRails : noop,
            runTests: karmads.utilities.runTests,
            resetVideoTargeting: noop,
            updateVideoTargeting: moduleExists('hb.video') ? karmads.hb.video.updateTargeting : noop
        };

        doLoop(methods, (key) => karma[key] = methods[key]);
    };

    return {
        init
    };

}());