karmads.targeting = karmads.targeting || {};
karmads.targeting.adunit = (function() {
    'use strict';

    // TODO (audit): more sites to add here?
    const deactivatedSites = ['money', 'si', 'fansided', 'kraftrecipes', 'dep', 'departures', 'emerils', 'foodwishes', 'ironsearch', 'more', 'parenting', 'mywedding', 'serpadres', 'sp', 'siempremujer', 'sm'],
        revshareSites = ['celebwell', 'eatthis', 'bestlife', 'bestlifeonline'];

    let topLevelAdUnit,
        pvs,
        siteName;

    const init = () => {
        // fetch the site name from the domain
        siteName = getSite();

        topLevelAdUnit = `${karmaConfig.networkCode}/${getTopLevelAdUnit()}`;

        pvs = karmaConfig.targeting || {};
        // TEST: the ad unit has no spaces in it
        karmaConfig.site = cleanCharacters(karmaConfig.site);
    };

    const getAdUnitPrefix = () => (revshareSites.indexOf(siteName) !== -1) ? 'revshare' : 'ddm';

    const getTopLevelAdUnit = () => {
        const deviceSuffix = isMobilePage ? 'mob' : 'com';

        // TEST: On any URL that has an email address in it: karmaConfig.site will be set to 'ddm.email.com'
        if (karmads.urlVars.urlContainsEmail()) {
            karmaConfig.site = 'ddm.email.com';
        }

        // TEST: If an email address is not present in the URL: if karma.config.site is specified, we use that; otherwise, we generate it
        if (!karmaConfig.site || karmaConfig.site.length < 1) {
            karmaConfig.site = isLegacyAdUnitFormat() ? `${siteName}.mdp.${deviceSuffix}` : `${getAdUnitPrefix()}.${siteName}.${deviceSuffix}`;
        }

        // TEST: If the site is in the list of deactivated sites, prepend 'deactivated.' to the site name
        if (isSiteDeactivated(karmaConfig.site)) {
            karmaConfig.site = `deactivated.${karmaConfig.site}`;
        }

        return karmaConfig.site;
    };

    const buildSub = (slot) => {
        let adUnits = [],
            targeting = clone(pvs);
        // TEST: If the slot is lazy and has unique targeting values, use those to build the ad unit. We need to clone so we don't override the settings on the page.
        if (slot && karmads.slots.isLazy(slot)) {
            targeting = extend(targeting, slot.persistentTargeting);
        }

        // TEST: the second-level ad unit should match the pattern 'tierx'
        const secondLevelAdUnit = (slot && slot.tierString) ? slot.tierString : 'tier0';
        adUnits.push(secondLevelAdUnit);
        // TEST: for native ad slots, the ad unit does not have 3rd or 4th level values appended
        if (secondLevelAdUnit.indexOf('ntv') !== 0) {
            // TEST: for non-native ad slots, the third-level ad unit should match the page targeting value 'type'
            adUnits.push((targeting.type !== '') ? targeting.type : 'other');
            // TEST: for non-native ad slots, the fourth level ad unit should match the page targeting value 'channel'
            if (targeting.channel) {
                adUnits.push(targeting.channel);
            }
        }

        // TEST: the 2nd-4th level ad units should not contain spaces
        adUnits = adUnits.map(cleanCharacters);
        // TEST: the 2nd-4th level ad units should be delimited with slashes
        return (adUnits.length > 0 ? '/' : '') + adUnits.join('/');
    };

    const buildTop = () => topLevelAdUnit;

    const build = (slot) => topLevelAdUnit + buildSub(slot);

    const getTopLevelVideoAdUnit = () => {
        const deviceSuffix = isMobilePage ? '.mob' : '';
        if (isLegacyAdUnitFormat()) {
            return `${karmaConfig.networkCode}/${siteName}.mdp.video${deviceSuffix}`;
        }
        return `${karmaConfig.networkCode}/${getAdUnitPrefix()}.${siteName}.video${deviceSuffix}`;
    };

    const isSiteDeactivated = (site) => {
        const hostname = win.location.hostname.split('.').splice(-2)[0],
            siteName = site.split('.')[0];
        return !!(deactivatedSites.indexOf(siteName) !== -1 || deactivatedSites.indexOf(hostname) !== -1);
    };

    return {
        build,
        buildTop,
        buildSub,
        getTopLevelAdUnit,
        getTopLevelVideoAdUnit,
        init
    };
}());