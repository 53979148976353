karmads.refresh = (function() {
    'use strict';

    karmaVars.refreshingAds = false;
    karmaConfig.refresh = karmaConfig.refresh || {};

    // from an array of slots, returns an array of slot ids
    const getSlotIds = (slots = karma.slots.refresh) => slots.map(slot => slot.slotContainer);

    const purgeSlots = () => {
        // TEST: when invoked, purgeRefreshSlots wipes out the list of soft-refreshable slots
        const refreshSlots = karma.slots.refresh = [];
        return refreshSlots;
    };

    const addSlot = (id) => {
        // TEST: when invoked, addToRefreshSlots adds the slot with the specified id to the list of soft-refreshable slots
        const slot = karmads.slots.getById(id);
        if (slot) {
            karma.slots.refresh.push(slot);
            return true;
        }
        return false;
    };

    const removeSlot = (id, bypassLog) => {
        // TEST: when invoked, removeFromRefreshSlots removes the slot with the specified id from the list of soft-refreshable slots
        const refreshSlots = karma.slots.refresh,
            refreshSlotIds = getSlotIds(refreshSlots),
            slotIndex = refreshSlotIds.indexOf(id);

        if (slotIndex > -1) {
            return refreshSlots.splice(slotIndex, 1);
        } else {
            if (!bypassLog) {
                log(`Attempted to remove an invalid slot id from karma.slots.refresh: ${id}`, {doc: 'refresh'});
            }
            return false;
        }
    };

    const buildDefaultSlots = () => {
        // TEST: karma.slots.refresh is an array containing the slots that are refreshed by karma.refresh()
        // TEST: when invoked, buildDefaultRefreshSlots adds to the list of soft-refreshable slots with the default set pulled in from the REST service config (karmaConfig.refresh.userInitiated.refreshSlotTypes)
        const refreshSlots = karma.slots.refresh = karma.slots.catalog.filter(
            (slot) => {
                return (
                    slot.slotContainer.indexOf('-lazy-') === -1 && karmads.slots.matchHandleArray(slot, karmaConfig.refresh.userInitiated.slotTypes || [])
                );
            }
        );
        return refreshSlots;
    };

    /* ============== User Initiated Refresh  ==================
     *  This code provides a hook for refreshing of the
     *  banner ads during a soft refresh of the page.
     *  Typically invoked by soft refreshing slideshows and applications.
     * =================================================================*/
    const startRefresh = (slotsOverride) => {
        // TEST: A function is available on karmads.refresh called startRefresh
        if (!karmaConfig.refresh.userInitiated.enabled) {
            // TEST: don't refresh the ads if user-initiated refresh is not enabled
            log('User initiated refresh is disabled for this page.');
            return false;
        }

        const adSlotConfigsArray = [],
            slotsToRefresh = slotsOverride || getSlotIds();
        // TEST: If an array of slot IDs are passed in to startRefresh, those slots are requested and not the slots in karma.slots.refresh
        let adSlot;

        /* Loop through slots to refresh*/
        doLoop(slotsToRefresh, (id) => {
            adSlot = karmads.slots.getById(id);
            if (adSlot) {
                // set shouldIncrementRefresh to true
                adSlot.shouldIncrementRefresh = true;
                // set refreshType to soft on user-initiated refresh slots
                adSlot.refreshType = 'soft';

                // TEST: when user-initiated refresh is triggered, any timed refresh timers for this slot are cleared
                karmads.refresh.timed.resetSlotTimer(adSlot);

                adSlotConfigsArray.push(adSlot);
            } else {
                // TEST: If an invalid slot ID is passed into startRefresh, it is ignored
                karma.log(`Cannot refresh ${id} because it does not exist`, {
                    force: 1,
                    level: 'warn'
                });
            }
        });

        if (adSlotConfigsArray.length > 0) {
            log(`refreshing these ads: ${getSlotIds(adSlotConfigsArray).toString()}`);
            // TEST: when user-initiated refresh is triggered, any timed refresh timers for this slot are restarted
            karmads.refresh.timed.check();
            // send the slots to requestQueue to request them
            karmads.requestQueue.process(adSlotConfigsArray);
            // TEST: When karma.refresh creates a valid ad request, it increments the user's page view count
            incrementPageCount();
        }
        return false;
    };

    const targeting = (slotConfigs) => {
        doLoop(slotConfigs, (slot) => {
            if (slot.refreshType) {
                // TEST: removing header bidding targeting when refreshing, slot param gets re-added
                // undefined is passed in to ensure that all values are cleared off the slot.
                // Anything stored in slot.persistentTargeting will be reset in the call to targeting.slotSetup
                karmads.targeting.clear(undefined, slot);

                // TEST: When the ads are refreshed, targeting is cleared and the params in persistentTargeting get re-added
                karmads.targeting.slotSetup(slot);

            } else {
                // TEST: All slots requested with the initial ad request have a refreshType if 'hard'
                slot.refreshType = 'hard';
            }
            // TEST: All slots requested after the initial ad request have a refreshType property that is either 'soft', 'timed', 'swap', 'lazy' or 'scroll'
            // TEST: Slots being requested have a targeting parameter 'refreshType' that is set to the slots refreshType property
            karmads.targeting.setSlot('refreshType', slot.refreshType, slot);
        });
    };

    /* Increment counter for timed refresh (including slot swap) and user-initiated refresh, and set as targeting
       Called from karmads.requestQueue.finish in order to set targeting as last step before ad request is made */
    const incrementRefreshCounter = (slots) => {
        // TEST: karmads.slots has a method called incrementRefreshCounter
        doLoop(slots, (slot) => {
            if (slot.shouldIncrementRefresh) {
                // TEST: If no refreshCount is set on a slot when incrementRefreshCounter is called, and slot.shouldIncrementRefresh is true, slot.refreshCount is set to 1
                slot.refreshCount = (typeof slot.refreshCount === 'number') ? slot.refreshCount : 0;
                // TEST: When incrementRefreshCounter is called, if slot.shouldIncrementRefresh is set to true, slot.refreshCount is incremented by 1
                slot.refreshCount += 1;
                // TEST: When incrementRefreshCounter is called, if slot.shouldIncrementRefresh is set to true, a targeting key of 'refresh' is set, with the value being the newly incremented refreshCount
                karmads.targeting.setSlot('refresh', slot.refreshCount, slot);
                // TEST: After incrementRefreshCounter is called for an array of slots, shouldIncrementRefresh is set to false on each slot
                slot.shouldIncrementRefresh = false;
            }
        });
    };

    return {
        startRefresh,
        buildDefaultSlots,
        purgeSlots,
        addSlot,
        removeSlot,
        targeting,
        incrementRefreshCounter
    };
}());