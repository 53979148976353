karmads.hb = karmads.hb || {};
karmads.hb.bidz = (function() {
    'use strict';

    let floorInflatePercentage,
        enabled;

    // TEST: bz values hit the appropriate increment (5 cent increments between $0.05 and $10.00, 10 cent increments between $10.10 and $20.00, 25 cent increments above $20)
    // See incrementizeBid for details on the structure of bid granularity maps
    const bidzGranularityMap = [
        {
            low: 5,
            high: 999,
            increment: 5
        },
        {
            low: 1000,
            high: 1999,
            increment: 10
        },
        {
            low: 2000,
            high: 999999,
            increment: 25
        }
    ];

    const init = () => {
        const daypartedPercentage = getDaypartedInflatePercentage();

        // TEST: karmaConfig.hb.bidz has a boolean property called 'enabled'
        // TEST: if Prebid and A9 are both disabled, so is bidZ
        enabled = isEnabled();

        if (!karmaConfig.hb || !karmaConfig.hb.prebid || !karmaConfig.hb.prebid.enabled) {
            enabled = false;
        }

        if (daypartedPercentage >= 0) {
            floorInflatePercentage = daypartedPercentage;
        } else {
            floorInflatePercentage = parseInt(karmaConfig.hb.bidz.percentage, 10) || 0;
        }

        log(`bidz enabled: ${enabled}; percentage: ${floorInflatePercentage}%`);
        return floorInflatePercentage;
    };

    const getDaypartedInflatePercentage = () => {
        const today = new Date(),
            winterDay = new Date(),
            todayOffset = today.getTimezoneOffset() / 60,
            overrides = karmaConfig.hb.bidz.dayPartOverride || [];

        let percentage = -1;

        // Get timezone offset for January to determine if current offset includes DST
        winterDay.setMonth(0);
        const winterDayOffset = winterDay.getTimezoneOffset() / 60,
            // If DST, subract one hour from timezone offset
            currentEasternOffset = 5 - (winterDayOffset - todayOffset);

        today.setHours(today.getHours() + (todayOffset - currentEasternOffset));

        const currentEasternHour = today.getHours();

        doLoop(overrides, (override) => {
            if (currentEasternHour >= override.start && currentEasternHour < override.end) {
                percentage = override.percentage;
            }
        });
        return percentage;
    };

    const isEnabled = () => {
        // TEST: if Prebid and A9 are both disabled, so is bidZ
        karmaConfig.hb.bidz.enabled = karmaConfig.hb.enabled && karmaConfig.hb.bidz.enabled && (!!karmaConfig.hb.a9.enabled || !!karmaConfig.hb.prebid.enabled);
        return karmaConfig.hb.bidz.enabled;
    };

    /* Price granularity map structure example. Buckets should be
       ordered from low to high as the the low for the  first
       bucket sets the floor and the high for the last bucket sets
       the ceiling.

    [
        {
            low: 10, <-- Effective floor
            high: 2000,
            increment: 10
        },
        {
            low: 2001,
            high: 5000, <-- Effective ceiling
            increment: 20
        }
    ]

    */
    const incrementizeBid = (val, map, alwaysRoundUp) => {
        // TEST: If alwaysRoundUp is true, the value should always be rounded up to the next increment per the map
        // TEST: If alwaysRoundUp is true, the value should always be rounded up even if it already falls on an increment
        // TEST: If alwaysRoundUp is false or not set, the value should always be rounded evenly to per the map
        const applyIncrement = (val, increment, alwaysRoundUp) => {
            let multiplier;
            if (alwaysRoundUp) {
                multiplier = Math.floor(val / increment) + 1;
            } else {
                multiplier = Math.round(val / increment);
            }
            return multiplier * increment;
        };

        let modifiedVal;
        const floor = map[0].low,
            ceiling = map[map.length - 1].high;

        doLoop(map, (bucket) => {
            if (val >= bucket.low && val <= bucket.high) {
                modifiedVal = applyIncrement(val, bucket.increment, alwaysRoundUp);
            }
        });

        if (!modifiedVal) {
            // TEST: When passed through incrementizeBid, bids of 0 stay at 0
            if (val === 0) {
                modifiedVal = 0;
            // TEST: When passed through incrementizeBid, anything above 0 but below the floor should be set to the floor value
            } else if (val < floor) {
                modifiedVal = floor;
            // TEST: When passed through incrementizeBid, anything above the ceiling should be set to the ceiling value
            } else if (val > ceiling) {
                modifiedVal = ceiling;
            }
        }

        return Math.floor(modifiedVal);
    };

    const determineAdXFloor = (slot) => {
        let floor = (karmads.hb.prebid.getHighestNonDealBid(slot) * 100) || 0;
        if (floor > 0) {
            floor = floor * ((floorInflatePercentage / 100) + 1);
        }
        // TEST: If an a9 bid is returned and no prebid bids are returned, the floor should be set at 015
        if (a9BidReturned(slot) && floor < 15) {
            floor = 14;
        }
        floor = Math.floor(floor * 100) / 100;
        return incrementizeBid(floor, bidzGranularityMap, true);
    };

    const convertToThreeDigitString = (number) => {
        // zero pads a number to three digits, e.g. 1 -> 001, 10 -> 010, 250 -> 250
        const string = String(number), l = string.length;
        if (l === 1) {
            return `00${string}`;
        } else if (l === 2) {
            return `0${string}`;
        }
        return string;
    };

    const a9BidReturned = (slot) => {
        if (slot.isPreroll) {
            return !!(karmaConfig.videoTargeting.amznbid && karmaConfig.videoTargeting.amznbid.length > 1);
        }
        const slotTargeting = karmads.gpt.getSlotTargeting(slot);
        return !!(slotTargeting.amznbid && slotTargeting.amznbid[0] && slotTargeting.amznbid[0].length > 1);
    };

    const setAdXFloor = (slot) => {
        // sets bidz-related targeting values: bz for the AdX fooor, bzr for a boolean
        const adXFloor = determineAdXFloor(slot),
            // TEST: if only a9 is returned, bzr=1 and bz=015
            // TEST: if no bids are returned and a9 returns a "no bid" value of 0, 1 or 2, then bzr=0 and bz=000
            bidzReturned = !adXFloor ? '0' : '1',
            slotBidzTargeting = {
                'bz': convertToThreeDigitString(adXFloor),
                'bzr': bidzReturned
            };
        if (slot.isPreroll && moduleExists('hb.video')) {
            return karmads.hb.video.setTargeting(slotBidzTargeting);
        }
        return karmads.gpt.setSlotTargetingMap(slot, slotBidzTargeting);
    };

    const setFloors = (slotConfigs) => {
        // TEST: for each slot that has header bidding enabled, a targeting parameter exists called 'bz' with a numeric value
        // TEST: bz values are not set if karmaConfig.hb.bidz.percentage === 0
        // TEST: if bidz is not enabled (karmaConfig.hb.bidz.enabled) bz and bzr are not set on any slots
        // TEST: if no bids are returned, bz=000 is passed on a slot
        // TEST: if no bids are returned, bzr=0 is passed on a slot
        // TEST: if bids are returned, bzr=1 is passed on a slot
        if (!floorInflatePercentage || !enabled) {
            return;
        }
        doLoop(slotConfigs, (slot) => {
            if (slot.isPreroll || karmads.hb.isAnyPartnerEnabledOnSlot(slot)) {
                setAdXFloor(slot);
            }
        });
    };

    return {
        incrementizeBid,
        setFloors,
        init
    };

})();