karmads.hb = karmads.hb || {};
karmads.hb.video = (function() {
    'use strict';

    const getTargeting = () => karmaConfig.videoTargeting || {};

    const getVideoId = () => {
        // TEST: karmads.hb.video.getVideoId returns and stores the value of data-video-id for the first video player on a page
        let videoId = karmaVars.videoId;

        if (!videoId) {
            // we are accounting for video players that load the correct numerical video id late, and also players that have empty strings for videoIds [see RVDEV-3702]
            const el = karmads.dom.lookup('.video-js', false, true);
            const segmentValues = karmads.dom.lookup('#page-segment-values .keyvals', false, true);
            if (el && el.dataset && el.dataset.hasOwnProperty('videoId') && /^\d*$/.test(el.dataset.videoId)) {
                karmaVars.videoId = videoId = el.dataset.videoId;
            } else if (segmentValues && segmentValues.dataset && segmentValues.dataset.hasOwnProperty('video_id') && /^\d*$/.test(segmentValues.dataset.video_id)) {
                karmaVars.videoId = videoId = segmentValues.dataset.video_id;
            }
        }

        return videoId;
    };

    const setTargeting = (targetingObj) => {
        // TEST: karmads.hb.video has a method called setTargeting that will add kvs to karma.vars.videoTargeting
        // Set returned Keys as global for Brightcove to pick up
        if (isObject(targetingObj)) {
            extend(karmaConfig.videoTargeting, targetingObj);
        }
    };

    const updateTargeting = () => {
        // TEST: karmads.hb.video has a method called updateTargeting which is aliased to karma.updateVideoTargeting()
        resetTargeting();
        if (isEnabled()) {
            doLoop(['a9', 'prebid'], (mod) => {
                if (moduleExists(`hb.${mod}`) && karmads.hb.isEnabled(mod)) {
                    const videoSlot = {isPreroll: true, hb: {a9: true, prebid: true}},
                        videoCallback = moduleExists('hb.bidz') ? () => karmads.hb.bidz.setFloors([videoSlot]) : noop;
                    karmads.hb[mod].fetchBids([videoSlot], 'video', videoCallback);
                }
            });
        }
    };

    const resetTargeting = () => {
        // TEST: karmads.hb.video has a method called resetTargeting which is aliased to karma.resetVideoTargeting()
        // TEST: karmads.hb.video.resetTargeting resets the the karma.vars.videoTargeting object to only contain the the keys in the karma.config.videoTargetingKeys
        const persistentKeys = karmaConfig.videoTargetingKeys || [];

        karmaVars.videoTargeting = karmaConfig.videoTargeting = {};
        doLoop(persistentKeys, (key) => {
            if (karmads.targeting.get(key)) {
                karmaVars.videoTargeting[key] = karmaConfig.targeting[key];
            }
        });
    };

    // TEST: karmads.hb.video has a method called fetchLateBids
    const fetchLateBids = () => {
        // After the initial GAM request has been returned, request prebid preroll bids on video pages
        if (isEnabled() && moduleExists('hb.prebid') && karmads.hb.isEnabled('prebid')) {
            const videoSlot = {isPreroll: true, hb: {a9: true, prebid: true}},
                videoCallback = moduleExists('hb.bidz') ? () => karmads.hb.bidz.setFloors([videoSlot]) : noop;
            karmads.hb.prebid.fetchBids([videoSlot], 'video', videoCallback);
        }
    };

    // TEST: karmads.hb.video has a method called isEnabled that returns true if video header bidding is enabled and a video player is on the page
    const isEnabled = () => {
        if (karmaConfig.hb.enabled && karmaConfig.hb.video.enabled && getVideoId()) {
            return true;
        }
        karmaConfig.hb.video.enabled = false;
        return false;
    };

    return {
        fetchLateBids,
        initTargeting: resetTargeting,
        isEnabled,
        getTargeting,
        getVideoId,
        setTargeting,
        resetTargeting,
        updateTargeting
    };

}());