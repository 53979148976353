karmads.partner = karmads.partner || {};
karmads.partner.ixIdentity = (function() {

    const isEnabled = () => {
        // TEST: if Prebid is enabled, karma.config.hb.ix.idEnabled is true
        // TEST: if Prebid is disabled, karma.config.hb.ix.idEnabled is false
        karmaConfig.hb = karmaConfig.hb || {};
        karmaConfig.hb.ix = karmaConfig.hb.ix || {};
        karmaConfig.hb.ix.idEnabled = karmads.hb.isEnabled('prebid');
        return karmaConfig.hb.ix.idEnabled;
    };

    const load = () => {
        if (isEnabled()) {
            // TEST: if karma.config.hb.ix.idEnabled is true, the Index Identity library is loaded
            fetchResource('//js-sec.indexww.com/ht/p/184003-52190608802424.js', () => log('Index Identity library loaded.'), () => {
                log('Failed to fetch Index Identity library.', {level: 'error', force: 1});
                karmaConfig.hb.ix.idEnabled = false;
            });
        }
    };

    return {
        load,
        isEnabled
    };

}());