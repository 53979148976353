karmads.config = karmads.config || {};
karmads.config.slots = (function() {
    'use strict';
    const slotContainers = {},
        finalSlots = [],
        slotDefinitions = [
            {
                slotContainer: 'div-gpt-leaderboard-flex',
                slotType: 'leaderboard',
                slotSizes: ['728x90', '970x90', '970x250'],
                a9: true,
                a9Sizes: [[728, 90]],
                prebidSizes: [[728, 90], [970, 250]]
            }, {
                slotContainer: 'div-gpt-leaderboard-fixed',
                slotType: 'leaderboard',
                slotSizes: ['728x90'],
                a9: true,
                a9Sizes: [[728, 90]],
                prebidSizes: [[728, 90]]
            }, {
                slotContainer: 'div-gpt-square-flex',
                slotType: 'square',
                slotSizes: ['300x250', '299x251', '300x600', '300x1050'],
                a9: true,
                a9Sizes: [[300, 250], [300, 600]],
                prebidSizes: [[300, 250], [300, 600], [300, 1050]]
            }, {
                slotContainer: 'div-gpt-square-fixed',
                slotType: 'square',
                slotSizes: ['300x250', '299x251'],
                a9: true,
                a9Sizes: [[300, 250]],
                prebidSizes: [[300, 250]]
            }, {
                slotContainer: 'div-gpt-halfPage-fixed',
                slotType: 'halfPage',
                slotSizes: ['300x600']
            }, {
                slotContainer: 'div-gpt-halfPage-flex',
                slotType: 'halfPage',
                slotSizes: ['300x250', '299x251', '300x600'],
                a9: true,
                a9Sizes: [[300, 250], [300, 600]],
                prebidSizes: [[300, 250], [300, 600]]
            }, {
                slotContainer: 'div-gpt-interstitial',
                slotType: 'interstitial',
                outOfPage: true
            }, {
                slotContainer: 'div-gpt-wallpaper',
                slotType: 'wallpaper',
                outOfPage: true
            }, {
                slotContainer: 'div-gpt-skyscraper',
                slotType: 'skyscraper',
                slotSizes: ['160x600']
            },
            /* special use slots */
            {
                slotContainer: 'div-gpt-native',
                tierSlugOverride: 'ntv',
                slotType: 'native',
                slotSizes: ['fluid']
            },
            {
                slotContainer: 'div-gpt-related',
                slotType: 'related',
                slotSizes: ['1x6']
            }, {
                slotContainer: 'div-gpt-sponsorLogo',
                slotType: 'sponsorLogo',
                slotSizes: ['122x34', '120x75']
            }, {
                slotContainer: 'div-gpt-fluid',
                slotType: 'fluid',
                slotSizes: ['fluid']
            },
            /* Begin Mobile Slots*/
            {
                slotContainer: 'div-gpt-mob-square-fixed',
                slotType: 'mob-square',
                slotSizes: ['300x250', '299x251', 'fluid'],
                a9: true,
                a9Sizes: [[300, 250]],
                prebidSizes: [[300, 250]],
                isMobile: true
            }, {
                slotContainer: 'div-gpt-mob-square-flex',
                slotType: 'mob-square',
                slotSizes: ['300x250', '299x251', '320x100', '320x200', 'fluid'],
                a9: true,
                a9Sizes: [[300, 250]],
                prebidSizes: [[300, 250]],
                isMobile: true
            }, {
                slotContainer: 'div-gpt-mob-banner-fixed',
                slotType: 'mob-banner',
                slotSizes: ['320x50', '319x51'],
                a9: true,
                a9Sizes: [[300, 250]],
                prebidSizes: [[320, 50]],
                isMobile: true
            }, {
                slotContainer: 'div-gpt-mob-banner-flex',
                slotType: 'mob-banner',
                slotSizes: ['320x50', '319x51'],
                a9: true,
                a9Sizes: [[320, 50]],
                prebidSizes: [[320, 50]],
                isMobile: true
            }, {
                slotContainer: 'div-gpt-mob-adhesive-banner-fixed',
                slotType: 'mob-adhesive-banner',
                slotSizes: ['320x50', '319x51'],
                a9: true,
                a9Sizes: [[320, 50]],
                prebidSizes: [[320, 50]],
                isMobile: true
            },
            {
                slotContainer: 'div-gpt-mob-native',
                tierSlugOverride: 'ntv',
                slotType: 'native',
                isMobile: true,
                slotSizes: ['fluid']
            },
            {
                slotContainer: 'div-gpt-mob-related',
                slotType: 'related',
                isMobile: true,
                slotSizes: ['320x175']
            }, {
                slotContainer: 'div-gpt-mob-sponsorLogo',
                slotType: 'sponsorLogo',
                isMobile: true,
                slotSizes: ['122x34', '120x75']
            }, {
                slotContainer: 'div-gpt-mob-fluid',
                slotType: 'fluid',
                isMobile: true,
                slotSizes: ['fluid']
            },
            /* Responsive Slots */
            {
                slotContainer: 'div-gpt-leaderboard-responsive',
                slotType: 'leaderboard-responsive',
                slotSizes: ['300x250'],
                a9: true,
                a9Sizes: [[300, 250]],
                responsiveA9Sizes: {
                    desktop: [[728, 90]]
                },
                responsive: true,
                responsiveSlotSizes: {
                    desktop: ['728x90']
                }
            }
        ];

    const getSlotConfig = (slotName = '') => {
        // Fetch the correct config object and assign it to the slot that exists on the page
        const slotDefs = slotDefinitions.filter((slotDef) => (slotName === slotDef.slotContainer) || slotName.indexOf(`${slotDef.slotContainer}-`) !== -1);
        if (slotDefs.length === 0) {
            log(`Slot placeholder id "${slotName}" is not a valid KARMA ad slot.`, {force: 1, level: 'error', doc: 'slot-catalog'});
            return false;
        }
        const thisSlot = {};
        // TEST: if a slot has header bidding things configured, it has an object property called 'hb'
        // TEST: all the properties in each slot's 'hb' object are listed in karma.slots.hbProps
        // TEST: the properties listed in karma.slots.hbProps do not appear on the main slot object
        doLoop(slotDefs[0], (slotProp) => {
            if (karma.slots.hbProps.indexOf(slotProp) !== -1) {
                thisSlot.hb = thisSlot.hb || {};
                thisSlot.hb[slotProp] = slotDefs[0][slotProp];
                return;
            }
            thisSlot[slotProp] = slotDefs[0][slotProp];
        });
        return thisSlot;
    };

    const buildLazySlots = (slots, site) => {
        const lazySlots = [];
        /* set up lazy loaded slots*/
        doLoop(slots, (slot) => {
            if (!slot.hasOwnProperty('site') || slot.site === site || slot.site.indexOf(site) !== -1) {
                const newSlot = {
                    type: slot.slotContainer,
                    lazyType: slot.slotContainer.replace('div-gpt', 'div-gpt-lazy')
                };
                doLoop(slot, (key) => {
                    if (karma.slots.hbProps.indexOf(key) !== -1) {
                        newSlot.hb = newSlot.hb || {};
                        newSlot.hb[key] = slot[key];
                    } else {
                        newSlot[key] = slot[key];
                    }
                });
                delete newSlot.slotContainer;
                lazySlots.push(newSlot);
            }
        });
        return lazySlots;
    };

    const build = (adSlots, site) => {
        const slotConfigs = {}; // This will house the instant and lazy slot configs for the current page
        let slotDef,
            slotName,
            tier;

        if (typeof adSlots === 'object') {
            doLoop(adSlots, (slot) => {
                tier = karmads.tiers.getFromType(slot) || null;
                slotName = slot.split(':tier')[0];
                slotDef = getSlotConfig(slotName);

                if (slotDef !== false) {
                    // set slot container
                    slotDef.slotContainer = slotName;

                    // set tier settings
                    if (tier !== null) {
                        karmads.tiers.setTierSettings(tier, slotDef, site);
                    }
                    /* Make sure it's not a duplicate */
                    if (!slotContainers.hasOwnProperty(slotDef.slotContainer)) {
                        /* add slot to list of final slots */
                        finalSlots.push(slotDef);
                        slotContainers[slotDef.slotContainer] = true;
                    }
                }
            });
        }

        /* Set adSlots and lazySlots */
        slotConfigs.adSlots = finalSlots;
        slotConfigs.lazySlots = buildLazySlots(slotDefinitions, site);

        return slotConfigs;
    };

    return {
        build
    };

}());
