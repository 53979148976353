karmads.viewability = (function() {
    'use strict';

    const supportsViewabilityDetection = typeof doc.body === 'object' && typeof doc.elementFromPoint === 'function' && typeof addEventListener === 'function';
    let browserWindowFocused = true;

    const isElementHidden = (el) => (el !== null) ? (el.offsetParent === null) : false;

    const isSlotDocked = (slot) => {
        // Determines if a slot part of a KARMA controlled docked element, so that we can force refresh
        if (!(karmaConfig.hasOwnProperty('alwaysRefreshDockedSlots') && karmaConfig.alwaysRefreshDockedSlots) || karmaConfig.docking.rail.enabled === false) {
            return false;
        }
        const getClosest = (elem, selector1, selector2) => {
            let howManyParentsToCheck = 10;
            while (howManyParentsToCheck > 0 && elem && elem !== document) {
                elem = elem.parentNode;
                if (elem === document) {
                    return false;
                }
                if (elem.matches(selector1) || elem.matches(selector2)) {
                    return true;
                }
                howManyParentsToCheck--;
            }
            return false;
        };
        return getClosest(doc.getElementById(slot.slotContainer), '.karma-sticky-stuck', '.karma-element-sticky-stuck');
    };

    const addFocusListeners = () => {
        // TEST: window listeners are added that determine whether or not the window is focused
        if (supportsViewabilityDetection) {
            win.addEventListener('blur', () => {
                browserWindowFocused = false;
            });
            win.addEventListener('focus', () => {
                browserWindowFocused = true;
            });
        } else {
            browserWindowFocused = false;
        }
    };

    const setBypassViewabilityRequirement = (slot, val) => {
        // TEST: karmads.setBypassViewabilityRequirement sets bypassViewabilityRequirement sets a slot (according to an id passed in) to the second arg passed in
        karmads.slots.getById(slot).bypassViewabilityRequirement = val;
    };

    const getViewabilityElement = (dom) => {
        let target = false;

        if (!dom) {
            return target;
        }

        if (dom.firstChild) {
            if (dom.firstChild.firstChild) {
                // TEST: if the GPT-inserted div has more than one child, the GPT-inserted div is the viewability element
                if (dom.firstChild.children.length > 1) {
                    target = dom.firstChild;
                // TEST: if the GPT-inserted div has a single child that's an iframe, the iframe is the viewability element
                } else if (dom.firstChild.firstChild.tagName === 'IFRAME') {
                    target = dom.firstChild.firstChild;
                }
            }
            if (target === false) {
                target = dom.firstChild;
            }
        }

        return target;
    };

    const isInView = (slot, bypassFocusRules) => {

        let inView = true;

        // Determines if a slot is in view. Meaning in the viewport, topmost, and in a focused tab.
        /* NOTE: bypassFocusRules should be used for in-console testing only */

        if (typeof slot === 'string') {
            slot = karmads.slots.getById(slot);
        }
        const el = slot.viewabilityDom = getViewabilityElement(karmads.dom.get(slot));

        if (!el) {
            log(`Could not get viewability element: ${ slot.slotContainer}`, {level: 'error', force: 1});
            return false;
        }

        if (!bypassFocusRules && (!browserWindowFocused || doc.visibilityState !== 'visible')) {
            inView = false;
            return inView;
        }

        // TEST: if the mobile adhesive banner is passed in to karmads.viewability.isInView, it always returns true
        if (slot.slotType === 'mob-adhesive-banner') {
            inView = true;
            return inView;
        }

        if (isSlotDocked(slot)) {
            inView =  true;
            return inView;
        }

        // Slot is refreshed if the window is in focus and bypassViewabilityRequirement is set, even if the slot is scrolled out of view
        if (slot.bypassViewabilityRequirement) {
            inView = true;
            return inView;
        }

        const rect = el.getBoundingClientRect(),
            topLeft = [Math.round(rect.left + 1), Math.round(rect.top + 1)],
            topRight = [Math.round(rect.right - 1), Math.round(rect.top + 1)],
            bottomLeft = [Math.round(rect.right - 1), Math.round(rect.bottom - 1)],
            bottomRight = [Math.round(rect.left + 1), Math.round(rect.bottom - 1)],
            coords = [topLeft, topRight, bottomLeft, bottomRight];

        let i,
            currentCoords,
            currentX,
            currentY,
            topEl;

        for (i = 0; i < 4; i++) {
            currentCoords = coords[i];
            currentX = currentCoords[0];
            currentY = currentCoords[1];
            topEl = doc.elementFromPoint(currentX, currentY);
            if (topEl !== el && !el.contains(topEl)) {
                inView = false;
                break;
            }
        }

        return inView;
    };

    const getSlotOffsetFromViewport = (slot) => {
        // For an out-of-view slot, gets the distance of the slot to the nearest edge of the viewport
        const viewportHeight = win.innerHeight,
            dom = karmads.dom.get(slot),
            offset = dom.getBoundingClientRect()['top'],
            slotHeight = dom.clientHeight;

        if ((offset + slotHeight) < 0) {
            return offset + slotHeight;
        } else if (offset > viewportHeight) {
            return offset - viewportHeight;
        }

        return 0;
    };

    const handleSpecialViewabilityRules = (slot) => {
        // TEST: bypassViewabilityRequirement is set to true for the first instance of tier 1 adx leaderboards
        // TEST: bypassViewabilityRequirement is set to false for all slots other than the first instance of tier 1 adx leaderboards
        if (karmads.slots.matchHandle(slot, 'leaderboard:tier1:1') && slot.advertiserId === 17396370) {
            slot.bypassViewabilityRequirement = true;
        }
    };

    return {
        isInView,
        isElementHidden,
        addFocusListeners,
        getSlotOffsetFromViewport,
        setBypassViewabilityRequirement,
        handleSpecialViewabilityRules
    };

}());