karmads.privacy = (function() {
    'use strict';

    // TEST: karma.config has an object property called 'usPrivacy'
    // TEST: karma.config.usPrivacy has an boolean property called 'enabled'
    // TEST: karma.config has an object property called 'euPrivacy'
    // TEST: karma.config.euPrivacy has an boolean property called 'enabled'
    // TEST: if the OptanonConsent cookie is not present, and the properties are not set manually in the page code, karma.config.usPrivacy.enabled and karma.config.euPrivacy.enabled are both false
    karmaConfig.usPrivacy = karmaConfig.usPrivacy || {enabled: false};
    karmaConfig.euPrivacy = karmaConfig.euPrivacy || {enabled: false};
    // TEST: karma.vars has a property called 'isUserInEurope'
    karmaVars.isUserInEurope = null;
    // TEST: karma.vars has a property called 'isUserInUsa'
    karmaVars.isUserInUsa = null;
    // TEST: karma.vars has an array property called euCountryCodes
    karmaVars.euCountryCodes = ['AT', 'BE', 'BG', 'CY', 'CZ', 'DE', 'DK', 'EE', 'ES', 'FI', 'FR', 'GB', 'GR', 'HR', 'HU', 'IE', 'IS', 'IT', 'LI', 'LT', 'LU', 'LV', 'MT', 'NL', 'NO', 'PL', 'PT', 'RO', 'SE', 'SI', 'SK'];

    const isEuCountryCode = (countryCode) => {
        if (!countryCode) {
            return null;
        }
        return karmaVars.euCountryCodes.indexOf(countryCode) !== -1;
    };

    const getConsentCookie = () => {
        // TEST: the user privacy consent cookie is called OptanonConsent
        let oneTrustCookie = getCookie('OptanonConsent');
        // if the OptanonConsent cookie is present
        if (oneTrustCookie) {
            // convert the cookie to an object, without lowercasing the keys
            oneTrustCookie = convertQueryStringToObject(oneTrustCookie, false);
            // log the cookie data to the KARMA log
            log(`OneTrust cookie is present, with this data: ${JSON.stringify(oneTrustCookie)}`);
            return oneTrustCookie;
        }
        // if the OptanonConsent cookie is not present, return null
        return null;
    };

    const hasUserConsented = (consentData = getConsentCookie(), otGeoLoc = getOneTrustGeoLocation()) => {
        // TEST: if the user privacy consent cookie is present, and the 'groups' parameter of that cookie contains '4:0', the user has opted out of advertising-related cookies
        if (consentData && consentData.hasOwnProperty('groups')) {
            return !(consentData.groups.indexOf('4:0') !== -1);
        }
        // TEST: if the user is in Europe, and the OptanonConsent cookie is  missing, or it doesn't contain a groups parameter, karma.config.euPrivacy.enabled is true
        // TEST: if the user is not in Europe, and the OptanonConsent cookie is  missing, or it doesn't contain a groups parameter, karma.config.usPrivacy.enabled is false
        return !isUserInEurope(otGeoLoc);
    };

    const getOneTrustGeoLocation = () => {
        if (win.otStubData && typeof window.otStubData.userLocation === 'object') {
            return win.otStubData.userLocation;
        }
        return null;
    };

    const isUserInUsa = (otGeoLoc = getOneTrustGeoLocation()) => {
        // TEST: if otStubData.userLocation returns a US country code, karma.vars.isUserInUsa is set to true
        // TEST: if otStubData.userLocation returns a non-US country code, karma.vars.isUserInUsa is set to false
        if (otGeoLoc && otGeoLoc.hasOwnProperty('country')) {
            karmaVars.isUserInUsa = (otGeoLoc.country === 'US');
        }
        return karmaVars.isUserInUsa;
    };

    const isUserInEurope = (otGeoLoc = getOneTrustGeoLocation()) => {
        // TEST: if otStubData.userLocation returns a European country code, karma.vars.isUserInEurope is set to true
        // TEST: if OtStubData.userLocation returns a non-European country code, karma.vars.isUserInEurope is set to false
        if (otGeoLoc && otGeoLoc.hasOwnProperty('country')) {
            karmaVars.isUserInEurope = isEuCountryCode(otGeoLoc.country);
        }
        return karmaVars.isUserInEurope;
    };

    const getOneTrustGroups = (consentData = getConsentCookie()) => {
        let otGroups = null;
        tryCatch(() => {
            otGroups = consentData.groups.split(',').filter((v) => v.charAt(0) === '4' && v.charAt(1) === ':');
        }, () => log('Something went wrong when trying to check the groups param of the Onetrust consent cookie', {force: 1}));
        return otGroups;
    };

    const setTargeting = (consentData = getConsentCookie(), otGeoLoc = getOneTrustGeoLocation(), otAlertBoxClosed = getCookie('OptanonAlertBoxClosed') || null) => {

        karmads.targeting.set('otabc', otAlertBoxClosed ? 1 : 0);

        if (karmaConfig.euPrivacy.enabled) {
            // TEST: if karmaConfig.euPrivacy.enabled is true, a page targeting key/value pair of npa=1 is present
            karmads.targeting.set('npa', 1);
        } else {
            // TEST: if karmaConfig.euPrivacy.enabled is false, a page targeting key/value pair of npa=1 is not present
            karmads.targeting.clear('npa');
        }
        if (karmaConfig.usPrivacy.enabled) {
            // TEST: if karmaConfig.usPrivacy.enabled is true, a page targeting key/value pair of rdp=1 is present
            karmads.targeting.set('rdp', 1);
        } else {
            // TEST: if karmaConfig.usPrivacy.enabled is false, a page targeting key/value pair of rdp=1 is not present
            karmads.targeting.clear('rdp');
        }

        if (consentData) {
            // TEST: if the OptanonConsent cookie is present and has a groups parameter, a targeting value of otgrp exists and is set to the value of the groups parameter
            if (consentData.hasOwnProperty('groups')) {
                karmads.targeting.set('otgrp', getOneTrustGroups(consentData));
                // TEST: if the OptanonConsent cookie is present and does not have a groups parameter, a targeting value of otgrp exists and is set to null
            } else {
                karmads.targeting.set('otgrp', null);
            }

            // TEST: When isGpcEnabled is NOT present or set to 0 in the OptanonConsent cookie, do NOT set a targeting value of otgpc
            if (consentData.hasOwnProperty('isGpcEnabled')) {
                if (coerceType(consentData.isGpcEnabled) === 1) {
                    // TEST: When isGpcEnabled is set to 1 in the OptanonConsent cookie, set a targeting value of otgpc=1
                    karmads.targeting.set('otgpc', 1);
                }
            }
        }

        if (otGeoLoc && otGeoLoc.hasOwnProperty('country')) {
            // TEST: if OtStubData.userLocation returns a US country code, a targeting value of 'otgeo' exists and is set to 2
            if (otGeoLoc.country === 'US') {
                karmads.targeting.set('otgeo', 2);
                // TEST: if OtStubData.userLocation returns a European country code, a targeting value of 'otgeo' exists and is set to 1
            } else if (isEuCountryCode(otGeoLoc.country)) {
                karmads.targeting.set('otgeo', 1);
                // TEST: if OtStubData.userLocation returns a non-US, non-Euroepan country code, a targeting value of 'otgeo' exists and is set to 0
            } else {
                karmads.targeting.set('otgeo', 0);
            }
        }
    };

    const partnerLogic = () => {

        // TEST: if the URL param adPrivacyPartnerOverride=true is present, Index, Krux, and A9 are not disabled by the privacy module
        if (
            (!karmaVars.url.hasOwnProperty('adprivacypartneroverride') || karmaVars.url.adprivacypartneroverride !== 'true') &&
            (karmaConfig.usPrivacy.enabled || karmaConfig.euPrivacy.enabled)
        ) {

            if ((karmaConfig.onPageSettings.config.usPrivacy && karmaConfig.onPageSettings.config.usPrivacy.enabled) ||
                karmaConfig.euPrivacy.enabled) {
                // TEST: if the URL param adPrivacyPartnerOverride is not present and the site has manually set karma.config.usPrivacy to true, A9 is disabled
                // TEST: if the URL param adPrivacyPartnerOverride is not present and if euPrivacy is enabled, A9 is disabled
                karmaConfig.hb.a9.enabled = false;
            }

            if (moduleExists('hb.prebid') && karmads.hb.prebid.isEnabled()) {
                if (karmaConfig.usPrivacy.enabled) {
                    // TEST: if the URL param adPrivacyPartnerOverride is not present and if the user has opted out of advertising-related cookies in US, Prebid passes the consent string
                    karmads.hb.prebid.setUSPrivacy(getCcpaConsentString());
                }

                if (karmaConfig.euPrivacy.enabled) {
                    // TEST: if the URL param adPrivacyPartnerOverride is not present and if the user has opted out of advertising-related cookies in EU, Prebid is disabled
                    karmaConfig.hb.prebid.enabled = false;
                }
            }
        }

        if (moduleExists('hb.a9') && karmaConfig.hb.a9.enabled) {
            // TEST: every time we check the privacy status, A9 is re-initialized with the new consent string
            karmads.hb.a9.init();
        }
    };

    const getCcpaConsentString = () => {
        // TEST: karmads.privacy has a method called getCcpaConsentString
        // TEST: the first character of karma.vars.ccpaConsentString is 1
        const ccpaConsentString = ['1'];
        if (isUserInUsa()) {
            // TEST: if the user is in the USA, the second character of karma.vars.ccpaConsentString is Y
            ccpaConsentString.push('Y');
            // TEST: if the user is in the USA and the user has opted out of ad-related targeting, the third character of karma.vars.ccpaConsentString is Y
            if (!hasUserConsented()) {
                ccpaConsentString.push('Y');
                // TEST: if the user is in the USA and the user has NOT opted out of ad-related targeting, the third character of karma.vars.ccpaConsentString is N
            } else {
                ccpaConsentString.push('N');
            }
        } else {
            // TEST: if the user is NOT in the USA, the second and third characters of karma.vars.ccpaConsentString are both '-'
            ccpaConsentString.push('-', '-');
        }
        // TEST: if a9 is enabled, karma.vars has a string property named 'ccpaConsentString'
        karmaVars.ccpaConsentString = ccpaConsentString.join('');
        return karmaVars.ccpaConsentString;
    };

    const check = () => {
        // TEST: karmads.privacy has a method called check
        const consentData = getConsentCookie(),
            otGeoLoc = getOneTrustGeoLocation(),
            isThisUserInEurope = isUserInEurope(otGeoLoc),
            otAlertBoxClosed = getCookie('OptanonAlertBoxClosed') || null,
            otGpcEnabled = consentData ? !!(consentData.hasOwnProperty('isGpcEnabled') && coerceType(consentData.isGpcEnabled) === 1) : null;

        // TEST: if the user is in Europe, and the OptanonConsent cookie is not present, karma.config.euPrivacy.enabled is true
        if (isThisUserInEurope) {
            karmaConfig.euPrivacy.enabled = true;
        }

        if (consentData) {
            const hasUserOptedOut = !hasUserConsented(consentData);
            // TEST: if the OptanonConsent cookie is present, and the user has NOT opted out of advertising-related cookies, karma.config.usPrivacy.enabled and karma.config.euPrivacy.enabled are both false
            // TEST: if the OptanonConsent cookie is present, the user is in the US, they've opted out of advertising-related cookies, and the OptanonConsent cookie contains isGpcEnabled=1, karma.config.usPrivacy.enabled is true
            // TEST: if the OptanonConsent cookie is present, the user is in the US, they've opted out of advertising-related cookies, the OptanonConsent cookie does NOT contain isGpcEnabled=1, and the OptanonAlertBox cookie is set, karma.config.usPrivacy.enabled is true
            // TEST: if the OptanonConsent cookie is present, the user is in the US, they've opted out of advertising-related cookies, the OptanonConsent cookie does NOT contain isGpcEnabled=1, and the OptanonAlertBox cookie is NOT set, karma.config.usPrivacy.enabled is false
            karmaConfig.usPrivacy.enabled = !!(isUserInUsa(otGeoLoc) && hasUserOptedOut && (otGpcEnabled || otAlertBoxClosed !== null));
            // TEST: if the OptanonConsent cookie is present, the user is in Europe, and they've opted out of advertising-related cookies, karma.config.euPrivacy.enabled is true
            karmaConfig.euPrivacy.enabled = !!(isThisUserInEurope && hasUserOptedOut);
        }

        // TEST: if karma.config.euPrivacy.enabled is true, we turn on non-personalized ads mode in GAM
        karmads.gpt.setNonPersonalizedAds(karmaConfig.euPrivacy.enabled);

        // TEST: if karma.config.usPrivacy.enabled is true, we turn on restricted data processing mode in GAM
        karmads.gpt.restrictDataProcessing(karmaConfig.usPrivacy.enabled);

        setTargeting(consentData, otGeoLoc, otAlertBoxClosed);
        partnerLogic();
    };

    return {
        check,
        getCcpaConsentString,
        getOneTrustGroups,
        hasUserConsented
    };

}());