karmads.utilities = (function() {
    'use strict';

    const hostname = win.location.hostname,
        hostParts = hostname.split('.'),
        pathname = win.location.pathname;

    const getAdUnitFormat = () => {
        const karmaSite = karmaConfig.site;
        if (karmaSite) {
            // TEST: if karma.config.site starts with ddm. or revshare., karma.config.adUnitFormat is ddm
            if (karmaSite.indexOf('ddm.') === 0 || karmaSite.indexOf('revshare.') === 0) {
                karmaConfig.adUnitFormat = 'ddm';
            }
            // TEST: if karma.config.site contains .mdp., karma.config.adUnitFormat is mdp
            if (karmaSite.indexOf('.mdp.') !== -1) {
                karmaConfig.adUnitFormat = 'mdp';
            }
        }
        // TEST: if the on-page config does not contain adUnitFormat and karma.config.site is not a valid .mdp., ddm. or revshare. ad unit, karma.config.adUnitFormat is ddm and getAdUnitFormat returns ddm
        return karmaConfig.adUnitFormat || 'ddm';
    };

    const isLegacyAdUnitFormat = () => getAdUnitFormat() === 'mdp';

    const moduleExists = (module) => {
        // note: as written, this only supports 1 level of nesting. if we ever change that, we'll need to modify this to follow
        if (module.indexOf('.') !== -1) {
            // if it's a nested module, check that both parent and child exist on karmads
            const modules = module.split('.'),
                parentModule = modules[0],
                childModule = modules[1];
            return !!(karmads.hasOwnProperty(parentModule) && karmads[parentModule].hasOwnProperty(childModule) && isObject(karmads[parentModule][childModule]) && Object.keys(karmads[parentModule][childModule]).length);
        }
        // otherwise, check that the module exists on karmads
        return !!(karmads.hasOwnProperty(module) && isObject(karmads[module]) && Object.keys(karmads[module]).length);
    };

    // TEST: karmads.utilities has a method called getHostname
    const getHostname = () => {
        // TEST: by default, getHostname returns the second to last bit of the hostname
        const siteName = hostParts.slice(-2, -1)[0];

        switch (siteName) {
        // TEST: if the 2nd to last bit of the hostname is .com or .co, getHostname returns the 3rd to last bit of the hostname instead
        case 'com':
            return hostParts.slice(-3, -1)[0];
        case 'themarthablog':
            // TEST: on The Martha Blog, getHostname returns 'marthastewart'
            // themarthablog.com
            return 'marthastewart';
        }
        return siteName || 'default';
    };

    // TEST: karmads.utilities has a method called getSite
    const getSite = (site = getHostname()) => {
        if (!isLegacyAdUnitFormat()) {
            return site;
        }
        // given a host name (if no host name is provided, we'll grab it from the URL using getHostname), getSite returns the correct ad-related abbreviation
        // TODO (post-Oxygen): clean this up
        const siteMap = {
            'allpeoplequilt': 'apq',
            'agriculture': 'ag',
            'allrecipes': 'ar',
            'bestlifeonline': 'bestlife',
            'cookinglight': 'ckl',
            'dailypaws': 'dp',
            'eatingwell': 'ew',
            'ew': 'enw',
            'foodandwine': 'fw',
            'health': 'hlt',
            'instyle': 'ins',
            'midwestliving': 'mwl',
            'marthastewart': 'mslo',
            'myrecipes': 'mre',
            'people': 'peo',
            'peopleenespanol': 'pesp',
            'realsimple': 'rsm',
            'southernliving': 'sol',
            'travelandleisure': 'tl',
            'woodmagazine': 'wood'
        };

        // if we're returning an abbreviation and  there's a hostname->abbreviation map, use that
        if (siteMap.hasOwnProperty(site)) {
            return siteMap[site];
        }

        return site;
    };

    // TODO: is this utility really needed? It's only used in one place in the code (_config.js)
    // TEST: karmads.utilities has a method called isValidEnv
    const isValidEnv = (envString, validEnvs =  ['local', 'dev', 'test', 'www']) => {
        // TEST: given a string, isValidEnv determines whether or not it's valid
        return (typeof envString === 'string') ? validEnvs.indexOf(envString) !== -1 : true;
    };

    const getEnv = () => {
        const adTestEnvParam = karmads.urlVars.get('adTestEnv'),
            adTestEnv = isValidEnv(adTestEnvParam) ? adTestEnvParam : false;
        // TEST: if ?adtestenv=local|dev|test|www is in the URL, getEnv uses that parameter to set the environment
        if (adTestEnv) {
            return adTestEnv;
        }
        // TEST: use 'www' if adTestEnv is not present in the URL
        return 'www';
    };

    const fetchResource = (url, resolve = noop, reject = noop, type = 'script', props = false) => {
        // TEST: If type is passed, that file type is requested
        // TEST: If type is not passed, a script file is requested
        // TEST: if we pass a URL to fetchResource, the URL will be appended to the document head
        let attributes = {src: url};
        attributes.onload = attributes.onreadystatechange = function() {
            const rs = this.readyState;
            if (rs && rs !== 'complete' && rs !== 'loaded') {
                reject();
            }
            // TEST: if we pass resolve callback to fetchResource, and the browser is not IE 9 or 10, the callback will be run if the request is successsful
            // TEST: if we pass reject callback to fetchResource, and the browser is not IE 9 or 10, the callback will be run if the request errors out
            try {
                resolve();
            } catch (e) {
                reject(e);
            }
        };
        attributes.onerror = reject;
        if (props) {
            attributes = extend(attributes, props);
        }

        karmads.dom.create(type, karmaVars.els.head, attributes);
    };

    // TODO (audit): let's check and see if there's a less verbose/more performant way to do this
    const fetchJson = (url, callback, error) => {
        let hasError = false;

        // TEST: karmads.utilities has a method called fetchJson
        // TEST: callback argument function is triggered upon request success
        // TEST: error argument function is triggered if request fails

        const errorHandler = () => {
            hasError = true;
            log('XMLHttpRequest initialization error', {level: 'error', force: 1});
            if (typeof error === 'function') {
                error();
            }
        };

        const request = new window.XMLHttpRequest();
        // Check if the XMLHttpRequest object has a "withCredentials" property.
        // "withCredentials" only exists on XMLHTTPRequest2 objects.
        if ('withCredentials' in request) {
            request.open('GET', url, true);
            request.onloadend = function() {
                if (request.status !== 404) {
                    const res = this.responseText !== '' ? JSON.parse(this.responseText) : {};
                    if (res && Object.keys(res).length === 0 && res.constructor === Object) {
                        errorHandler();
                    } else {
                        callback(res, request.getAllResponseHeaders());
                    }
                } else {
                    errorHandler();
                }
            };
            if (!hasError) {
                request.onerror = errorHandler;
                request.send();
            }
        } else {
            errorHandler();
        }
    };

    const getKarmaScript = (isReloaded = false) => {
        const scripts = karmads.dom.lookup('script[src*="karma.mdpcdn.com"]', true, true),
            // TEST: The official KARMA script path is the first js file from karma.mdpcdn.com that matches karma.*.js
            matchPattern = /^http(?:s)?:\/\/([^.]*)\.?karma\.mdpcdn\.com[:9]*\/service\/(js|js-min)\/(karma(.*))$/;
        let currentScriptMatch,
            currentScript,
            l = scripts.length;

        if (karmaVars.scriptPath && karmaVars.scriptPath.length > 0) {
            return karmaVars.scriptPath.match(matchPattern);
        }

        // TEST: there should be at least one match for a KARMA file on the page
        while (l--) {
            currentScript = scripts[l];
            currentScriptMatch = currentScript.src.match(matchPattern);
            // TEST: If KARMA has been reloaded, getKarmaScript returns the script with the data-reloaded="true" attribute
            if (currentScriptMatch !== null && (isReloaded === (currentScript.dataset && currentScript.dataset.reloaded === 'true'))) {
                return currentScriptMatch;
            }
        }

        return null;
    };

    const createEvent = (name) => new Event(name);

    const incrementPageCount = () => {
        // increment the page count
        const pv = karmaConfig.storedTargeting.pv++;
        // set the updated page count in local storage
        localStorage.setItem('mdpAdPageCount', pv);
        // set the 'pv' targeting value to match the incremented page count
        karmads.targeting.set('pv', pv);
        return pv;
    };

    const setAndCheckLocalStorageVars = () => {
        const expirationMs = 43200000, // 12 hour expiration
            currentTime = Date.now(),
            storedTargeting = karmaConfig.storedTargeting;
        let expired = false;

        if (!karmaVars.isLocalStorageAvailable) {
            storedTargeting.pv = 0;
            log('localStorage not available ... setting karma.config.storedTargeting.pv to 0.');
        } else {
            // if no session is set
            if (!localStorage.mdpAdSessionTTL) {
                // TEST: the browser's localStorage has an item called mdpAdSessionTTL that's set to the timestamp of 12 hours in the future or less
                localStorage.setItem('mdpAdSessionTTL', currentTime + expirationMs);
            }

            // expire the values if the current time is past the TTL
            if (currentTime > localStorage.mdpAdSessionTTL) {
                expired = true;
                localStorage.removeItem('mdpAdRefHub');
                localStorage.setItem('mdpAdSessionTTL', currentTime + expirationMs);
            }

            // TEST: karma.config.storedTargeting has a numeric property called 'pv'
            // retrieve the page count from local storage
            storedTargeting.pv = (expired || localStorage.getItem('mdpAdPageCount') === null) ? 0 : parseInt(localStorage.mdpAdPageCount, 10);
            // increment the page count
            storedTargeting.pv++;

            // TEST: if ref_hub is set as a targeting value (and ref_hub is in the URL) or in local storage, karma.config.storedTargeting has a string property called 'ref_hub'
            storedTargeting.ref_hub = karmaVars.url['ref_hub'] ? karmaConfig.targeting.ref_hub : localStorage.mdpAdRefHub;

            // TEST: the browser's localStorage has an item called mdpAdPageCount that matches the pv targeting value and karma.config.storedTargeting.pv
            localStorage.setItem('mdpAdPageCount', storedTargeting.pv);
            // TEST: if karma.config.storedTargeting.ref_hub is set and the URL does not contain referringId, the browser's localStorage has an item called mdpAdRefHub that matches the ref_hub targeting value and karma.config.storedTargeting.ref_hub
            if (storedTargeting.ref_hub && !karmaVars.url['referringId']) {
                localStorage.setItem('mdpAdRefHub', storedTargeting.ref_hub);
            }
        }
    };

    const coerceType = (str) => {
        if (typeof str === 'string') {
            // TEST: if a numeric string is passed to coerceType, it will return a number
            if (str.match(/^[0-9]+$/)) {
                return convertStringToNumber(str);
            // TEST: if a "true" or "false" string is passed to coerceType, it will return a boolean
            } else {
                return convertStringToBoolean(str);
            }
        }
        return str;
    };

    const convertStringToBoolean = (str) => {
        // TEST: if we pass 'true' or 'false' as a string to convertStringToBoolean, the returned result is a boolean
        // TEST: if we pass a string other than 'true' or 'false' convertStringToBoolean, the returned result remains a string
        if ((typeof str === 'string' && (str === 'true' || str === 'false' || str === '')) || str === undefined || str === null) {
            return (str === 'true') || false;
        }
        return str;
    };

    // TEST: if we pass a string to karmads.utilities.convertStringToNumber, the returned result is either a number or NaN
    const convertStringToNumber = (str) => parseInt(str, 10);

    const extend = (obj1 = {}, obj2) => {
        if (obj2) {
            // TEST: if we pass two objects to karmads.utilities.extend, the properties of the second will be copied on top of the first
            doLoop(obj2, (p) => {
                try {
                    // Property in destination object set; update its value.
                    if (obj2[p].constructor === Object) {
                        obj1[p] = extend(obj1[p], obj2[p]);
                    } else {
                        obj1[p] = obj2[p];

                    }

                } catch (e) {
                    // Property in destination object not set; create it and set its value.
                    obj1[p] = obj2[p];

                }
            });
        }
        return obj1;
    };

    const clone = (obj) => {
        // TODO (audit): can we use spread operators to simplify this code?
        // TEST: if we pass an object or array to karmads.utilities.clone, a copy is created with all the properties of the original
        let copy = obj;
        if (isObject(obj)) {
            copy = Object.create(obj.constructor.prototype);
            doLoop(obj, (key) => {
                copy[key] = clone(obj[key]);
            });
        }
        return copy;
    };

    const isObject = (x) => {
        // TEST: if we pass an array to karmads.utilities.isObject, it will return false
        // TEST: if we pass a non-array object to karmads.utilities.isObject, it will return true
        return (x !== null && typeof x === 'object' && !Array.isArray(x) && !NodeList.prototype.isPrototypeOf(x));
    };

    const isHomepage = () => {
        // TEST: on pages with no pathname and no special subdomain, karmads.utilities.isHomepage returns true
        // TEST: on pages with a pathname, karmads.utilities.isHomepage returns false
        // TEST: on pages with a special subdomain, karmads.utilities.isHomepage returns false
        return (pathname === '/' && (/^www|dev|test|allrecipes|local|bestlifeonline|eatthis|eatingwell$/.test(hostParts[0]) !== null));
    };

    // TODO (audit): is there a reason this is outside a function call?
    // Setting isLocalStorageAvailible on karma.vars once.
    // TEST: if the browser does support local storage, karma.vars.isLocalStorageAvailable returns true
    // TEST: if the browser does not support local storage, karma.vars.isLocalStorageAvailable returns false
    const testKey = 'mdpTest',
        storage = win.localStorage;
    try {
        storage.setItem(testKey, '1');
        storage.removeItem(testKey);
        karmaVars.isLocalStorageAvailable = true;
    } catch (error) {
        karmaVars.isLocalStorageAvailable = false;
    }

    const cleanCharacters = (string) => {
        let cleanString = '',
            argReturnedAsString = false;
        // TODO (audit): make log function, then refactor this to return early
        if (string === null || string === undefined) {
            cleanString = '';
        } else if (Array.isArray(string)) {
            cleanString = [];
            /* start recursion */
            doLoop(string, (v, k) => {
                cleanString[k] = cleanCharacters(v);
            });
        } else if (typeof string === 'string') {
            // TEST: if any targeting values are passed in that contain special characters, they're stripped
            cleanString = string.replace(/[?'"=!#+*~;^()<>[\],&\s]/gi, '');
            argReturnedAsString = true;
        } else if (typeof string === 'number' || typeof string === 'boolean') {
            // TEST: cleanCharacters converts booleans and numbers to strings
            cleanString = String(string);
        }
        if (argReturnedAsString && string !== cleanString) {
            log(`Targeting value "${string}" was incorrectly formatted, so KARMA changed it to "${cleanString}"`, {level: 'warn', force: 1});
        }
        return cleanString;
    };

    /* debounce - for throttling functions so they only run once per wait period, even if the are triggered more frequently (e.g. onscroll listeners) */
    const debounce = (func, wait, immediate) => {
        let timeout;
        return function() {
            const context = this,
                args = arguments,
                callNow = immediate && !timeout;

            // eslint-disable-next-line func-style
            function later() {
                timeout = null;
                if (!immediate) {
                    func.apply(context, args);
                }
            }

            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) {
                func.apply(context, args);
            }
        };
    };

    /* Breakpoint matching based on configured breakpoints (karmaConfig.breakpoints) */
    // TEST: karmads.utilities has a method called getBreakpoint()
    // TEST: karmads.utilities.getBreakpoint returns a string
    const getBreakpoint = (breakpointsArray) => {
        const breakpointsConfig = karmaConfig.breakpoints;
        let breakpointsToCheck,
            breakpointMatch = 'base',
            highest = 0;

        // Create base breakpoint ad 0 width
        if (!breakpointsConfig.hasOwnProperty('base')) {
            breakpointsConfig.base = 0;
        }

        // getBreakpoint accepts an array of breakpoints that it wants to match against
        // TEST: If passed an array of non-matched breakpoints is passed to getBreakpoint, 'base' is returned
        // TEST: If an array containing the current breakpoint is passed to getBreakpoint, that array is returned
        // TEST: If no arguments are passed into getBreakpoint, the current breakpoint is returned
        breakpointsToCheck = Object.keys(breakpointsConfig);
        if (breakpointsArray) {
            breakpointsToCheck = breakpointsArray.filter((breakpoint) => breakpointsConfig.hasOwnProperty(breakpoint));
        }

        const filteredBreakpoints = breakpointsToCheck.filter((a) => breakpointsConfig[a] <= win.innerWidth);

        doLoop(filteredBreakpoints, (breakpoint) => {
            if (breakpointsConfig[breakpoint] >= highest) {
                highest = breakpointsConfig[breakpoint];
                breakpointMatch = breakpoint;
            }
        });

        return breakpointMatch;
    };

    // TEST: karmads.utilities has a method called tryCatch
    // TEST: failing functions or non-function arguments passed into tryCatch fail gracefully
    // TODO (audit): do we really need this? Is it less code than just using try/catch?
    const tryCatch = (fn, errFn) => {
        try {
            fn();
        } catch (e) {
            if (errFn) {
                errFn(e);
            } else {
                win.console.error(e);
            }
        }
    };

    // TEST: karmads.utilities has a method called randomPercentage
    // TEST: randomPercentage returns true x percent of the time where x is percentage
    const randomPercentage = (percentage) => Math.random() < (percentage / 100);

    // TEST: karmads.utilities has a method called doLoop
    const doLoop = (iter, fn) => {
        // doLoop loops over an array or NodeList and applies the given function to each item. The function will receive two arguments: 1: the item, 2: the item index
        iter = isObject(iter) ? Object.keys(iter) : iter;
        iter.forEach(fn);
    };

    // TEST: karmads.utilities has a method called getCookie
    const getCookie = (cname) => {
        if (!navigator.cookieEnabled) {
            log('Cookies are disabled in your browser', {force: 1, level: 'warn'});
            return false;
        }
        // TEST: getCookie returns a cookie value given a cookieName
        const name = `${cname}=`,
            decodedCookie = decodeURIComponent(doc.cookie),
            cookieArray = decodedCookie.split(';');
        let cookieMatch;
        doLoop(cookieArray, (thisCookie) => {
            while (thisCookie.charAt(0) === ' ') {
                thisCookie = thisCookie.substring(1);
            }
            if (thisCookie.indexOf(name) === 0) {
                cookieMatch = thisCookie.substring(name.length, thisCookie.length);
            }
        });
        return cookieMatch;
    };

    const convertQueryStringToObject = (queryString, lowerCaseKeys) => {
        queryString = decodeURIComponent(queryString);
        // if we don't pass the second param (lowerCaseKeys), we'll assume that we want the keys lowercased
        lowerCaseKeys = (lowerCaseKeys !== undefined) ? lowerCaseKeys : true;
        const obj = {};
        queryString.replace(/[?&]?([^=&]+)=([^&]*)/gi, (m, key, value) => {
            if (lowerCaseKeys) {
                obj[key.toLowerCase()] = value;
            } else {
                obj[key] = value;
            }
        });
        return obj;
    };

    // TEST: karmads.utilites has a function called noop that does nothing
    const noop = () => {
        /* Do nothing */
    };

    // TODO (audit): do we need a tests module in KARMA?
    const runTests = (paramsObj = {}) => { // {level: 'critical', module: 'hb.bidz', method: 'isEnabled', ticket: 'RVDEV-2010'}
        if (!paramsObj.hasOwnProperty('urlTriggered')) {
            const defaultSettings = {level: 'critical'},
                params = Object.assign(defaultSettings, paramsObj);

            karmaVars.testParams = params;
        }
        const env = karmaVars.environment,
            isLocal = (env === 'local'),
            isNotProd = (env !== 'www');
        karmads.gpt.queuePush(() => {
            karmads.dom.create(
                'script',
                karmaVars.els.head,
                {src: `https://${(isNotProd ? `${env}.` : '')}karma.mdpcdn.com${(isLocal ? ':9999' : '')}/tests/js/karma.tests.bookmarklet.js`}
            );
        });
    };

    return {
        // if you add a method here, make sure you add it down below and in eslintrc
        cleanCharacters,
        clone,
        coerceType,
        convertQueryStringToObject,
        convertStringToBoolean,
        convertStringToNumber,
        createEvent,
        debounce,
        doLoop,
        extend,
        fetchJson,
        fetchResource,
        getAdUnitFormat,
        getBreakpoint,
        getCookie,
        getEnv,
        getKarmaScript,
        getHostname,
        getSite,
        incrementPageCount,
        isHomepage,
        isLegacyAdUnitFormat,
        isObject,
        isValidEnv,
        moduleExists,
        noop,
        runTests,
        randomPercentage,
        setAndCheckLocalStorageVars,
        tryCatch
    };
}());

// scope utility functions so that they can be called directly
/* eslint-disable no-unused-vars */
const {
    cleanCharacters,
    clone,
    coerceType,
    convertQueryStringToObject,
    convertStringToBoolean,
    convertStringToNumber,
    createEvent,
    debounce,
    doLoop,
    extend,
    fetchJson,
    fetchResource,
    getAdUnitFormat,
    getBreakpoint,
    getCookie,
    getEnv,
    getKarmaScript,
    getHostname,
    getSite,
    incrementPageCount,
    isHomepage,
    isLegacyAdUnitFormat,
    isObject,
    isValidEnv,
    moduleExists,
    noop,
    randomPercentage,
    runTests,
    setAndCheckLocalStorageVars,
    tryCatch
} = karmads.utilities;
/* eslint-enable no-unused-vars */


