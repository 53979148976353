karmads.reporting = (function() {
    const refreshedHeavyAdSlots = [];
    let reportingEnabledCheckDone = false,
        reportingDisabledMsg = false,
        sampleRateRoulette;

    const init = () => {
        // TEST: karma.config has a boolean property called refreshHeavyAds
        karmaConfig.refreshHeavyAds = karmaConfig.refreshHeavyAds || true;

        // TEST: karma.config has an integer property called reportingSampleRate
        karmaConfig.reportingSampleRate = karmaConfig.reportingSampleRate || 0;
        sampleRateRoulette = randomPercentage(karmaConfig.reportingSampleRate);
        // instant slot information is reported
        instantSlots();

        // window is listening for heavy ad intervention
        addHeavyAdListener();
    };

    const addHeavyAdListener = () => {
        // TEST: the window is listening for Chrome's heavy ad intervention to fire
        win.addEventListener('message', (e) => {
            if (typeof e.data === 'string' && e.data.indexOf('HeavyAdIntervention') !== -1) {
                try {
                    const data = JSON.parse(e.data);
                    // report heavy ad intervention
                    reportHeavyAdAndRefresh(data);
                } catch (err) {
                    log(`${err.toString()} while trying to process HeavyAdIntervention. e.data: ${e.data}`, {level: 'error', force: 1});
                }
            }
        }, false);
    };

    const reportHeavyAdAndRefresh = (event) => {
        if (typeof event.slot !== 'string' || !karmads.slots.getById(`div-gpt-${event.slot}`)) {
            return false;
        }

        // TEST: if a heavy ad slot is detected, we send a report to Segment with the creative id
        segmentReport('chromeHeavyAdIntervention', event.creativeId || false, 'gamCreativeTracking');

        if (karmaConfig.refreshHeavyAds) {
            // TEST: if a heavy ad slot is detected, and karma.config.refreshHeavyAds is set to true, we force refresh the ad slot in question
            // todo: expose on karma.slots.
            if (refreshedHeavyAdSlots.indexOf(event.slot) === -1) {
                refreshedHeavyAdSlots.push(event.slot);
                window.karma.refresh([`div-gpt-${event.slot}`]);
            } else {
                log(`Chrome Heavy Ad Intervention blocked the ad in ${event.slot} but the slot has already been force-refreshed once. Not refreshing again.`);
            }
        }
    };

    // TEST: karmads.reporting has a method called reportType which is a proxy of isEnabled
    // TEST: If karmaConfig.reporting is not set to 'ga' or 'segment' karmads.reporting.reportType() returns false
    const isEnabled = () => {
        // TEST: karma.config has a property called reportingEnabled that can either be a boolean or a string
        const reportingEnabled = karmaConfig.reportingEnabled;

        // TEST: if karmaConfig.reportingEnabled is not set to 'ga' or 'segment', no data is sent
        if (reportingEnabled !== 'ga' && reportingEnabled !== 'segment') {
            if (!reportingEnabledCheckDone) {
                log('Reporting is misconfigured or not enabled for this site. No reports sent.');
                karmaConfig.reportingEnabled = false;
            }
            reportingEnabledCheckDone = true;
            return false;
        }

        // if we are reporting to segment, return 'segment'
        if (reportingEnabled === 'segment') {
            return reportingEnabled;
        }

        // TEST: if GA reporting is enabled, but the pageview doesn't fall within the sample rate, no GA reports are sent
        if (!sampleRateRoulette) {
            if (!reportingEnabledCheckDone) {
                log(`GA reporting is enabled for this site, but this pageview didn't fall inside the sample rate of ${karmaConfig.reportingSampleRate}%. No reports sent.`);
                karmaConfig.reportingEnabled = false;
            }
            reportingEnabledCheckDone = true;
            return false;
        }

        return reportingEnabled;
    };

    const report = (shouldReportToGA, reportObject, eventType, logData) => {
        const reportType = isEnabled(),
            allowedEventTypes = ['karmaRequest', 'karmaPerformance'],
            isLocal = (getEnv() === 'local'),
            runKarmaTestsIsNotInUrl = !!(karmads.urlVars.get('runKarmaTests') === null),
            pageType = karmads.targeting.get('type'),
            unmappedPageType = karmaVars.unmappedPageType,
            logOptions = {
                reportEventType: eventType,
                payload: reportObject
            };

        if (!reportType) {
            if (!reportingDisabledMsg) {
                log('Reporting is disabled for this page.');
                reportingDisabledMsg = true;
            }
            return false;
        }

        // TEST: if the reportObject passed to the 'report' function is not an object, reports are not sent
        if (!isObject(reportObject)) {
            log(`The reportObject passed to karmads.reporting.report was not an object: ${typeof reportObject}`, {force: 1, level: 'warn'});
            return false;
        }

        // TEST: site and contentType are passed on every reporting request
        reportObject.site = karmaConfig.site;
        reportObject.contentType = karmads.targeting.get('type');
        // TEST: If the page type is different from the unmapped page type, the unmapped type is passed as origionalContentType
        if (pageType !== unmappedPageType && reportType !== 'ga') {
            reportObject.originalContentType = unmappedPageType;
        }
        // TEST: a nonInteraction property is added to every reporting request with a value of 1
        reportObject.nonInteraction = 1;
        // by default, we log out the eventType plus the reportObject
        logData = logData || `${eventType}: ${JSON.stringify(reportObject)}`;

        if (reportType === 'ga') {
            // TEST: if false is passed as the first argument to the report function, we don't send the report to GA
            if (!shouldReportToGA) {
                log(`Did not report to GA because this is a Segment-only kinda thing: ${ logData}`, logOptions);
                return false;
            }

            // TEST: when reporting to GA, the 'event' property should match the 'category' property
            // TEST: when reporting to GA, the 'contentType' property is mapped to 'karmaPageType'
            const gaPropMap = {
                'category': 'event',
                'contentType': 'karmaPageType',
                'adSlots': 'karmaAdSlots',
                'channel': 'karmaChannel'
            };
            doLoop(gaPropMap, (key) => {
                if (reportObject.hasOwnProperty(key) && !reportObject.hasOwnProperty(gaPropMap[key])) {
                    reportObject[gaPropMap[key]] = reportObject[key];
                    delete reportObject[key];
                }
            });

            // TEST: if KARMA mapped an invalid content type, the contentType passed to GA is unmappedType:type
            // TEST: if KARMA did not map an invalid content type, the contentType passed to GA is type (no colon)
            reportObject.karmaPageType = unmappedPageType !== pageType ? `${unmappedPageType}:${pageType}` : pageType;

            logData = `${eventType}: ${JSON.stringify(reportObject)}`;
        }

        // TEST: if reporting is enabled, but the url contains runKarmaTests, no reports are sent
        if (!runKarmaTestsIsNotInUrl) {
            log(`Did not report to ${reportType}, because we're on an automated testing page. ${logData}`, logOptions);
            return false;
        }

        // TEST: if we're on local, nothing is reported to GA or Segment
        if (isLocal) {
            log(`Did not report to ${reportType}, because we're on local: ${logData}`, logOptions);
            return false;
        }

        if (reportType === 'ga') {
            // TEST: if karma.config.reportingEnabled is set to 'ga', and GA is on the page, the dataLayer contains KARMA reports
            if (typeof win.dataLayer === 'object' && typeof win.dataLayer.push === 'function') {
                // TEST: window.dataLayer exists and is an array on 'GA' pages
                win.dataLayer.push(reportObject);
                log(`Reported to GA: ${logData}`, logOptions);
                return true;
            }
        }

        // TEST: If reportingEnabled is set to 'segment', and segment is on the page, events are reported via segment
        if (typeof win.analytics === 'object' && typeof win.analytics.track === 'function') {
            // TEST: the reporting eventType must be karmaRequest or karmaPerformance in order to be reported to Segment
            if (allowedEventTypes.indexOf(eventType) === -1) {
                log(`Did not report to Segment, because the eventType "${eventType}" was not one of: ${allowedEventTypes.join(', ')}; ${logData}`);
                return false;
            }
            win.analytics.track(eventType, reportObject);
            log(`Reported to Segment: ${logData}`, logOptions);
        }
        return true;
    };

    const instantSlots = () => {
        // TEST: if reporting is enabled, list of instant slots is sent to Segment/GA
        // TEST: the instantSlots report object has a label of 'slotInfo'
        const slotLookupArray = karmads.slots.getSlotIdTierCombos(karma.slots.catalog),
            reportType = isEnabled(),
            reportObject = {
                'category': reportType === 'ga' ? 'adRequest' : 'instantAdSlots',
                'label': reportType === 'ga' ? 'slotInfo' : 'adSlotInfo',
                'adSlots': slotLookupArray,
                'channel': karmads.targeting.get('channel'),
                'karmaId': karmads.targeting.get('id')
            };
        report(true, reportObject, 'karmaRequest');
    };

    // TEST: if GA reporting is enabled, and lazy load slots are requested, lazy slot info is sent to GA
    // TEST: if segment reporting is enabled, and lazy load slots are requested, lazy slot info is sent to segment
    // TEST: the lazyLoadSlot report object has a label of 'slotInfo'
    const lazyLoadSlot = (slotName) => {
        const reportType = isEnabled(),
            reportObject = {
                'category': reportType === 'ga' ? 'adSlotLazyLoad' : 'lazyLoadAdSlots',
                'label': reportType === 'ga' ? 'slotInfo' : 'adSlotInfo',
                'adSlots': [slotName],
                'channel': karmads.targeting.get('channel'),
                'karmaId': karmads.targeting.get('id')
            };

        report(true, reportObject, 'karmaRequest');
    };

    const segmentReport = (category, value, label = 'tracking') => {

        // TEST: Reporting KillCode or CollapseCode has a label of 'gamCreativeTracking'
        if (category === 'KillCode' || category === 'CollapseCode') {
            label = 'gamCreativeTracking';
        }

        // category should be CollapseCode or KillCode, until we add more
        // TEST: when reporting CollapseCode, KillCode, or HeavyAdIntervention, the Segment report should have a category, label, and value
        const segmentReportObject = {
            category,
            label,
            value
        };
        // anything in segmentReport should not be reported to GA
        report(false, segmentReportObject, 'karmaRequest');
    };

    // report on karma events and timing
    const reportTiming = () => {
        let logEntry,
            reportCount = 0;

        const reportType = isEnabled(),
            reportObject = {
                category: 'karmaTimingBundle',
                label: 'timing'
            };

        // Report timing for segment only
        if (reportType !== 'segment') {
            return false;
        }

        // TEST: log timings are sent if the measured value is between 0 and 599,999 ms
        doLoop(karmaVars.logObject, (key) => {
            logEntry = karmaVars.logObject[key];
            if (!!logEntry.report && !logEntry.reported) {
                const reportValue = logEntry.reportValue;
                logEntry.reported = true;

                if (typeof reportValue === 'number') {
                    if (reportValue === 0) {
                        log(`Attempted to send performance timing for ${key} to Segment, but measured value is 0`);
                        return false;
                    }

                    if (reportValue >= 600000) {
                        log(`Attempted to send performance timing for ${key} to Segment, but measured value exceeds 599,999ms`);
                        return false;
                    }
                }

                // add event to segment report object
                if (key) {
                    reportObject[key] = reportValue;
                }

                reportCount++;
            }
        });

        if (reportCount) {
            // TEST: segment timing metrics are sent as a single request
            report(false, reportObject, 'karmaPerformance');
            return reportCount;
        }

        log('karmads.reporting.reportTiming() was called, but there was nothing to report');

    };

    const addTimingEventListener = () => {
        const onloadEvent = 'slotOnload';

        karmads.gpt.addCallback((e) => {
            if (!karmaVars.slotOnloadLogged) {

                // TEST: if reporting is enabled, slotOnload timing data is sent to Segment
                log(`Slot onload event for ${e.slot.getSlotElementId()}`, {label: onloadEvent, report: true});
                // TEST: When the first slot is loaded, reportTiming is called to log karma events and timing
                reportTiming();

                // TEST: karma.vars has a boolean property called 'slotOnloadLogged'
                // TEST: karma.vars.slotOnloadLogged is true after the first ad returns
                karmaVars.slotOnloadLogged = true;
            }
        }, onloadEvent);
    };

    return {
        segmentReport,
        init,
        report,
        reportTiming,
        reportType: isEnabled,
        initialSlots: instantSlots,
        lazyLoadSlot,
        addEventListener: addTimingEventListener
    };

}());