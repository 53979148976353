karmads.kismet = (function() {
    'use strict';

    // TEST: karma.vars has an object property called 'kismet'
    // TEST: karma.vars.kismet.reported is a boolean
    karmaVars.kismet = {
        checked: false,
        reported: false
    };

    const report = (filename = 'spacer2') => {
        // TEST: If a string is passed in to karmads.kismet.report, kismet/[filename].png is added to the page
        // TEST: If a non-string is passed into karmads.kismet.report, kismet/spacer2.png is added to the page
        const reported = karmads.dom.lookup(`img[src$="kismet/${filename}.png"]`) !== null;
        if (!reported) {
            fetchResource(`/kismet/${filename}.png`, noop, noop, 'img');
            karmaVars.kismet.reported = true;
        }
    };

    return {
        report
    };

}());