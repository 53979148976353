karmads.hb = (function() {
    'use strict';
    const partnersToCheck = ['a9', 'prebid'];

    // TEST karmads.hb has a function called isEnabled that returns a boolean when a partner name is passed in
    // TEST: If a partner is enabled, but their module is not available, they are treated as disabled.
    // TEST: if the user has opted out of advertising-related cookies, both Prebid and A9 are disabled
    const isEnabled = (partner) => karmaConfig.hb.enabled && karmaConfig.hb[partner].enabled && !!karmads.hb[partner];

    const initTimeouts = () => {
        // TEST: karma.config.hb has a property called bidTimeouts that is an object
        // TEST: karma.config.hb.bidTimeouts has a property called failsafe that is the partner failsafe timeout length (number)
        // TEST: karma.config.hb.bidTimeouts has a property called hard that is the hard refresh bid timeout length (number)
        // TEST: karma.config.hb.bidTimeouts has a property called soft that is the soft refresh bid timeout length (number)
        // TEST: karma.config.hb.bidTimeouts has a property called timed that is the timed refresh bid timeout length (number)

        const bidTimeouts = karmaConfig.hb.bidTimeouts = karmaConfig.hb.bidTimeouts || {};
        bidTimeouts.failsafe = bidTimeouts.failsafe || 2000;
        bidTimeouts.hard = bidTimeouts.hard || 750;
        bidTimeouts.soft = bidTimeouts.soft || 500;
        bidTimeouts.timed = bidTimeouts.timed || bidTimeouts.hard;
    };

    const isAnyPartnerEnabledOnSlot = (slot) => {
        // TEST: isAnyPartnerEnabledOnSlot() returns true if header bidding is enabled on the slot
        // TEST: isAnyPartnerEnabledOnSlot() returns false if no header bidding is enable on the page
        // TEST: isAnyPartnerEnabledOnSlot() returns false if no header bidding is enabled on the page but not on the slot
        slot = (typeof slot === 'string') ? window.karmads.slots.getById(slot) : slot;
        const arr = partnersToCheck.filter((partner) => isPartnerEnabledOnSlot(slot, partner));
        return arr.length > 0;
    };

    const isPartnerEnabledOnSlot = (slot, partner) => slot.hb && slot.hb[partner] && isEnabled(partner);

    const isPartnerEnabledOnSlots = (slots, partner) => {
        const arr = slots.filter((slot) => isPartnerEnabledOnSlot(slot, partner));
        return arr.length > 0 && isEnabled(partner);
    };

    const initDemandFetch = (slotConfigs) => {
        // TEST: a function is available on karmads.hb called initDemandFetch
        // TEST: Ads load when a9 and prebid are enabled
        // TEST: Ads load when a9 and prebid are disabled
        // TEST: Ads load when a9 is enabled and prebid is disabled
        // TEST: Ads load when a9 is disabled and prebid is enabled
        // TEST: Ads load when all partners are disabled

        const checkInPartner = (partner) => {
            const index = partnersToFetch.indexOf(partner);

            if (index >= 0) {
                partnersToFetch.splice(index, 1);
            }

            if (partnersToFetch.length === 0) {
                log('Finishing request');
                karmads.requestQueue.finishRequest(slotConfigs);
            }
        };

        const hasRefreshTypes = (refreshTypes, refreshTypesToMatch) => {
            const result = refreshTypes.filter((refreshType) => refreshTypesToMatch.indexOf(refreshType) > -1);
            return result.length > 0;
        };

        const getRefreshTypeForTimeout = (slotConfigs) => {
            // TEST: The bidTimeouts property 'timed' is used if any of the slots in the batch are refreshType 'timed' or 'swap'
            // TEST: The bidTimeouts property 'soft' is used if none of the slots in the batch are refreshType 'timed' or 'swap', one or more are refreshType 'soft', 'lazy' or 'scroll'
            // TEST: If none of the slots in the batch are refreshType 'timed', 'swap', 'soft', 'lazy' or 'scroll', the bidTimeouts property 'hard' is used
            const refreshTypes = slotConfigs.map((slot) => slot.refreshType),
                hasTimedSlots = hasRefreshTypes(refreshTypes, ['timed', 'swap']),
                hasSoftSlots = hasRefreshTypes(refreshTypes, ['soft', 'lazy', 'scroll']);

            if (hasTimedSlots) {
                return 'timed';
            } else if (hasSoftSlots) {
                return 'soft';
            }
            return 'hard';
        };

        const batchHasAtLeastOnePartner = (slotConfigs) => {
            const arr = slotConfigs.filter((slot) => isAnyPartnerEnabledOnSlot(slot));
            return arr.length > 0;
        };

        const partnersToFetch = ['control'],
            refreshTypeForTimeout = getRefreshTypeForTimeout(slotConfigs);

        doLoop(partnersToCheck, (partner) => {
            if (moduleExists(`hb.${partner}`) && isPartnerEnabledOnSlots(slotConfigs, partner)) {
                partnersToFetch.push(partner);
                karmads.hb[partner].fetchBids(slotConfigs, refreshTypeForTimeout, () => {
                    checkInPartner(partner);
                });
            }
        });

        if (refreshTypeForTimeout === 'timed' && batchHasAtLeastOnePartner(slotConfigs)) {
            // TEST: When the bid refresh is 'timed', the window for header bidding is rounded up to the nearest second
            const controlTimeout = Math.ceil(karmaConfig.hb.bidTimeouts.timed / 1000) * 1000;
            log(`Starting partner control timeout for timed refresh: ${controlTimeout}`);
            setTimeout(() => {
                checkInPartner('control');
            }, controlTimeout);
        } else {
            // TEST: Control checks in automatically for non-timed refresh bid requests
            checkInPartner('control');
        }
    };


    // TEST: karmads.hb has a method called filterSlots
    // TEST: karmads.hb.filterSlots returns an array of partner-enabled slots according to the partner name passed
    // TEST: karmads.hb.filterSlots does not remove preroll slots from the array which it returns
    const filterSlots = (slots, partner) => {
        const partnerEnabledSlots = slots.filter((slot) => slot.hb && slot.hb[partner] || slot.isPreroll);
        return partnerEnabledSlots;
    };

    const getTimeoutLengths = (refreshType) => {
        const bidTimeouts = karmaConfig.hb.bidTimeouts || {},
            isHardRefresh = (refreshType === 'hard'),
            result = {
                partnerEnforced: bidTimeouts[refreshType] || 750,
                failsafe: bidTimeouts.failsafe || 2000,
                message: isHardRefresh ? 'failsafe' : 'refresh'
            };

        result.karmaEnforced = (isHardRefresh) ? result.failsafe : result.partnerEnforced;

        return result;
    };

    const generateGpid = (slot) => {
        // TEST: on display slots generateGpid concats the first 4 levels of the ad unit (with 'na' in place of 'none' for type) with the slot id
        // TEST: on video slots generateGpid concats network id, video site, page type and "preroll"
        const gpidArr = [];
        if (!slot.isPreroll) {
            gpidArr.push(karmaConfig.networkCode, karmaConfig.site, slot.tierString);
        } else {
            gpidArr.push(karmads.targeting.adunit.getTopLevelVideoAdUnit());
        }
        gpidArr.push(karmads.targeting.get('type').replace('other', 'na'), slot.slotContainer || 'preroll');
        return gpidArr.join('/');
    };

    return {
        filterSlots,
        generateGpid,
        getTimeoutLengths,
        initTimeouts,
        isEnabled,
        isAnyPartnerEnabledOnSlot,
        initDemandFetch
    };
}());