karmads.calvera = (function() {

    const isEnabled = () => karmaConfig.calveraEnabled;

    // TEST: on environments other than prod, the Calvera script is loaded off qa-cdn.polaris.me
    // TEST: on prod, the Calvera script is loaded off cdn.polaris.me
    const buildScript = () => `//${karmads.utilities.getEnv() !== 'www' && 'qa-' || ''}cdn.polaris.me/cva/local-offers/c/${karmaConfig.siteCode || karmaConfig.site.split('.')[0]}-calvera.js`;

    const load = () => {
        if (isEnabled()) {
            const script = buildScript();
            // TEST: if calveraEnabled is set to true and is an mdex page, the calvera script is loaded
            fetchResource(script, () => log(`Calvera script loaded. (${script}) `), () => {
                // TEST: if calveraEnabled is enabled and the wrapper fails to load, we disable calvera
                log("Failed to fetch Calvera's script.", {level: 'error', force: 1});
                karmaConfig.calveraEnabled = false;
            });
        }
    };

    return {
        load,
        isEnabled
    };

}());