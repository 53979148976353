karmads.timeline = (function() {
    'use strict';

    karmaVars.initialized = karmaVars.initialized || false;

    // fires first
    const beforeConfig = () => {
        // TEST: the GPT library is loaded
        karmads.gpt.init();
        // TEST: Meredith user id is initialized
        karmads.muid.init();
    };

    // initialize KARMA
    const init = () => {
        // convert on-page settings for use in karma.config
        win.karma = win.karma || {};
        karma = extend(karma, win.karma);
        karma.config.onPageSettings = (Object.keys(win.karma).length > 0) ? win.karma : {};
        karmads.translate.convertConfig();

        // set config values that were passed in the URL
        karmads.urlVars.setTestValues();

        // TEST: the karma config has a boolean toggle called isMobile
        if (!karma.config.hasOwnProperty('isMobile') || typeof karma.config.isMobile !== 'boolean') {
            log('karma.config.isMobile is missing or is not a boolean.', {force: 1, level: 'error', doc: 'karmaconfigismobile---required'});
        }
        isMobilePage = karma.config.isMobile = convertStringToBoolean(karma.config.isMobile);

        // TEST: karma.vars.initialized is true
        karmaVars.initialized = true;
        // the local karma variable is assigned to the window scope
        win.karma = karma;

        // init debug module
        // this lives here deliberately
        karmads.debug.init(karmads.urlVars.get('karmaDebug'));

        log('KARMA is running!', {report: true, label: 'karmaRunning', force: 1});

        // TEST: viewability focus listeners are added to the window
        karmads.viewability.addFocusListeners();
        // TEST: the top-level ad unit is built
        karmads.targeting.adunit.init();
        // TEST: page-level targeting values are initialized
        karmads.targeting.init();
        // process callbacks and add them to the callback stack
        karmads.callbacks.init();
        // TEST: A log entry should be added when the slotOnload event fires
        karmads.reporting.addEventListener();
        // TEST: the KARMA config is loaded
        karmads.config.init(afterConfigReady);
    };

    // fires after the config is fetched
    const afterConfigReady = (ads) => {
        // TEST: the KARMA config is processed
        karmads.config.process(ads);
        // TEST: the ad unit format is established
        getAdUnitFormat();
        // TEST: the request queue is processed
        karmads.requestQueue.init();
        // TEST: the Index Identity Library is loaded
        moduleExists('partner.ixIdentity') && karmads.partner.ixIdentity.load();
        // TEST: the header bidding timeouts are initialized
        moduleExists('hb') && karmads.hb.initTimeouts();
        // TEST: a9 is loaded
        moduleExists('hb.a9') && karmads.hb.a9.load();
        // TEST: once the DOM is ready, slots are setup
        karmads.dom.ready(afterDomReady);
    };

    // fires after DOM ready
    const afterDomReady = () => {
        // TEST: out of page slots are injected
        karmads.slots.injectOutOfPageSlots();
        // TEST: KARMA slots are initialized
        karmads.slots.init();
        // initialize bidz
        moduleExists('hb.bidz') && karmads.hb.bidz.init();
        // TEST: Calvera is loaded
        moduleExists('calvera') && karmads.calvera.load();
        karmads.gpt.queuePush(afterGPTReady);
    };

    // fires once GPT is ready
    const afterGPTReady = () => {
        // TEST: the KARMA slots are registered with GPT
        karmads.slots.define();
        // TEST: adlightning
        moduleExists('partner.adLightning') && karmads.partner.adLightning.load();
        // TEST: prebid is loaded (config is initialized)
        moduleExists('hb.prebid') && karmads.hb.prebid.load();
        // TEST: the KARMA log contains a KarmaInit entry
        log({label: 'KarmaInit'});
        // TEST: the KARMA external API methods are set up
        karmads.methods.init();
        // TEST: if apiVersion === 2, everything is translated for backwards compatibility
        karmads.translate.init();

        // make the ad request
        adRequest(karma.slots.catalog);

    };

    // fired from afterGPTReady
    const adRequest = (adSlotsArray) => {
        // TEST: GPT is enabled
        karmads.gpt.enable();
        // TEST: GPT slots are set to display
        karmads.gpt.displayAllSlots();
        // TEST: the instant slots are sent to reporting
        karmads.reporting.init();
        // TEST: the log contains an entry that says 'Finished GPT Setup... calling DFP'
        moduleExists('hb.video') && karmads.hb.video.initTargeting();
        log('Finished GPT Setup... calling DFP');
        // TEST: the instant slots are requested
        karmads.requestQueue.process(adSlotsArray);
        // TEST: the list of default refreshable slots is built
        karmads.refresh.buildDefaultSlots();

        // TEST: karmaReady is called
        if (adSlotsArray.length === 0) {
            karmaVars.requestInFlight = false;
            afterFirstAdReturnedRunOnce();
        } else {
            karmads.callbacks.add(afterFirstAdReturnedRunOnce);
        }
    };

    // fires once the slots have been requested
    const afterFirstAdReturnedRunOnce = (e) => {
        if (karmaVars.firstAdReturned) {
            return;
        }
        // TEST: karma.vars.firstAdReturned exists and is true
        karmaVars.firstAdReturned = true;
        firstAdReturned(e);
    };

    const firstAdReturned = (e) => {
        if (e) {
            // TEST: the KARMA log contains a slotRenderEnded entry
            log(`slotRenderEnded: ${e.slot.getSlotElementId()}`, {report: true, label: 'slotRenderEnded'});
        }

        // TEST: if karmads.docking.leaderboard.isEnabled is true, leaderboard docking is initialized
        // TEST: if karmads.docking.rail.isEnabled is true, right rail docking is initialized
        moduleExists('docking') && karmads.docking.init(); // inits both leaderboard and rail
        // TEST: if enabled, timed refresh is initialized
        karmads.refresh.timed.init();
        // The order in which processQueue() fires here is important as to not interfere with
        // other functions that run after the first ad is returned
        processQueue();
        moduleExists('hb.video') && karmads.hb.video.fetchLateBids();
        karmads.translate.ready();
        karmads.scrollLoad.checkAndRequest();
        // TEST: If config.useWebInterstitial is true, the web interstitial slot is added
        karmads.gpt.requestWebInterstitial();
        injectHelperScripts();
    };

    const processQueue = () => {
        const runCmdFunc = (func) => {
            if (typeof func === 'function') {
                try {
                    func();
                } catch (e) { // if an invalid
                    log(`karma.cmd error running function: ${e}`, {force: 1, level: 'error'});
                }
            }
        };

        // TEST: karma.vars.queueExecuted exists and is true
        karmaVars.queueExecuted = true;

        // TEST: There is a globally-scoped array available on the page called karma.cmd
        karma.cmd = win.karma.cmd || [];
        karmads.translate.queue();
        if (typeof karma.cmd === 'object') {
            while (karma.cmd.length > 0) {
                runCmdFunc(karma.cmd[0]);
                karma.cmd.splice(0, 1);
            }
        }
        /* Modify command queue to execute any commands passed into it now that methods have initialized. */
        // TEST: window.karma.cmd has a property called 'push' that is a function
        karma.cmd = {
            push: runCmdFunc
        };
    };

    const injectHelperScripts = () => {
        if (!karmaVars.testsRun) {
            log({label: 'onload'});
            const runTests = karmads.urlVars.get('runKarmaTests');
            // TEST: if runKarmaTests is in the URL, the KARMA test bookmarklet JS is added to the page
            if (runTests) {
                karmads.utilities.runTests({urlTriggered: true});
            }
            karmaVars.testsRun = true;
        }
        if (!karmaVars.bookmarkletInjected && karmads.urlVars.isTrue('karmaDebug')) {
            // TEST: if karmaDebug=true is in the URL, the KARMA bookmarklet is launched
            karmads.gpt.queuePush(() => {
                karmads.dom.create('script', karmaVars.els.head, {src: `${(karmaVars.environment === 'local') ? 'http://localhost:9000' : 'https://karma.mdpcdn.com/files/tools/bookmarklets'}/karmaBookmarklet.js`});
            });
            // TEST: if the bookmarklet has been injected via URL param, karma.vars.bookmarkletInjected is true
            karmaVars.bookmarkletInjected = true;
        }
    };

    // Timeline start code: needs to live outside a function scope or it freaks out. I'm not sure why.
    karmaVars.ready = true;

    if (!karmads.reload.checkParams()) {

        // TEST: karma.vars.headerInitialized is set to true
        karmaVars.headerInitialized = karmaVars.headerInitialized = true;

        // load stuff that needs to be requested before the config
        beforeConfig();

        // if we're in KARMA 3 mode, or if karma.header.js loads after karma.footer.js, auto-init
        if (apiVersion === 3 || (win.karmaService && win.karmaService.vars.footerReady && !karmaVars.initialized)) {
            init();
        }
    }

    // legacy support for KARMA 2
    if (win.karmaService && win.karmaService.vars) {
        karmaVars.footerReady = win.karmaService.vars.footerReady;
    }
    win.karmaService = {
        vars: karmaVars,
        init
    };

    // set up karma object and init method
    win.karma = win.karma || {};
    win.karma.init = init;
    // End timeline start code

    return {
        init,
        afterConfigReady
    };
}());

